import React, { PropsWithChildren, useContext } from "react";
import { Badge } from "../../../ui/badge";
import { Checkbox } from "../../../ui/checkbox";
import { Button } from "../../../ui/button";
import { ChevronDown, Eye, EyeOff } from "lucide-react";
import { ContentItemsView } from "./content/segment-content";
import {
  SegmentBlueprint,
  SegmentStyle,
} from "tekkr-common/dist/model/playbook/segment/blueprint";
import { SegmentEdit } from "tekkr-common/dist/model/playbook/segment/edit";
import { Collapsible, CollapsibleContent } from "../../../ui/collapsible";
import { cn } from "../../../../lib/utils";
import { PlaybookViewControllerContext } from "../playbook-view";
import { PlaybookEdit } from "tekkr-common/dist/model/playbook/edit";
import { useScopedEdit } from "../../../hooks/use-edit";
import { TekkrMarkdown } from "../../markdown/tekkr-markdown";

type SegmentContainerProps = PropsWithChildren & {
  id: string,
  name: string,
  title: string,
  isEditing: boolean,
  hidden: boolean,
  done: boolean,
  expanded: boolean,
  setDone: (done: boolean) => void,
  setHidden?: (hidden: boolean) => void,
  setExpanded?: (expanded: boolean) => void,
  options?: {
    hideControls?: boolean;
  }
}

export function SegmentContainer(props: SegmentContainerProps) {
  const { children, hidden, done, expanded, setExpanded, setDone, setHidden } = props;
  return <>
    <div
        style={{ scrollMarginTop: "56pt" }}
        className={"group rounded-lg border relative bg-background"}
    >
      <div className={"overflow-hidden relative rounded-lg"}>
        <div
            className={cn(
                "flex flex-col p-5 gap-4",
                hidden ? "opacity-45" : null
            )}
        >
          { !props.options?.hideControls && <div className={"flex flex-row justify-between"}>
            <Badge
                variant={"mini_accent"}
                className={
                  done && !hidden
                      ? "bg-confirmation text-confirmation-foreground"
                      : undefined
                }
            >
              {props.name}
            </Badge>
            {props.isEditing ? (
                <div
                    className={cn(
                        "flex items-center space-x-2 animate-in slide-in-from-right-3 fade-in duration-300",
                        hidden ? "hidden" : null
                    )}
                >
                  <Checkbox
                      variant={done ? "confirmation" : "primary"}
                      id={`done-segment-${props.id}`}
                      checked={done}
                      onCheckedChange={(done) => setDone(!!done)}
                  />
                  <label
                      htmlFor={`done-segment-${props.id}`}
                      className=" text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                  >
                    done
                  </label>
                </div>
            ) : null}
          </div> }
          { !props.options?.hideControls && <h3
              className={cn("flex flex-row", !hidden && setExpanded ? "cursor-pointer" : undefined)}
              onClick={() => setExpanded?.(!expanded)}
          >
            <TekkrMarkdown markdown={props.title} className={"w-auto"} />
            { !!setExpanded && <Button
                className={cn("w-6 h-6 p-1 ml-2", hidden ? "hidden" : null)}
                style={{ verticalAlign: "middle" }}
                variant={"ghost"}
                size={"icon"}
            >
              <ChevronDown
                  className={"transition-all"}
                  style={{
                    transform: expanded ? "rotate(-180deg)" : undefined,
                  }}
              />
            </Button> }
            <div className={"grow"}></div>
          </h3> }
          <Collapsible
              open={expanded && !hidden}
              onOpenChange={setExpanded}
          >
            <CollapsibleContent className="space-y-2">
              { !props.options?.hideControls && <hr className={"-mx-10 border-0 border-b"} /> }
              { children }
            </CollapsibleContent>
          </Collapsible>
        </div>
        <div
            className={cn(
                "w-1 top-0 bottom-0 absolute",
                hidden
                    ? "bg-secondary"
                    : done
                        ? "bg-confirmation"
                        : "bg-primary"
            )}
        ></div>
        {props.isEditing && expanded && (setExpanded || !done) && !props.options?.hideControls ? (
            <Button
                className={"w-full rounded-none bg-accent hover:bg-muted animate-in fade-in duration-300"}
                onClick={() => setDone(true)}
                variant={"ghost"}
            >
              {done ? "Close" : "Mark as done:"} &quot;
              {props.title}&quot;
            </Button>
        ) : null}
      </div>
      {props.isEditing ? (
          <div className={"opacity-0 invisible group-hover:visible group-hover:opacity-100 transition-all duration-300 w-12 absolute -right-14 top-0 flex flex-col"}>
            { !!setHidden && <Button
                onClick={() => setHidden(!hidden)}
                size={"sm"}
                variant={"secondary"}
                className={"w-8 h-8 p-2 opacity-65"}
            >
              {hidden ? <Eye /> : <EyeOff />}
            </Button> }
          </div>
      ) : null}
    </div>
  </>
}

export default function PlaybookSegment(props: {
  playbookEdit?: PlaybookEdit;
  segment: SegmentBlueprint;
  index: number;
  segmentEdit?: SegmentEdit;
  isEditing: boolean;
  expandedByDefault?: boolean;
}) {
  const [expanded, setExpanded] = React.useState(props.expandedByDefault ?? false);

  const viewController = useContext(PlaybookViewControllerContext);

  if (props.segmentEdit && !props.segmentEdit.content) {
    props.segmentEdit.content = {};
  }

  const { state, updateEdit } = useScopedEdit(props.segmentEdit, (e) => ({
    done: e?.done ?? false,
    hidden: e?.hidden ?? false,
  }))

  function setDone(done: boolean) {
    if (done && expanded) {
      setTimeout(() => viewController.goToSegment(props.index), 100);
      setExpanded(false);
    }
    if (!props.segmentEdit || state.done === done) {
      return;
    }
    updateEdit((e) => {
      e!.done = done;
    });
  }
  function setHidden(hidden: boolean) {
    if (!props.segmentEdit) {
      return;
    }
    updateEdit((e) => {
      e!.hidden = hidden;
    });
    setExpanded(false);
  }

  const content = <ContentItemsView
      isEditing={props.isEditing}
      content={props.segment.content}
      edit={props.segmentEdit}
  />;

  if (props.segment.style === SegmentStyle.plain) {
    return <div className={"my-4"}>{ content }</div>;
  } else if (props.segment.style === SegmentStyle.card) {
    return <SegmentContainer
        id={props.segment.id}
        name={props.segment.name}
        title={props.segment.title}
        isEditing={props.isEditing}
        hidden={state.hidden}
        done={state.done}
        expanded={expanded}
        setDone={setDone}
        setHidden={setHidden}
        setExpanded={setExpanded}>
      { content }
    </SegmentContainer>
  } else {
    throw new Error(`segment style "${props.segment.style}" is not supported.`);
  }
}
