import { cn, getInitialsForName } from "../../lib/utils";
import { Progress } from "../ui/progress";
import { Building, Check, Lock, LockOpen, Users2 } from "lucide-react";
import Rating from "./rating";
import { Link } from "react-router-dom";
import { ClientInferResponseBody } from "@ts-rest/core";
import { apiContract } from "tekkr-common/dist/model/api/api.contract";
import { Button } from "../ui/button";
import {
    PlaybookStage,
    playbookCategoryConfig,
} from "tekkr-common/dist/model/playbook/enums/enums";
import { playbookStageTitles } from "tekkr-common/dist/model/playbook/static/copy";
import { Badge } from "../ui/badge";
import React from "react";
import { Avatar, AvatarFallback, AvatarImage } from "../ui/avatar";
import { PlaybookState } from "tekkr-common/dist/model/playbook/state";
import { IconForPlaybookStage } from "./icon-playbook-stage";
import { formats } from "tekkr-common/dist/utils/formatting";

export type PlaybookMeta =
    | ClientInferResponseBody<typeof apiContract.listBlueprints, 200>["data"][0]
    | ClientInferResponseBody<typeof apiContract.listPlaybooks, 200>["data"][0];

interface PlaybookCardProps {
    variant: "vertical" | "horizontal";
    data: PlaybookMeta;
    state?: PlaybookState;
    linkTo: string;
    locked?: boolean;
    onLockedClick?: () => void;
}

function TransitionStagePlaybookStateFooter(props: { stage: PlaybookStage }) {
    return <div className={"text-sm flex flex-row gap-1.5 items-center"}>
        <div className={"me-1 text-muted-foreground"}>Next:</div>
        <IconForPlaybookStage stage={props.stage} className={"inline-block w-4 h-4"} />
        <div>{playbookStageTitles.upcoming[props.stage]}</div>
    </div>;
}

function ProgressBarPlaybookStateFooter(props: { stage: PlaybookStage, progress: number }) {
    return <div className={"flex flex-row gap-3 items-center text-sm"}>
        <div className={"flex flex-row gap-1.5 items-center text-muted-foreground"}>
            <IconForPlaybookStage stage={props.stage} className={"inline-block shrink-0 w-4 h-4"} />
            <div className={"shrink-0"}>{playbookStageTitles.ongoing[props.stage]}:</div>
        </div>
        <Progress
            value={props.progress * 100}
            className={"h-2 shrink grow w-auto"}
            color={"confirmation"}
        ></Progress>
        <div className={"flex-shrink-0"}>
            {Math.round((props.progress) * 100)}%
        </div>
    </div>;
}

function PlaybookStateFooter(props: { state: PlaybookState }) {
    const firstNonCompleted = Object.values(PlaybookStage).find(stage => {
        return props.state[stage] !== undefined && props.state[stage]! < 1;
    })

    if (!firstNonCompleted) {
        return <div className={"font-semibold flex flex-row items-center gap-2 text-sm text-confirmation"}><Check className={"w-4 h-4"} /> completed</div>
    }

    if (props.state[firstNonCompleted] === 0) {
        return <TransitionStagePlaybookStateFooter stage={firstNonCompleted} />
    } else {
        return <ProgressBarPlaybookStateFooter stage={firstNonCompleted} progress={props.state[firstNonCompleted]!} />
    }
}

function PlaybookCard(props: PlaybookCardProps) {
    const card = (
        <div
            data-testid="playbook-card"
            className={cn(
                "border bg-background relative rounded-lg p-4 hover:bg-accent transition-all overflow-hidden group animate-in fade-in slide-in-from-bottom-3 duration-300",
                props.variant === "vertical" ? "min-w-48" : null,
                //`duration-[${}]`
            )}
        >
            <div
                className={cn(
                    "flex gap-4",
                    props.variant === "horizontal" ? "flex-row" : "flex-col",
                )}
            >
                <img
                    alt={"Playbook Cover"}
                    className={cn(
                        "border-0 rounded-lg object-cover aspect-3/2 bg-accent",
                        props.variant === "horizontal" ? "w-1/3 shrink-0" : null,
                    )}
                    src={props.data.imageUrl}
                />
                <div className={cn("flex flex-col gap-2", props.variant === "vertical" ? "h-80" : undefined)}>
                    <div>
                        <div className={"flex flex-row gap-1 flex-wrap"}>
                            {props.data.categories.map((category) => {
                                const config = playbookCategoryConfig[category];
                                return (
                                    <Badge
                                        key={category}
                                        style={{
                                            backgroundColor: config.color,
                                        }}
                                    >
                                        {config.title}
                                    </Badge>
                                );
                            })}
                        </div>
                        <h4 className={cn("line-clamp-3 mt-2")}>{props.data.title}</h4>
                    </div>
                    <div className={"flex flex-row gap-2 items-center"}>
                        <Rating rating={props.data.rating.score}></Rating>
                        <div className={"text-xs text-muted-foreground max-h-4 overflow-hidden"}>
                            {formats.friendly.rating(props.data.rating)} (used&nbsp;by&nbsp;{props.data.rating.reviewCount}&nbsp;companies)
                        </div>
                    </div>
                    <div className={"flex flex-row items-center"}>
                        <span className={"text-muted-foreground"}>By</span>
                        &nbsp;&nbsp;
                        <Avatar className={"w-5 h-5 inline-block"}>
                            <AvatarImage src={props.data.author.imageUrl}></AvatarImage>
                            <AvatarFallback>
                                {getInitialsForName(props.data.author.name)}
                            </AvatarFallback>
                        </Avatar>
                        &nbsp;
                        <span>{props.data.author.name}</span>
                    </div>
                    <p className={"shrink overflow-ellipsis overflow-hidden line-clamp-5"}>
                        {props.data.summary}
                    </p>
                    <div className={"grow"}></div>
                    <hr />
                    <div className={"flex flex-row gap-4 my-1"}>
                        <div className={"flex flex-row gap-1 items-center"}>
                            <Users2 className={"text-muted-foreground w-4 h-4"} />
                            <span className={"text-sm text-muted-foreground"}>
                {formats.friendly.teamSizeApplicability(props.data.teamSize)}
              </span>
                        </div>
                        <div className={"flex flex-row gap-1 items-center"}>
                            <Building className={"text-muted-foreground w-4 h-4"} />
                            <span className={"text-sm text-muted-foreground"}>
                {formats.friendly.companyStageApplicability(
                    props.data.companyStages,
                )}
              </span>
                        </div>
                    </div>
                    {props.state !== undefined && <PlaybookStateFooter state={props.state} />}
                </div>
            </div>
            {props.locked ? (
                <div
                    className={
                        "absolute top-0 bottom-0 right-0 left-0 opacity-0 group-hover:opacity-100 duration-200 backdrop-blur bg-background/80 flex flex-col gap-4 items-center justify-center p-8"
                    }
                >
                    <Lock />
                    <h3 className={"text-center"}>
                        Get access to all playbooks
                    </h3>
                    <p className={"text-center max-w-sm"}>
                        Unlock your tech team's potential using Tekkr's expert-curated,
                        actionable playbooks.
                    </p>
                    <Button
                        className={
                            "hover:drop-shadow-glow-primary transition-all duration-300"
                        }
                    >
                        <LockOpen className={"w-5 h-5 mr-2"} />
                        Unlock now
                    </Button>
                </div>
            ) : null}
        </div>
    );

    if (!props.locked) {
        return <Link to={props.linkTo}>{card}</Link>;
    } else {
        return (
            <div onClick={props.onLockedClick} className={"cursor-pointer"}>
                {card}
            </div>
        );
    }
}

export default PlaybookCard;
