"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.segmentBlueprintSchema = exports.SegmentTodoType = exports.segmentContentSchema = exports.SegmentStyle = void 0;
const blueprint_1 = require("./content/types/markdown/blueprint");
const blueprint_2 = require("./content/types/checklist/blueprint");
const zod_1 = require("zod");
const blueprint_3 = require("./content/types/people-list/blueprint");
const blueprint_4 = require("./content/types/select/blueprint");
const blueprint_5 = require("./content/types/section/blueprint");
const blueprint_6 = require("./content/types/blueprint-reference/blueprint");
const blueprint_7 = require("./content/types/external-link/blueprint");
const blueprint_8 = require("./content/types/editable-text/blueprint");
const schema_refinements_1 = require("../../../utils/schema-refinements");
const blueprint_9 = require("./content/types/categorization/blueprint");
const blueprint_10 = require("./content/blueprint");
const blueprint_11 = require("./content/types/tool-template/blueprint");
const blueprint_12 = require("./content/types/principles/blueprint");
const blueprint_13 = require("./content/types/space/blueprint");
var SegmentStyle;
(function (SegmentStyle) {
    SegmentStyle["plain"] = "plain";
    SegmentStyle["card"] = "card";
})(SegmentStyle || (exports.SegmentStyle = SegmentStyle = {}));
exports.segmentContentSchema = zod_1.z.array(zod_1.z.discriminatedUnion("type", [
    blueprint_2.checklistContentBlueprintSchema,
    blueprint_1.markdownContentBlueprintSchema,
    blueprint_3.peopleListContentBlueprint,
    blueprint_4.selectContentBlueprintSchema,
    blueprint_5.sectionContentBlueprintSchema,
    blueprint_6.blueprintReferenceContentBlueprintSchema,
    blueprint_7.externalLinkContentBlueprintSchema,
    blueprint_8.editableTextContentBlueprintSchema,
    blueprint_9.categorizationContentBlueprintSchema,
    blueprint_11.toolTemplateContentBlueprintSchema,
    blueprint_12.principlesContentBlueprintSchema,
    blueprint_13.spaceContentBlueprintSchema,
]))
    .superRefine((0, schema_refinements_1.validateUniqueIds)((content) => "id" in content ? content.id : undefined))
    .superRefine((val, ctx) => {
    for (const [index, item] of val.entries()) {
        if (item.type === blueprint_10.ContentType.select && item.default) {
            const subjectConfig = blueprint_4.selectSubjectConfigs[item.subject];
            if (!subjectConfig.options.find((option) => option.id === item.default)) {
                ctx.addIssue({
                    path: [index, "default"],
                    code: zod_1.z.ZodIssueCode.custom,
                    message: `${item.default} is not a valid default value for subject ${item.subject}. Valid options are: ${subjectConfig.options.map(o => o.id).join(", ")}`,
                });
            }
        }
        else if (item.type === blueprint_10.ContentType.markdown && !item.id) {
            if (item.markdown.includes(":editable")) {
                ctx.addIssue({
                    path: [index],
                    code: zod_1.ZodIssueCode.custom,
                    message: "missing required property \'id\'. The content seems to use the :editable directive, in this case an id is required."
                });
            }
        }
    }
});
var SegmentTodoType;
(function (SegmentTodoType) {
    SegmentTodoType["simple"] = "simple";
    SegmentTodoType["meeting"] = "meeting";
    SegmentTodoType["tool"] = "tool";
    SegmentTodoType["list"] = "list";
})(SegmentTodoType || (exports.SegmentTodoType = SegmentTodoType = {}));
exports.segmentBlueprintSchema = zod_1.z.strictObject({
    id: zod_1.z.string().regex(schema_refinements_1.formats.kebabCaseRegex),
    name: zod_1.z.string(),
    title: zod_1.z.string(),
    style: zod_1.z.nativeEnum(SegmentStyle),
    content: exports.segmentContentSchema,
    todo: zod_1.z.strictObject({
        title: zod_1.z.string(),
        description: zod_1.z.string(),
        type: zod_1.z.nativeEnum(SegmentTodoType),
        contentReference: zod_1.z.array(zod_1.z.string()).min(1).optional(),
    }).optional(),
}).superRefine((val, ctx) => {
    if (!val.todo) {
        return;
    }
    // todo check that text placeholders exist
    // todo check that contentReference is valid (if keeping it)
});
