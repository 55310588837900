import { DateTime, DurationLike } from "luxon";

export type Rfc5545FrequencyConfig = { freq: "DAILY" | "WEEKLY" | "MONTHLY" | "YEARLY"; interval?: number } | null;

interface GoogleCalendarLinkOptions {
    eventName: string;
    description?: string;
    invitees?: string[];
    meetingStart?: Date;
    meetingDuration: DurationLike;
    recur?: Rfc5545FrequencyConfig;
}

function formatDate (dt: DateTime): string {
    const tzed = dt.setZone("UTC")
    return `${tzed.toFormat("yyyyMMdd")}T${tzed.toFormat("HHmmss")}Z`;
}

function defaultStartTime(): Date {
    return DateTime.now().startOf("hour").plus({ hour: 1 }).toJSDate();
}

export function createGoogleCalendarLink (options: GoogleCalendarLinkOptions): string {
    const url = new URL("https://calendar.google.com/calendar/render?action=TEMPLATE")

    // meeting descriptions
    url.searchParams.append("text", options.eventName);
    if (options.description) {
        url.searchParams.append("details", options.description);
    }

    // attendees
    url.searchParams.append("add", [
        // `meeting+129uk89j120ja1s9@tekkr.io`, we can invite some tekkr bot here in the future
        ...(options.invitees ?? [])
    ].join(","));

    if (options.recur) {
        const props = {
            FREQ: options.recur.freq,
            ...(options.recur.interval ? { INTERVAL: options.recur.interval } : {}),
        }
        const val = `RRULE:${Object.entries(props).map(([k,v]) => `${k}=${v}`).join(';')}`;
        url.searchParams.append("recur", val);
    }

    // times
    const start = DateTime.fromJSDate(options.meetingStart ?? defaultStartTime());
    const startFormatted = formatDate(start);
    const endFormatted = formatDate(start.plus(options.meetingDuration));
    url.searchParams.append("dates", `${startFormatted}/${endFormatted}`);

    // return link
    return url.toString();
}