import PlaybookStats from "./components/playbook-stats";
import React, { useRef } from "react";
import { Badge } from "../../ui/badge";
import { PlaybookBlueprint } from "tekkr-common/dist/model/playbook/blueprint";
import { PlaybookEdit } from "tekkr-common/dist/model/playbook/edit";
import { PlaybookCategory } from "tekkr-common/dist/model/playbook/enums/enums";
import { computePlaybookEditProgress } from "tekkr-common/dist/model/playbook/state";
import { playbookCategoryConfig } from "tekkr-common/src/model/playbook/enums/enums";
import Contenteditable from "../contenteditable";
import { cn } from "../../../lib/utils";
import { useScopedEdit } from "../../hooks/use-edit";
import { Button } from "../../ui/button";
import { DeleteIcon, ImageIcon } from "lucide-react";
import { UploadPlaybookImageDialog } from "../../../modals/upload-playbook-image";
import { DialogTrigger } from "../../ui/dialog";
import { usePlaybookOptional } from "../../../pages/playbook/controller/hooks/playbook";

interface Props {
    blueprint: PlaybookBlueprint;
    playbook?: {
        edit?: PlaybookEdit;
        imageUrl?: string;
    };
    isEditing?: boolean;
    actionBarItems?: PlaybookActionBarItem[];
    onReplaceImage?: () => void,
}

function CategoryTagsBar(props: { categories: PlaybookCategory[] }) {
    return (
        <div className={"flex flex-row justify-between w-full items-center"}>
            <div className={"flex flex-row gap-2 flex-wrap"}>
                {props.categories.map((category) => {
                    const config = playbookCategoryConfig[category];
                    return (
                        <Badge key={category} style={{ backgroundColor: config.color }}>
                            {config.title}
                        </Badge>
                    );
                })}
            </div>
        </div>
    );
}

export interface PlaybookActionBarItem {
    title: string;
    onClick: () => void;
    icon: typeof DeleteIcon;
    variant?: "destructive";
}

function PlaybookActionsBar(props: { actions: PlaybookActionBarItem[] }) {
    return <div className={"flex flex-row items-center gap-2"}>
        { props.actions.map((action) => {
            const Icon = action.icon;
            return <Button
                onClick={action.onClick}
                className={"border-input text-muted-foreground p-2 duration-300"}
                size={"sm"}
                variant={action.variant === "destructive" ? "outline-destructive" : "outline"}
                key={action.title}>
                <Icon className={"w-5 h-5"} />
            </Button>
        } )}
    </div>
}

function TitleInput(props: { playbookEdit?: PlaybookEdit, blueprint: PlaybookBlueprint, isEditing: boolean }) {
    const { state, updateEdit } = useScopedEdit(props.playbookEdit, (e) => ({
        title: e?.title ?? props.blueprint.title,
    }));
    const updateTitle = (title: string) => {
        updateEdit(() => {
            if (props.playbookEdit) {
                props.playbookEdit.title = title;
            }
        });
    };
    let err: string | null = null;
    if (state.title.length > 100) {
        err = "This title is too long.";
    } else if (state.title.length < 1) {
        err = "Please enter a title for this playbook.";
    }

    const scaleContainerRef = useRef<HTMLDivElement>(null);
    const backgroundContainerRef = useRef<HTMLDivElement>(null);

    const titleClasses = "font-extrabold text-3xl";

    return <div className={"animate-in slide-in-from-bottom-2 duration-300"}>
        <div ref={backgroundContainerRef} className={cn(props.isEditing && "bg-input", "-my-2 transition-all duration-200 rounded-sm py-2", err ? "border border-destructive" : undefined)}>
            <div style={{ transform: props.isEditing ? "scale(0.975)" : undefined }} ref={scaleContainerRef} className={"transition-all"}>
                { props.isEditing ? <Contenteditable
                    className={cn(titleClasses, "outline-none")}
                    value={state.title} onChange={updateTitle}></Contenteditable> : <div className={titleClasses}>{ state.title }</div> }
            </div>
        </div>
        {err && <p className={"mt-2 text-sm text-destructive font-semibold"}>{err}</p>}
    </div>;
}

export function PlaybookHeader(props: React.PropsWithChildren & Props) {
    const progress = computePlaybookEditProgress(props.blueprint, props.playbook?.edit);
    const [imageDialogOpen, setImageDialogOpen] = React.useState(false);

    const pb = usePlaybookOptional();

    return (
        <div className={"flex flex-col gap-4"}>
            <div className={"flex flex-row justify-between items-center animate-in fade-in zoom-in-95 duration-300"}>
                <CategoryTagsBar categories={props.blueprint.categories} />
                { props.actionBarItems && <PlaybookActionsBar actions={props.actionBarItems} /> }
            </div>
            <TitleInput playbookEdit={props.playbook?.edit} blueprint={props.blueprint} isEditing={props.isEditing ?? false} />
            <PlaybookStats mode={pb ? "copy" : "discover"} progress={progress} pb={props.blueprint}></PlaybookStats>
            {props.children}
            <div className={"relative w-full"}>
                <img
                    alt={"Playbook Cover"}
                    className={"border-0 rounded-lg object-cover max-h-[45vh] min-h-96 animate-in slide-in-from-bottom-8 fade-in duration-700 w-full"}
                    src={props.playbook?.imageUrl ?? props.blueprint.imageUrl}
                />
                { props.isEditing && props.onReplaceImage !== undefined && <UploadPlaybookImageDialog open={imageDialogOpen} onOpenChange={setImageDialogOpen} onImageUploaded={() => pb?.refetch()}>
                    <DialogTrigger asChild>
                        <Button
                            onClick={props.onReplaceImage}
                            variant={"outline-glass"}
                            className={"bottom-2 right-2 absolute gap-2 border-none bg-black/45 hover:bg-black/55 text-white hover:text-white animate-in slide-in-from-bottom-4 fade-in-50"}
                        >
                            <ImageIcon className={"w-5 h-5"} />
                            <span className={"drop-shadow-2xl"}>Replace Image</span>
                        </Button>
                    </DialogTrigger>
                </UploadPlaybookImageDialog> }
            </div>
            <hr className={"my-6"} />
        </div>
    );
}
