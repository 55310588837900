import React from "react";
import { ContentSpacer } from "../common/layout";
import { PlaybookOverviewAnalyticsTable } from "./components/playbook-table";

export function AnalyticsPage() {
    return <ContentSpacer>
        <h1 className={"mt-4 animate-in slide-in-from-top-2 fade-in-75 duration-300"}>Tekkr Analytics</h1>
        <p className={"animate-in slide-in-from-bottom-1 fade-in-50"}>See how your playbooks are performing and what you
            can do to unblock your intiatives.</p>

        <div className={"animate-in slide-in-from-bottom-2 duration-500"}>
            <h3 className={"mt-4 mb-3"}>Summary</h3>
            <div className={"grid grid-cols-2 gap-4"}>
                <div
                    className={"bg-muted opacity-50 h-64 text-sm rounded-md items-center justify-center font-semibold flex flex-col"}>this
                    will be a chart
                </div>
                <div
                    className={"bg-muted opacity-50 h-64 text-sm rounded-md items-center justify-center font-semibold flex flex-col"}>this
                    will be a chart
                </div>
            </div>
        </div>

        <div className={"animate-in slide-in-from-bottom-4 duration-700"}>
            <h3 className={"mt-4 mb-3"}>Playbooks In Progress</h3>
            <PlaybookOverviewAnalyticsTable />
        </div>
    </ContentSpacer>
}