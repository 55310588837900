import React from "react";
import { StagePageContainer } from "../stage-container";
import { usePlaybook } from "../../controller/hooks/playbook";
import { SegmentTodoType } from "tekkr-common/dist/model/playbook/segment/blueprint";
import { CalendarIcon, User } from "lucide-react";
import { useCurrentOrgUser, useOrg, useOrgUserMap } from "../../../../auth/org-provider";
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger } from "../../../../components/ui/select";
import { PlaybookImplementation, PlaybookTodoStatus } from "tekkr-common/dist/model/playbook/stages/implementation";
import { cn } from "../../../../lib/utils";
import { TekkrMarkdown } from "../../../../components/shared/markdown/tekkr-markdown";
import { MeetingTodoContent } from "./components/meeting-todo-content";
import { ToolTodoContent } from "./components/tool-todo-content";
import { EditScope, useScopedEdit } from "../../../../components/hooks/use-edit";
import { DateTime } from "luxon";
import { usePlaybookImplementationEdit } from "../../controller/hooks/implementation-edit";
import { Calendar } from "../../../../components/ui/calendar";
import { Popover, PopoverContent, PopoverTrigger } from "../../../../components/ui/popover";
import { Button } from "../../../../components/ui/button";
import { TekkrCard, TekkrCardStyle } from "../../../../components/shared/tekkr-card";
import { useBlueprint } from "../../controller/hooks/blueprint";
import { ConnectOauthAccountButton } from "../../../../components/shared/connect-oauth-account-button";
import { apiClient } from "../../../../service/tekkr-service";
import { ClientInferResponseBody } from "@ts-rest/core";
import { apiContract } from "tekkr-common/dist/model/api/api.contract";
import { config, Environment } from "../../../../lib/config";
import { SiAsana } from "@icons-pack/react-simple-icons";
import { computePlaybookTodos } from "tekkr-common/dist/model/playbook/stages/implementation/compute-todos";
import { PlaybookImplementationTodo } from "tekkr-common/dist/model/playbook/stages/implementation/todo";

function TodoItemContent(props: { todo: Todo }) {
    if (props.todo.type === SegmentTodoType.meeting) {
        return <MeetingTodoContent {...props} />;
    } else if (props.todo.type === SegmentTodoType.simple) {
        return null;
    } else if (props.todo.type === SegmentTodoType.tool) {
        return <ToolTodoContent {...props} />;
    } else {
        throw new Error(`todo type ${props.todo.type} not supported`);
    }
}

const cardStyle: Record<PlaybookTodoStatus, TekkrCardStyle> = {
    [PlaybookTodoStatus.todo]: "primary",
    [PlaybookTodoStatus.done]: "confirmation",
    [PlaybookTodoStatus.inProgress]: "primary",
    [PlaybookTodoStatus.notDoing]: "disabled",
}

function TodoItem(props: { todo: Todo }) {
    const org = useOrg();
    const orgUserMap = useOrgUserMap();

    const { implementationEdit } = usePlaybookImplementationEdit();
    const todosEdit = implementationEdit.todos;

    if (!todosEdit[props.todo.id]) {
        todosEdit[props.todo.id] = {};
    }

    const { state, updateEdit } = useScopedEdit(todosEdit[props.todo.id], (e) => ({
        status: e.status ?? PlaybookTodoStatus.todo,
        assignee: e.assignee,
        dueDate: e.dueDate ? DateTime.fromISO(e.dueDate) : undefined,
    }));
    const setStatus = (status: PlaybookTodoStatus) => {
        updateEdit((e) => {
            e.status = status;
        });
    }
    const setAssignee = (assigneeUserId: string) => {
        updateEdit((e) => {
            e.assignee = assigneeUserId;
        })
    }
    const setDueDate = (dueDate?: Date) => {
        updateEdit((e) => {
            e.dueDate = dueDate?.toISOString();
        })
    }

    const collapsed = !(state.status !== PlaybookTodoStatus.notDoing && state.status !== PlaybookTodoStatus.done);

    return <TekkrCard cardStyle={cardStyle[state.status]}>
        <div className={cn("flex flex-row justify-between gap-4", collapsed && "items-center")}>
            <div className={"grow"}>
                <div
                    className={cn("flex flex-col gap-2 items-start", state.status === PlaybookTodoStatus.notDoing && "transition-all duration-300 opacity-65")}>
                    <h4><TekkrMarkdown markdown={props.todo.title} /></h4>
                </div>
                {!collapsed && <TekkrMarkdown markdown={props.todo.description}
                                              className={"text-sm text-muted-foreground overflow-clip"} />}
            </div>
            <div className={"grow-0"}>
                <select
                    value={state.status}
                    onChange={(e) => setStatus(e.target.value as PlaybookTodoStatus)}
                    className={cn(
                        "text-foreground cursor-pointer transition-all duration-300 bg-accent rounded-md py-1 px-2 text-sm font-semibold focus:outline-none active:outline-none border-r-transparent border-r-8",
                        state.status === PlaybookTodoStatus.done && "bg-confirmation text-white",
                        state.status === PlaybookTodoStatus.inProgress && "bg-primary text-white",
                    )}>
                    <option value={PlaybookTodoStatus.todo}>To do</option>
                    <option value={PlaybookTodoStatus.inProgress}>In Progress</option>
                    <option value={PlaybookTodoStatus.done}>Done</option>
                    <option value={PlaybookTodoStatus.notDoing}>Not Doing</option>
                </select>
            </div>
        </div>


        <div className={"transition-all duration-300"} style={{
            maxHeight: collapsed ? "0" : "9999pt",
            opacity: collapsed ? 0 : 1,
            visibility: collapsed ? "hidden" : "visible",
            transform: collapsed ? "scaleY(0.5)" : "scaleY(1.0)",
            transformOrigin: "top"
        }}>
            <div className={"pt-3"}>
                <TodoItemContent todo={props.todo} />
            </div>
            <hr className={"-mx-4 my-4"} />
            <div className={"flex flex-row gap-6"}>
                <div className={"flex flex-row gap-2 items-center"}>
                    <User className={"w-5 h-5"} />
                    <Select value={state.assignee} onValueChange={(userId) => setAssignee(userId)}>
                        <SelectTrigger className={"border-0 bg-accent h-8 w-[180px]"}>
                            {state.assignee ? orgUserMap[state.assignee]!.name :
                                <span className={"text-muted-foreground"}>Owner</span>}
                        </SelectTrigger>
                        <SelectContent>
                            <SelectGroup>
                                {org.users.map(u => <SelectItem key={u.id} value={u.id}>
                                    <div className={"flx flex-col"}>
                                        <div>{u.name}</div>
                                        <div className={"text-sm text-muted-foreground"}>{u.title}</div>
                                    </div>
                                </SelectItem>)}
                            </SelectGroup>
                        </SelectContent>
                    </Select>
                </div>
                <div className={"flex flex-row gap-2 items-center"}>
                    <CalendarIcon className={"w-5 h-5"} />
                    <Popover>
                        <PopoverTrigger asChild>
                            <Button
                                variant={"secondary"}
                                className={"w-[180px] justify-start text-left font-normal bg-accent h-8"}
                            >
                                {state.dueDate ? state.dueDate.toLocaleString({ month: "long", day: "numeric" }) :
                                    <span className={"text-muted-foreground"}>Due Date</span>}
                            </Button>
                        </PopoverTrigger>
                        <PopoverContent className="w-auto p-0">
                            <Calendar
                                mode="single"
                                defaultMonth={state.dueDate?.toJSDate()}
                                selected={state.dueDate?.toJSDate()}
                                onSelect={setDueDate}
                                initialFocus
                            />
                        </PopoverContent>
                    </Popover>
                </div>
            </div>
        </div>
    </TekkrCard>
}

export type Todo = PlaybookImplementationTodo;

function usePlaybookTodos(): Todo[] {
    const { blueprint } = useBlueprint();
    const { pw } = usePlaybook();

    return computePlaybookTodos(pw, blueprint);
}

function countTodosNotDone(todos: Todo[], edit: PlaybookImplementation["todos"]): number {
    let count = 0;
    todos.forEach(todo => {
        const state = edit?.[todo.id]?.status ?? PlaybookTodoStatus.todo;
        if (state !== PlaybookTodoStatus.done && state !== PlaybookTodoStatus.notDoing) {
            count++;
        }
    });
    return count;
}

function ExportToAsanaButton() {
    const { playbook } = usePlaybook();
    const exportToAsana = async () => {
        const res = await apiClient.exportImplementationTasks({
            params: {
                playbookId: playbook.id,
            },
            body: {
                service: "asana",
            }
        });
        const body = res.body as ClientInferResponseBody<typeof apiContract.exportImplementationTasks>;
        window.open(body.exportResultUrl, "_blank");
    }

    const currentUser = useCurrentOrgUser();
    const asanaConnected = currentUser.linkedAccounts.includes("asana");

    const buttonContent = <>
        <SiAsana className={"w-4 h-4 me-3"} />
        Export to Asana
    </>

    if (!asanaConnected) {
        return <ConnectOauthAccountButton onConnected={exportToAsana} service={"asana"}>{ buttonContent }</ConnectOauthAccountButton>
    }

    return <>
        <Button variant={"secondary"} size={"sm"} onClick={exportToAsana} className={`hover:text-[#F06A6A]`}>{ buttonContent }</Button>
    </>
}

export function ImplementationTodosPage() {
    const { updateImplementation, implementationEdit } = usePlaybookImplementationEdit();
    const todos = usePlaybookTodos();
    const todosNotDoneCount = countTodosNotDone(todos, implementationEdit.todos);

    return <EditScope onEditUpdated={updateImplementation}>
        <StagePageContainer header={"Implement Changes"}
                            explainer={"The items below represent tasks that need to be completed by you or others to complete this playbook implementation. They're based on your custom playbook."}
                            nextButtonDisabled={todosNotDoneCount > 0}>
            <div className={"flex flex-row gap-4 items-center"}>
                <h3>Todos</h3>
                { !!todosNotDoneCount && <div className={"rounded-md w-5 text-center text-white border-foreground text-sm font-semibold bg-primary"}>{todosNotDoneCount}</div> }
                { config.env !== Environment.prod && <ExportToAsanaButton /> }
            </div>
            <div className={"flex flex-col gap-6"}>
                {todos.map((todo) => <TodoItem key={todo.id} todo={todo} />)}
            </div>
        </StagePageContainer>
    </EditScope>;
}