import { Org, OrgUser } from "../../../service/tekkr-service-types";
import { useOrg } from "../../../auth/org-provider";
import { Avatar, AvatarFallback } from "../../../components/ui/avatar";
import { getInitialsForName } from "../../../lib/utils";
import { Button } from "../../../components/ui/button";
import { CalendarClock, CalendarPlus } from "lucide-react";
import React, { useState } from "react";
import Spinner from "../../../components/ui/spinner";
import { AiButton } from "../../../components/shared/ai-button";

function ScheduleButton (props: { user: OrgUser, scheduled: boolean, schedule: (user: OrgUser) => Promise<void> }) {
    const [loading, setLoading] = useState(false);

    const Icon = props.scheduled ? CalendarClock : CalendarPlus;
    const text = props.scheduled ? "Schedule again" : "Schedule Meeting";

    const onClick = async () => {
        setLoading(true);
        try {
            await props.schedule(props.user);
        } catch (error) {
            console.error(error);
        }
        setLoading(false);
    };

    return <AiButton
        isLoading={loading}
        variant={props.scheduled ? "outline" : "default"}
        onClick={onClick}
        size={"sm"}>
        <Icon className={"w-4 h-4 me-2"} />{ text }
    </AiButton>
}

export function MeetingSchedulingMatrix(props: { users: string[], scheduled: string[], onScheduledUpdated?: (scheduled: string[]) => void , schedule?: (user: Org["users"][0]) => Promise<void> }) {
    const org = useOrg();
    const schedule = async (user: Org["users"][0]) => {
        await props.schedule?.(user);
        props.onScheduledUpdated?.([...props.scheduled, user.id]);
    }
    return <div>
        <table>
            <tbody>
            { props.users.map(userId => {
                const scheduled = props.scheduled.includes(userId);
                const user = org.users.find((u) => u.id === userId)!;
                return <tr key={userId}>
                    <td className={"flex flex-row gap-2 items-center h-12"}>
                        <Avatar className={"w-8 h-8"}>
                            <AvatarFallback>{ getInitialsForName(user.name) }</AvatarFallback>
                        </Avatar>
                        <div className={"font-semibold"}>{ user.name }</div>
                        <div className={"text-muted-foreground"}>{ user.title }</div>
                    </td>
                    <td className={"ps-6 h-12"}>
                        <ScheduleButton user={user} scheduled={scheduled} schedule={schedule} />
                    </td>
                </tr>;
            })}
            </tbody>
        </table>
        { props.users.length < 1 && <span className={"text-muted-foreground font-semibold text-sm"}>Looks like you haven't selected anyone yet. Once you do, you'll be able to schedule meetings here.</span>}
    </div>
}