import React, { createContext, useRef } from "react";
import { PlaybookEdit } from "tekkr-common/dist/model/playbook/edit";
import PlaybookSegment from "./components/segment";
import { PlaybookPeopleGroups } from "tekkr-common/dist/model/playbook/people-groups";
import { useBlueprint } from "../../../pages/playbook/controller/hooks/blueprint";

interface Props {
  playbookEdit?: PlaybookEdit;
  peopleGroups?: PlaybookPeopleGroups;
  isEditing: boolean;
}

interface PlaybookViewController {
  goToSegment(index: number): void;
}

export const PlaybookViewControllerContext =
  createContext<PlaybookViewController>({} as PlaybookViewController);

export default function PlaybookView(props: React.PropsWithChildren & Props) {
  const segmentContainerRef = useRef<HTMLDivElement>(null);
  const controller: PlaybookViewController = {
    goToSegment(index: number) {
      if (segmentContainerRef.current?.children[index]) {
        segmentContainerRef.current?.children[index].scrollIntoView({
          behavior: "smooth",
        });
      }
    },
  };

  if (props.playbookEdit && !props.playbookEdit.segments) {
    props.playbookEdit.segments = {};
  }

  const { blueprint } = useBlueprint();

  return (
    <PlaybookViewControllerContext.Provider value={controller}>
      <div className={"flex flex-col gap-4"}>
        <div ref={segmentContainerRef} className={"flex flex-col gap-4"}>
          {blueprint.segments.map((segment, index) => {
            if (
              segment.id &&
              props.playbookEdit &&
              !props.playbookEdit.segments![segment.id]
            ) {
              props.playbookEdit.segments![segment.id] = {};
            }
            return (
              <PlaybookSegment
                playbookEdit={props.playbookEdit}
                key={segment.id ?? index}
                index={index}
                segment={segment}
                isEditing={props.isEditing}
                segmentEdit={props.playbookEdit?.segments?.[segment.id]}
              ></PlaybookSegment>
            );
          })}
        </div>
      </div>
    </PlaybookViewControllerContext.Provider>
  );
}
