import { useState } from "react";

export type IdentifierListInterface = [
  string[],
  (toAdd: string) => void,
  (toRemove: string) => void,
  (newVal: string[]) => void,
]

export function useIdentifierList(
  initial?: string[],
  sideEffect?: (value: string[]) => void
): IdentifierListInterface {
  const [selected, setSelected] = useState<string[]>(initial ?? []);
  function remove(pId: string) {
    const newVal = selected.filter((p) => p !== pId);
    setSelected(newVal);
    if (sideEffect) {
      sideEffect(newVal);
    }
  }
  function add(pId: string) {
    const newVal = [...selected, pId];
    setSelected(newVal);
    if (sideEffect) {
      sideEffect(newVal);
    }
  }
  function replace(newVal: string[]) {
    setSelected(newVal);
    if (sideEffect) {
      sideEffect(newVal);
    }
  }
  return [selected, add, remove, replace];
}
