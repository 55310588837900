import {
    BlueprintReferenceContentBlueprint,
} from "tekkr-common/dist/model/playbook/segment/content/types/blueprint-reference/blueprint";
import { ArrowRight, Unlock } from "lucide-react";
import { Link } from "react-router-dom";
import { Button } from "../../../../../ui/button";
import { SegmentContentViewProps } from "../segment-content";
import React from "react";
import { useQuery } from "@tanstack/react-query";
import { apiClient } from "../../../../../../service/tekkr-service";
import { ClientInferResponseBody } from "@ts-rest/core";
import { apiContract } from "tekkr-common/dist/model/api/api.contract";
import { useOrg } from "../../../../../../auth/org-provider";
import Spinner from "../../../../../ui/spinner";
import { useGlobalDialogs } from "../../../../../../modals/global-dialogs";

export default function BlueprintReferenceSegmentContentView(
    props: SegmentContentViewProps<BlueprintReferenceContentBlueprint>,
) {
    const org = useOrg();
    const { isPending, data } = useQuery({
        queryKey: ["blueprint-meta", org.id, props.content.blueprintId],
        queryFn: async () => {
            const response = await (await apiClient).getBlueprintMetaById({ params: { id: props.content.blueprintId } });
            return response.body as ClientInferResponseBody<
                typeof apiContract.getBlueprintMetaById,
                200
            >;
        },
    });
    const globalDialogs = useGlobalDialogs();
    return (
        <div
            className={
                "border rounded-md px-4 py-3 flex flex-row items-center justify-between mt-6 gap-3"
            }
        >
            {!!data && <>
                <div className={"flex flex-row items-center justify-start gap-3 text-sm"}>
                    <img className={"rounded-md aspect-3/2 h-24 object-cover"} src={data.imageUrl} />
                    <div>
                        <div className={"text-muted-foreground text-xs font-semibold mb-1"}>Playbook</div>
                        <div><b>{data.title}</b></div>
                        <div className={"line-clamp-2"}>{data.summary}</div>
                    </div>
                </div>
                {data.access === "access" && <Button asChild>
                    <Link className={"shrink-0"} to={`/blueprint/${props.content.blueprintId}`}>
                        <div className={"flex flex-row gap-2 items-center"}>
                            Learn more
                            <ArrowRight className={"w-5 h-5"} />
                        </div>
                    </Link>
                </Button>}
                {data.access === "restricted" && <Button className={"hover:drop-shadow-glow-primary transition-all duration-500"} onClick={() => globalDialogs?.showUnlockAllPlaybooksCta()}>
                    <div className={"flex flex-row gap-2 items-center"}>
                        <Unlock className={"w-5 h-5"} />
                        Get access
                    </div>
                </Button>}
            </>}

            {isPending && <div className={"w-full flex flex-row items-center justify-center h-12 text-muted-foreground gap-2"}>
                <Spinner />
                <p className={"text-sm font-semibold"}>loading playbook...</p>
            </div> }

        </div>
    );
}
