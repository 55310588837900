import { Avatar, AvatarFallback } from "../../../../../ui/avatar";
import { ChevronsUpDown, PlusCircle, Users2, X } from "lucide-react";
import React from "react";
import { Button } from "../../../../../ui/button";
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "../../../../../ui/popover";
import {
    Command,
    CommandEmpty,
    CommandGroup,
    CommandInput,
    CommandItem,
    CommandList,
} from "../../../../../ui/command";
import { useIdentifierList } from "../../../../../hooks/set-list";
import {
    PeopleListContentBlueprint,
} from "tekkr-common/dist/model/playbook/segment/content/types/people-list/blueprint";
import { DialogTrigger } from "../../../../../ui/dialog";
import { useOrg } from "../../../../../../auth/org-provider";
import { cn, getInitialsForName } from "../../../../../../lib/utils";
import CreatePersonDialog from "../../../../../../modals/create-person-dialog";
import { TekkrMarkdown } from "../../../../markdown/tekkr-markdown";
import { SegmentContentViewProps } from "../segment-content";
import { usePlaybookPeopleGroups } from "../../../../../../pages/playbook/controller/hooks/people-groups";
import { useScopedEdit } from "../../../../../hooks/use-edit";
import { AvatarForUser } from "../../../../avatar-for-user";

interface Person {
    id: string;
    displayName: string;
    title?: string;
}

interface Props {
    options?: {
        hideTitle?: boolean;
        disableGridAlignment?: boolean;
    };
}

export default function PeopleListSegmentContentView({
                                                         edit,
                                                         content,
                                                         isEditing,
                                                         options,
                                                     }: SegmentContentViewProps<PeopleListContentBlueprint, Props>) {
    const { updateEdit } = useScopedEdit(edit, () => null);
    let startingList: string[] = [];
    if (!content.peopleGroupId && edit && "selectedUsers" in edit) {
        startingList = edit.selectedUsers ?? [];
    }
    const localList = useIdentifierList(
        startingList,
        (selected) => {
            if (edit) {
                updateEdit(() => {
                    edit.selectedUsers = selected;
                });
            }
        },
    );
    const peopleGroups = usePlaybookPeopleGroups();

    const [selected, add, remove] = content.peopleGroupId
        ? peopleGroups.byId(content.peopleGroupId)
        : localList;

    const org = useOrg();
    const orgPeople: Record<string, Person> = Object.fromEntries(
        org.users.map((u) => [
            u.id,
            {
                id: u.id,
                title: u.title,
                displayName: u.name,
            },
        ]),
    );

    const [open, setOpen] = React.useState(false);
    return (
        <div className={!options?.disableGridAlignment ? "grid grid-cols-4" : "flex flex-row items-center gap-2"}>
            <CreatePersonDialog context={"playbook"}></CreatePersonDialog>
            {!options?.hideTitle && <div className={"align-top me-4 py-2 font-semibold flex-shrink-0"}>
                {content.title}:
            </div>}
            <div className={cn("col-span-3", content.description && "border rounded-md p-2")}>
                <div className={"flex flex-row gap-2 flex-wrap w-full"}>
                    {isEditing && (!content.limit || selected.length < content.limit) ? (
                        <Popover open={open} onOpenChange={setOpen}>
                            <PopoverTrigger asChild>
                                <Button
                                    role="combobox"
                                    aria-expanded={open}
                                    className="w-42 justify-between h-9 bg-input text-foreground hover:bg-input hover:text-muted-foreground"
                                >
                                    Add someone
                                    <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                                </Button>
                            </PopoverTrigger>
                            <PopoverContent className="w-[200px] p-0">
                                <Command>
                                    <CommandInput placeholder="Search..." />
                                    <CommandList>
                                        <CommandEmpty>Nothing found.</CommandEmpty>
                                        <CommandGroup>
                                            {Object.values(orgPeople)
                                                .filter((p) => !selected.includes(p.id))
                                                .map((person) => (
                                                    <CommandItem
                                                        className={"cursor-pointer flex-col items-start"}
                                                        key={person.id}
                                                        value={person.displayName + " " + (person.title ?? "")}
                                                        onSelect={() => {
                                                            setOpen(false);
                                                            add(person.id);
                                                        }}
                                                    >
                                                        <div className={"flex flex-row items-center gap-2"}>
                                                            <AvatarForUser user={person} className="w-6 h-6" />
                                                            <div>
                                                                {person.displayName}
                                                                <br />
                                                                <div
                                                                    className={"text-xs text-muted-foreground"}>{person.title}</div>
                                                            </div>
                                                        </div>
                                                    </CommandItem>
                                                ))}
                                        </CommandGroup>
                                    </CommandList>
                                    <hr />
                                    <CommandGroup>
                                        <CreatePersonDialog
                                            context={"playbook"}
                                            onPersonCreated={(id) => {
                                                add(id);
                                                setOpen(false);
                                            }}
                                        >
                                            <DialogTrigger>
                                                <CommandItem className={"gap-3 cursor-pointer"}>
                                                    <PlusCircle className={"w-4 h-4"} />
                                                    <div>Add Person</div>
                                                </CommandItem>
                                            </DialogTrigger>
                                        </CreatePersonDialog>
                                    </CommandGroup>
                                </Command>
                            </PopoverContent>
                        </Popover>
                    ) : null}
                    {Array.from(selected.entries()).map(([, p]) => {
                        const person = orgPeople[p] ?? {
                            id: "deleted",
                            displayName: "Deleted User",
                        };
                        return (
                            <div
                                key={p}
                                className={
                                    "bg-input rounded-md px-2 flex flex-row items-center gap-2 h-9 cursor-default"
                                }
                            >
                                <AvatarForUser className={"w-6 h-6"} user={person} />
                                <span className={"text-sm"}>{person.displayName}</span>
                                {isEditing ? (
                                    <X
                                        onClick={() => remove(p)}
                                        className={"w-4 h-4 cursor-pointer"}
                                    />
                                ) : null}
                            </div>
                        );
                    })}
                    {!isEditing && selected.length === 0 ? (
                        <div
                            className={
                                "border border-secondary rounded-md p-3 flex flex-row items-center gap-2 h-9 text-muted-foreground cursor-default"
                            }
                        >
                            <Users2 className={"w-4 h-4"} />
                            <div>To be selected</div>
                        </div>
                    ) : null}
                </div>
                {content.description && (
                    <TekkrMarkdown
                        className={"mt-2 w-full"}
                        markdown={content.description}
                    ></TekkrMarkdown>
                )}
            </div>
        </div>
    );
}
