import React, { HTMLProps, useContext } from "react";
import { TekkrMarkdown } from "../../../../markdown/tekkr-markdown";
import { MarkdownContentBlueprint } from "tekkr-common/dist/model/playbook/segment/content/types/markdown/blueprint";
import "@mdxeditor/editor/style.css";
import { SegmentContentViewProps } from "../segment-content";
import { useScopedEdit } from "../../../../../hooks/use-edit";
import { cn } from "../../../../../../lib/utils";

interface EditableTextEditContextType {
    isEditing: boolean;
    value: string | undefined;
    setValue: (v: string) => void;
}

const EditableTextEditContext = React.createContext<ContentInstanceContext | null>(null);

interface ContentInstanceContext {
    isEditing: boolean;
    valueForId: (id: string) => string | undefined;
    setValueForId: (id: string, v: string) => void;
}

export function useEditableTextInMarkdownEdit(id: string, defaultValue: string): EditableTextEditContextType | null {
    const ctx =  useContext(EditableTextEditContext);
    if (!ctx) {
        return null;
    }
    const v = ctx.valueForId(id);
    if (v === undefined) {
        ctx.setValueForId(id, defaultValue);
    }
    return {
        isEditing: ctx.isEditing,
        value: v,
        setValue: (v: string) => ctx.setValueForId(id, v),
    }
}

function TekkrGuidanceContainer (props: React.PropsWithChildren<HTMLProps<HTMLDivElement>>) {
    return <div className={cn("border border-primary bg-primary/10 rounded-md px-4 pb-4 pt-5 relative", props.className)}>
        <div className={"bg-primary text-white text-xs px-2 py-0.5 rounded-md font-semibold absolute h-5 -top-2.5"}>Guidance from Tekkr</div>
        { props.children }
    </div>
}

export default function MarkdownSegmentContentView(
    props: SegmentContentViewProps<MarkdownContentBlueprint>,
) {
    if (props.content.id) {
        if (props.edit && !props.edit.editableText) {
            props.edit.editableText = {};
        }
    }
    const { state, updateEdit } = useScopedEdit(props.edit, (e) => ({
        editableTexts: e?.editableText,
    }))

    let view = <div className={("flex flex-row justify-between w-full items-center")}>
        <TekkrMarkdown className={cn(props.content.tekkrGuidance && "[&>*:first-child]:mt-0")} markdown={props.content.markdown}></TekkrMarkdown>
    </div>
    if (props.content.tekkrGuidance) {
        view = <TekkrGuidanceContainer>{view}</TekkrGuidanceContainer>
    }

    if (props.edit) {
        return <EditableTextEditContext.Provider value={{
            isEditing: props.isEditing,
            valueForId: (id: string) => state.editableTexts?.[id],
            setValueForId: (id: string, v: string) => {
                updateEdit((e) => {
                    e!.editableText![id] = v;
                });
            },
        }}>
            {view}
        </EditableTextEditContext.Provider>
    } else {
        return view;
    }
}
