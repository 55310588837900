import React, { useRef, useState } from "react";
import { StagePageContainer } from "../stage-container";
import { usePlaybookTargets } from "../../controller/hooks/targets";
import { PlaybookTargets } from "tekkr-common/dist/model/playbook/targets";
import { Reorder } from "framer-motion";
import { GripVertical, ListPlus, X } from "lucide-react";
import { Button } from "../../../../components/ui/button";
import Contenteditable, { ContenteditableRef } from "../../../../components/shared/contenteditable";
import { usePlaybookTargetsReviewEdit } from "../../controller/hooks/target-review-edit";
import { TekkrCard } from "../../../../components/shared/tekkr-card";
import { useBlueprint } from "../../controller/hooks/blueprint";

const variants: Record<"draft" | "finalize" | "set", { header: string, explainer: string, hint?: string }> = {
    draft: {
        header: "Draft Targets",
        explainer: "To have an effective conversation and get high quality input, we recommend to first create a first draft of targets to review with stakeholders."
    },
    finalize: {
        header: "Finalize Targets",
        explainer: "Define the final targets for this Playbook. Do this after running all of the review meetings you scheduled in previous steps.",
    },
    set: {
        header: "Set Targets",
        explainer: "Set the targets for this Playbook. These targets should reflect the motivation for doing the Playbook in the first place and will guide the following steps."
    }
}

interface SuggestedTarget {
    id: string;
    title: string;
    description: string;
}

interface Props {
    blueprintTargets?: SuggestedTarget[];
    targets: PlaybookTargets;
    onUpdateTargets: (targets: PlaybookTargets) => void;
}

export function TargetsView (props: Props) {
    const { targets, onUpdateTargets: setTargets } = props;
    const [mappedSuggestedTargets] = useState<Record<string, SuggestedTarget>>(Object.fromEntries((props.blueprintTargets ?? []).map(it => [it.id, it])));

    const [inputTitle, setInputTitle] = React.useState("");
    const [inputDescription, setInputDescription] = React.useState("");

    const suggestions = (props.blueprintTargets ?? []).filter(it => !targets.find(target => "id" in target && target.id === it.id));

    const newTargetTitleFieldRef = useRef<ContenteditableRef>(null);
    const newTargetTitleFieldPlaceholderRef = useRef<HTMLDivElement>(null);
    const onClickDisabledTargetButton = () => {
        newTargetTitleFieldRef.current?.focus();
        newTargetTitleFieldPlaceholderRef.current?.classList.add("animate-highlight")
    }

    return <div>
        { targets.length === 0 && <p className={"mb-4 text-sm text-muted-foreground"}>you haven't added any targets yet.</p>}
        <Reorder.Group as={"div"} values={targets} onReorder={setTargets}>
            {targets.map(item => {
                const copy: { title: string, description: string } = !("title" in item) ? mappedSuggestedTargets[item.id] : item;
                return <Reorder.Item className={"border rounded-lg my-3 flex flex-row items-center"} as={"div"} key={"id" in item ? item.id : item.title} value={item}>
                    <div className={"cursor-grab active:cursor-grabbing p-3"}><GripVertical className={"w-5 h-5 text-muted-foreground"} /></div>
                    <div className={"flex flex-row items-center gap-4 py-3 pe-4 grow"}>
                        <div className={"flex flex-col grow"}>
                            <div className={"font-semibold"}>{copy.title}</div>
                            <div className={"text-muted-foreground text-sm"}>{copy.description}</div>
                        </div>
                        <Button size={"sm"} className={"p-2 transition-all"} variant={"ghost"}
                                onClick={() => setTargets(targets.filter(it => it !== item))}><X
                            className={"text-muted-foreground"} /></Button>
                    </div>
                </Reorder.Item>
            })}
        </Reorder.Group>

        <div className={"flex flex-row gap-2 items-center border rounded-lg px-4 py-3"}>
            <div className={"flex flex-col gap-1 grow"}>
                <div className={"relative"}>
                    { inputTitle.length < 1 && <div ref={newTargetTitleFieldPlaceholderRef} className={"absolute -z-20 font-semibold duration-500 text-muted-foreground opacity-80"}>New Target</div> }
                    <Contenteditable ref={newTargetTitleFieldRef} className={"outline-none font-semibold caret-primary"} value={inputTitle} onChange={setInputTitle} />
                </div>
                <div className={"relative"}>
                    { inputDescription.length < 1 && <div className={"absolute top-0 -z-20 text-sm text-muted-foreground opacity-80"}>Description (optional)</div> }
                    <Contenteditable className={"outline-none text-sm caret-primary"} value={inputDescription} onChange={setInputDescription} />
                </div>
            </div>
            <div onClick={inputTitle.trim().length < 1 ? onClickDisabledTargetButton : undefined}>
                <Button className={"transition-all"} disabled={inputTitle.trim().length < 1} onClick={() => {
                    setInputDescription("");
                    setInputTitle("")
                    setTargets([...targets, { title: inputTitle, description: inputDescription }]);
                }}><ListPlus className={"w-5 h-5 me-2"} />Add Target</Button>
            </div>
        </div>

        { suggestions.length > 0 && <div className={"pt-8 pb-6"}>
            <p className={"pb-2 font-semibold"}>Tekkr suggests these targets for this playbook:</p>
            <div className={"grid grid-cols-2 gap-3"}>
                {suggestions.map(it => <div
                    className={"border rounded-md py-2 px-3 cursor-pointer transition-all hover:-translate-y-0.5"}
                    key={it.id}
                    onClick={() => setTargets([...targets, { id: it.id }])}>
                    <div className={"font-semibold"}>{it.title}</div>
                    <div className={"text-sm text-muted-foreground"}>{it.description}</div>
                </div>)}
            </div>
        </div> }

    </div>
}

export function TargetsSettingPage (props: { variant: keyof typeof variants }) {
    const { blueprint } = useBlueprint();
    const [targets, setTargets] = usePlaybookTargets();

    const {targetsReviewEdit, updateTargetsReview} = usePlaybookTargetsReviewEdit();
    const onNext = () => {
        if (props.variant === "draft") {
            targetsReviewEdit.targetDraftDone = true;
        } else {
            targetsReviewEdit.targetSettingDone = true;
        }
        updateTargetsReview();
    }

    const done = props.variant === "draft" ? targetsReviewEdit.targetDraftDone : targetsReviewEdit.targetSettingDone;

    return <StagePageContainer onNext={onNext} key={props.variant} {...variants[props.variant]}>
        <TekkrCard cardStyle={done ? "confirmation" : "primary"}>
            <TargetsView blueprintTargets={blueprint.targets} targets={targets} onUpdateTargets={setTargets} />
        </TekkrCard>
    </StagePageContainer>
}