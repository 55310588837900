import { TargetReviewTemplate } from "tekkr-common/dist/model/playbook/stages/target-review";

interface TargetReviewCopy {
    navigationTitle: string;
    title: string;
    description: string;
}

const copy: Record<TargetReviewTemplate, TargetReviewCopy> = {
    [TargetReviewTemplate.seniorStakeholderReview]: {
        title: "Schedule Target Reviews with 👨‍💼 Senior Stakeholders",
        navigationTitle: "Senior Stakeholders Review",
        description: "Reviewing targets with senior stakeholders ensures alignment on goals and priorities, securing their support and buy-in.",
    },
    [TargetReviewTemplate.techLeadership]: {
        title: "Schedule Target Reviews with Tech Leadership",
        navigationTitle: "Tech Leadership Review",
        description: "Reviewing targets with tech leadership ensures alignment on goals and priorities, securing their support and buy-in.",
    },
    [TargetReviewTemplate.keyImpactedStakeholderReview]: {
        title: "Schedule Targets Review with 👨‍💻 Key Impacted Stakeholders",
        navigationTitle: "Key Impacted Stakeholders Review",
        description: "Review targets with key impacted stakeholders to better understand their needs, uncover potential bottlenecks and get their buy-in for your initiative."
    }
};

export function getCopyForTargetReviewTemplate(templateId: TargetReviewTemplate) {
    return copy[templateId];
}