import { WandSparkles } from "lucide-react";
import React from "react";
import { cn } from "../../lib/utils";

export function AiPlug (props: { children: string, className?: string }) {
    return <div
        className={cn(props.className, "text-sm mt-2 flex flex-row items-center gap-3 bg-gradient-to-br from-pink-400 to-blue-500 bg-clip-text text-transparent")}>
        <WandSparkles className={"w-4 h-4 text-pink-400 shrink-0"} />
        <span>{ props.children }</span>
    </div>
}