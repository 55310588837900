import { useEffect } from "react";

export function useCloseConfirmation(requireConfirmation: boolean) {
    useEffect(() => {
        const onBeforeUnload = (ev: Event) => {
            if (requireConfirmation) {
                ev.preventDefault();
                ev.returnValue = requireConfirmation;
                return requireConfirmation;
            }
        };
        window.addEventListener("beforeunload", onBeforeUnload);
        return () => {
            window.removeEventListener("beforeunload", onBeforeUnload);
        };
    }, [requireConfirmation]);
}