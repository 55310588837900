import { usePlaybook } from "./playbook";
import { useMutation } from "@tanstack/react-query";
import { playbookMutations } from "../queries";
import { useCallback } from "react";
import debounce from "debounce";

export function usePlaybookCommunicationEdit() {
    const { playbook } = usePlaybook();

    if (!playbook.communication) {
        playbook.communication = {
            informStakeholdersDone: false,
            affectedStakeholders: {},
            announcement: {
                done: false,
                message: {},
            },
        };
    }
    const edit = playbook.communication!;

    const mutation = useMutation(playbookMutations.updateCommunication(playbook.id));
    const onUpdated = useCallback(debounce(() => {
        mutation.mutate(edit);
    }, 500), [playbook.id]);

    return {
        communicationEdit: edit,
        updateCommunication: onUpdated,
    }
}