import { config } from "../lib/config";
import {
    initClient,
    InitClientArgs,
    InitClientReturn,
    tsRestFetchApi,
} from "@ts-rest/core";
import { apiContract } from "tekkr-common/dist/model/api/api.contract";
import { getFirebaseAuth } from "../auth/firebase";
import { ApiFetcherArgs } from "@ts-rest/core/src/lib/client";
import { OrgSelector } from "../auth/org-provider";
import { publicApiContract } from "tekkr-common/dist/model/api/public.contract";
import { ErrorCode } from "tekkr-common/dist/model/api/error-codes";

export class ApiError extends Error {
    constructor(
        readonly status: number,
        readonly body: unknown,
    ) {
        super(`Request failed with status code ${status}`);
    }
    static isApiErrorWithCode(e: unknown, code: ErrorCode): e is ApiError {
        if (!(e instanceof ApiError)) {
            return false;
        }
        if (!e.body || typeof e.body !== "object" || !("code" in e.body)) {
            return false;
        }
        return e.body.code === code;
    }
}

function throwApiErrorOnNonSuccess(res: { status: number, body: unknown }) {
    if (res.status < 200 || res.status >= 400) {
        throw new ApiError(res.status, res.body);
    }
}

export type ApiClient = InitClientReturn<typeof apiContract, InitClientArgs>;

export const apiClient: ApiClient = initClient(apiContract, {
    baseUrl: config.serviceHost,
    api: async (args: ApiFetcherArgs) => {
        const token = await getFirebaseAuth().currentUser?.getIdToken();
        if (!token) {
            throw new Error("no authorization token available");
        }
        args.headers["Authorization"] = token;
        const orgId = OrgSelector.getSelectedOrgId();
        if (orgId) {
            args.headers["X-Tekkr-Org"] = orgId;
        }
        const res = await tsRestFetchApi(args);
        throwApiErrorOnNonSuccess(res);
        return res;
    },
});

export type PublicApiClient = InitClientReturn<typeof publicApiContract, InitClientArgs>;

export const publicApiClient: PublicApiClient = initClient(publicApiContract, {
    baseUrl: config.serviceHost,
    api: async (args: ApiFetcherArgs) => {
        const res = await tsRestFetchApi(args);
        throwApiErrorOnNonSuccess(res);
        return res;
    },
})