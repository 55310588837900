/*

 */

import {
    SelectContentBlueprint,
    SelectContentSubject,
    selectSubjectConfigs,
} from "tekkr-common/dist/model/playbook/segment/content/types/select/blueprint";
import React from "react";
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "../../../../../ui/select";
import { icons } from "lucide-react";
import { cn } from "../../../../../../lib/utils";
import { SegmentContentViewProps } from "../segment-content";
import { TekkrMarkdown } from "../../../../markdown/tekkr-markdown";
import { useScopedEdit } from "../../../../../hooks/use-edit";

const subjectIcons: Record<SelectContentSubject, keyof typeof icons> = {
    [SelectContentSubject.location]: "Globe",
    [SelectContentSubject.workingMode]: "Laptop",
    [SelectContentSubject.meetingDuration]: "Clock",
    [SelectContentSubject.meetingDurationDays]: "Clock",
    [SelectContentSubject.meetingCadence]: "Calendar",
    [SelectContentSubject.turnaroundTime]: "CalendarClock",
    [SelectContentSubject.leadTimeWeeks]: "CalendarClock",
};

interface Props {
  options?: {
    disableGridAlignment?: boolean;
  }
}

export default function SelectSegmentContentView({
    content,
    edit,
    isEditing,
    options,
}: SegmentContentViewProps<SelectContentBlueprint, Props>) {
    const config = selectSubjectConfigs[content.subject];

    const { state: selected, updateEdit } = useScopedEdit(edit, (e) => e?.selected ?? content.default);

    function onSelected(selected: string) {
        if (edit) {
            updateEdit(() => {
                edit.selected = selected;
            });
        }
    }

    const IconEl = icons[subjectIcons[content.subject]];

    // todo show icon in edit mode too

    const selectedOption = config.options.find((o) => o.id === selected);

    return (
        <div className={!options?.disableGridAlignment ? "grid grid-cols-4" : "flex flex-row gap-2"}>
            <div className={"me-4 align-top py-2 font-semibold flex-shrink-0"}>
                <TekkrMarkdown markdown={(content.overrides?.title ?? config.title) + ":"} />
            </div>
            <div className={"col-span-3 flex"}>
                {isEditing && (
                    <div className={cn(content.descriptionMarkdown && "border rounded-md p-2")}>
                        <Select onValueChange={(v) => onSelected(v)} value={selected}>
                            <SelectTrigger className="w-[180px] bg-input border-input">
                                <SelectValue placeholder={"Select"} />
                            </SelectTrigger>
                            <SelectContent>
                                <SelectGroup>
                                    {config.options.map((op) => (
                                        <SelectItem key={op.id} value={op.id}>
                                            {op.displayName}
                                        </SelectItem>
                                    ))}
                                </SelectGroup>
                            </SelectContent>
                        </Select>
                        {content.descriptionMarkdown &&
                            <TekkrMarkdown className={"mt-2"} markdown={content.descriptionMarkdown} />}
                    </div>
                )}
                {!isEditing && (
                    <div
                        className={cn(
                            !selected ? "text-muted-foreground" : null,
                            "bg-input rounded-md p-3 flex flex-row items-center gap-2 h-9 cursor-default",
                        )}
                    >
                        <IconEl className={"w-4 h-4"} />
                        <div>
                            {!selected ? "To be selected" : selectedOption!.displayName}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}
