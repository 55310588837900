"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toolTemplateContentBlueprintSchema = void 0;
const zod_1 = require("zod");
const blueprint_1 = require("../../blueprint");
exports.toolTemplateContentBlueprintSchema = blueprint_1.editableContentBlueprintSchema.extend({
    type: zod_1.z.literal(blueprint_1.ContentType.toolTemplate),
    googleFileId: zod_1.z.string(),
    title: zod_1.z.string(),
    description: zod_1.z.string(),
    imageUrl: zod_1.z.string(),
});
