"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.checklistContentEditSchema = exports.customChecklistItemSchema = void 0;
const zod_1 = require("zod");
exports.customChecklistItemSchema = zod_1.z.strictObject({
    title: zod_1.z.string(),
});
exports.checklistContentEditSchema = zod_1.z.strictObject({
    selectedItems: zod_1.z.array(zod_1.z.string()).optional(),
    customItems: zod_1.z.array(exports.customChecklistItemSchema).optional(),
});
