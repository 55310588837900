import { usePlaybook } from "../../../controller/hooks/playbook";
import { useOrg } from "../../../../../auth/org-provider";
import {
    getMeetingCadenceFromSegment,
    getMeetingDurationFromSegment,
    getMeetingParticipantsFromSegment,
} from "../todos";
import { createGoogleCalendarLink } from "../../../../../lib/google-calendar";
import { Calendar, ExternalLink } from "lucide-react";
import React, { useState } from "react";
import { Todo } from "../todos-page";
import { AiPlug } from "../../../../../components/shared/ai-plug";
import { AiButton } from "../../../../../components/shared/ai-button";
import { prompts } from "../../../../../service/prompts";
import { TekkrMarkdown } from "../../../../../components/shared/markdown/tekkr-markdown";
import { useBlueprint } from "../../../controller/hooks/blueprint";

export function MeetingTodoContent (props: { todo: Todo }) {
    const { blueprint } = useBlueprint();
    const { playbook } = usePlaybook();
    const org = useOrg();
    const segment = blueprint.segments.find(s => s.id === props.todo.segmentId)!
    const segmentEdit = playbook.edit?.segments?.[segment.id];

    const [loading, setLoading] = useState<boolean>(false);

    const meeting = {
        title: segment.title,
        recur: getMeetingCadenceFromSegment(segment, segmentEdit),
        duration: getMeetingDurationFromSegment(segment, segmentEdit).minutes,
        participants: getMeetingParticipantsFromSegment(org, segment, segmentEdit, playbook.peopleGroups),
    }
    const schedule = async () => {
        setLoading(true);
        const description = await prompts.implementSegmentBasedMeetingDescription({
            playbookId: playbook.id,
            dynamicVars: { segment_id: segment.id },
        });
        const link = createGoogleCalendarLink({
            description,
            eventName: meeting.title,
            invitees: meeting.participants.map(u => u.email),
            meetingDuration: { minute: meeting.duration },
            recur: meeting.recur?.rfc5545,
        })
        window.open(link, "_blank");
        setLoading(false);
    }

    return <div className={"border rounded-md p-4 flex flex-col gap-1"}>

        <div className={"flex flex-row gap-2 items-center"}>
            <Calendar className={"w-4 h-4"} /><b><TekkrMarkdown markdown={ meeting.title } /></b>
        </div>
        <div className={"text-muted-foreground font-semibold text-sm"}>{meeting.recur && <span>{ meeting.recur.friendlyName } &bull;&nbsp;</span>}{meeting.duration} minutes</div>
        { meeting.participants.length > 0 && <div className={"text-sm"}>
            with&nbsp;
            { meeting.participants.slice(0, 3).map(u => u.name).join(", ") }
            { meeting.participants.length > 3 && ` and ${(meeting.participants.length - 3)} more.` }
        </div> }
        <div className={"mt-3"}>
            <AiButton onClick={schedule} isLoading={loading}>
                <ExternalLink className={"w-5 h-5 me-2"} />Schedule Meeting
            </AiButton>
        </div>
        <AiPlug>Tekkr will generate a meeting description based on your settings for this meeting.</AiPlug>
    </div>
}