import { apiClient } from "./tekkr-service";

interface Options {
    playbookId: string;
    format?: "gcal",
}

type GenerateCallReturnType<T extends Record<string, string> | undefined> = (options: T extends undefined ? Options : Options & { dynamicVars: T }) => Promise<string>
function generateCall<T extends Record<string, string> | undefined = undefined>(prompt: string): GenerateCallReturnType<T> {
    const fn = async (options: Options) => {
        const res = await (await apiClient).generateCopy({
            params: {
                type: prompt,
            },
            body: {
                playbookId: options.playbookId,
                dynamicVars: "dynamicVars" in options ? options.dynamicVars as Record<string, string> : undefined,
            },
            query: {
                format: options.format,
            }
        });
        if (res.status !== 200) {
            throw new Error("copy generation failed.");
        }
        return res.body.message;
    }
    return fn as GenerateCallReturnType<T>;
}

export const prompts = {
    alignPlaybookReviewMessage: generateCall<{ deadline: string }>("align-playbook-review-message"),
    alignPlaybookReviewGroupMeetingDescription: generateCall<{ people_group_name: string }>("align-playbook-review-group-meeting-description"),
    alignPlaybookReviewIndividualMeetingsDescription: generateCall("align-playbook-review-individual-meetings-description"),
    alignRoastingMessage: generateCall<{ deadline: string }>("align-roasting-message"),
    communicateAnnouncementMessage: generateCall("communication-announce-playbook-message"),
    communicateInformAffectedStakeholderMessage: generateCall<{
        responsibilities_list: string,
        affected_user_name: string
    }>("communicate-inform-affected-stakeholder-message"),
    implementSegmentBasedMeetingDescription: generateCall<{ segment_id: string }>("implement-segment-based-meeting-description"),
    targetsCommunicationMessage: generateCall("targets-communication-message"),
    targetsCommunicationMeetingDescription: generateCall("targets-communication-meeting-description"),
    targetsExpectationReviewMeetingDescription: generateCall<{ questions: string }>("targets-expectation-review-meeting-description")
};
