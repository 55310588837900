import { useQuery } from "@tanstack/react-query";
import { apiClient } from "../../service/tekkr-service";
import * as React from "react";
import { Avatar, AvatarFallback, AvatarImage } from "../ui/avatar";
import { getInitialsForName } from "../../lib/utils";

export function AvatarForUser (props: { user: { id: string, name?: string, displayName?: string }, className?: string }) {
    const { data } = useQuery({
        queryKey: ["profile", "picture", props.user.id],
        staleTime: Infinity,
        queryFn: async () => {
            const imgData = await apiClient.getProfilePicture({
                params: {
                    userId: props.user.id,
                },
            });
            if (imgData.status !== 200) {
                return null;
            }
            return imgData.body;
        }
    });

    return <Avatar className={props.className}>
        <AvatarImage src={data ? `data:image/png;base64,${data}` : undefined} />
        <AvatarFallback>{getInitialsForName(props.user.displayName ?? props.user.name ?? "")}</AvatarFallback>
    </Avatar>
}