"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formats = void 0;
exports.validateUniqueIds = validateUniqueIds;
const zod_1 = require("zod");
function validateUniqueIds(visitor) {
    return (input, ctx) => {
        const ids = new Set();
        input.forEach((item) => {
            const id = visitor(item);
            if (!id)
                return;
            if (ids.has(id)) {
                ctx.addIssue({
                    code: zod_1.z.ZodIssueCode.custom,
                    message: `the id ${id} is used more than one time`,
                });
            }
            ids.add(id);
        });
    };
}
exports.formats = {
    kebabCaseRegex: /^[a-z0-9]+(-[a-z0-9]+)*$/g,
};
