import React from "react";
import { SegmentContentViewProps } from "../segment-content";
import {
    PrinciplesContentBlueprint, PrinciplesContentEdit,
} from "tekkr-common/dist/model/playbook/segment/content/types/principles/blueprint";
import { TekkrMarkdownEditor } from "../../../../markdown/editor";
import { InlineEditableTextField } from "../../../../markdown/components/inline-editable";
import { cn } from "../../../../../../lib/utils";
import { TekkrMarkdown } from "../../../../markdown/tekkr-markdown";
import { Button } from "../../../../../ui/button";
import { Plus, ThumbsDown, ThumbsUp, Trash } from "lucide-react";
import { useScopedEdit } from "../../../../../hooks/use-edit";
import { v4 as uuid } from "uuid";
import ConfirmationDialog from "../../../../../../modals/confirmation-dialog";
import { DialogTrigger } from "@radix-ui/react-dialog";

type Principle = PrinciplesContentEdit["principles"][0];

function principleHasExamples(principle: Principle) {
    return !!(principle.positiveExamples?.trim() || principle.negativeExamples?.trim());
}

function PrincipleView(props: { principle: Principle, isEditing: boolean, removeSelf: () => void }) {
    const { state: principle, updateEdit } = useScopedEdit(props.principle, () => props.principle);

    const updateTitle = (title: string) => {
        updateEdit(() => {
            principle.title = title;
        });
    }
    const updateSubtitle = (subtitle: string) => {
        updateEdit(() => {
            principle.subtitle = subtitle;
        });
    }
    const updateDescription = (description: string) => {
        updateEdit(() => {
            principle.description = description;
        });
    }
    const updatePositiveExamples = (positiveExamples: string) => {
        updateEdit(() => {
            principle.positiveExamples = positiveExamples;
        });
    }
    const updateNegativeExamples = (negativeExamples: string) => {
        updateEdit(() => {
            principle.negativeExamples = negativeExamples;
        });
    }

    return <div className={cn("transition-all flex flex-col gap-6 border p-4 rounded-md overflow-hidden")}>
        <div className={cn("transition-all flex flex-col gap-1", props.isEditing && "gap-2")}>
            <div className={"flex flex-row justify-between items-center w-full"}>
                <InlineEditableTextField className={"text-xl font-semibold"} value={principle.title} setValue={updateTitle}
                                         placeholder={"Principle"}
                                         isEditing={props.isEditing} />
                {props.isEditing && <ConfirmationDialog title={"Delete Principle?"}
                                                        body={<p>Are you sure you want to remove <b>{principle.title}</b>?
                                                        </p>} onConfirm={props.removeSelf}>
                    <DialogTrigger asChild>
                        <Button variant={"ghost-destructive"} className={"animate-in slide-in-from-right-2 fade-in-50"}
                                size={"sm"}>
                            <Trash className={"w-5 h-5"} />
                        </Button>
                    </DialogTrigger>
                </ConfirmationDialog>}
            </div>
            { (props.isEditing || !!principle.subtitle?.trim()) && <InlineEditableTextField
                placeholder={"Headline"}
                className={"font-medium -mt-1"}
                value={principle.subtitle}
                setValue={updateSubtitle}
                isEditing={props.isEditing} /> }
        </div>

        { (props.isEditing || !!principle.description?.trim()) && <div className={cn("flex flex-col gap-1")}>
            <p className={"text-sm font-semibold"}>Description:</p>
            {props.isEditing && <TekkrMarkdownEditor placeholder={"Explain in more detail"} className={"animate-in fade-in"}
                                                     markdown={principle.description ?? ""} onChange={updateDescription}
                                                     hideToolbar={true} />}
            {!props.isEditing && <TekkrMarkdown className={"opacity-85"} markdown={principle.description!} />}
        </div>}

        {(props.isEditing || principleHasExamples(principle)) && <div
            className={cn("grid grid-cols-2 animate-in slide-in-from-top-2")}>
            <div className={"flex flex-col me-4"}>
                <div className={"mb-1 flex flex-row items-center"}>
                    <p className={" text-sm font-semibold"}>What this means</p>
                    <ThumbsUp className={"ms-2 w-4 h-4"} />
                </div>
                {props.isEditing &&
                    <TekkrMarkdownEditor className={"animate-in fade-in"} markdown={principle.positiveExamples ?? ""}
                                         onChange={updatePositiveExamples} hideToolbar={true} placeholder={"Examples of behavior in line with this idea"} />}
                {!props.isEditing && <TekkrMarkdown className={"opacity-80"} markdown={principle.positiveExamples ?? ""} />}
            </div>
            <div className={"flex flex-col ms-4"}>
                <div className={"mb-1 flex flex-row items-center"}>
                    <p className={" text-sm font-semibold"}>What this does <span className={"font-semibold"}>not</span> mean</p>
                    <ThumbsDown className={"ms-2 w-4 h-4"} />
                </div>
                {props.isEditing &&
                    <TekkrMarkdownEditor className={"animate-in fade-in"} markdown={principle.negativeExamples ?? ""}
                                         onChange={updateNegativeExamples} hideToolbar={true} placeholder={"Examples of behavior in contrast to this idea"} />}
                {!props.isEditing && <TekkrMarkdown className={"opacity-80"} markdown={principle.negativeExamples ?? ""} />}
            </div>
        </div>}
    </div>
}

export function PrinciplesSegmentContentView(
    props: SegmentContentViewProps<PrinciplesContentBlueprint>,
) {
    if (props.edit && !props.edit.principles) {
        props.edit.principles = [];
    }
    const { state: principles, updateEdit } = useScopedEdit(props.edit, () => (props.edit?.principles ?? []));

    function addPrinciple() {
        updateEdit(() => {
            principles.push({
                id: uuid(),
                title: "New Principle",
                subtitle: ""
            });
        });
    }

    function removePrinciple(id: string) {
        updateEdit(() => {
            principles.splice(principles.findIndex((p) => p.id === id), 1);
        })
    }

    const empty = principles.length === 0;

    if (!props.edit || (!props.isEditing && !principles.length)) {
        // placeholder for discovery mode or when empty in reading mode
        return <div className={"border text-sm font-medium bg-input/20 h-10 rounded-md flex flex-col items-center justify-center relative cursor-not-allowed group overflow-hidden"}>
            <div className={"flex flex-row items-center p-2"}><Plus className={"me-2 w-5 h-5"} /> Add Principle</div>
            <div className={"transition-all duration-300 top-0 bottom-0 left-0 right-0 absolute font-medium flex flex-col items-center justify-center group-hover:backdrop-blur group-hover:bg-muted/20"}>
                <span className={"text-base transition-all duration-300 scale-105 group-hover:scale-100 text-transparent group-hover:text-foreground"}>Start editing to define your ways of working</span>
            </div>
        </div>
    }

    return <div className={"flex flex-col gap-6"}>
        { principles.map(principle => <PrincipleView removeSelf={() => removePrinciple(principle.id)} key={principle.id} principle={principle} isEditing={props.isEditing} />)}
        { props.isEditing && <Button onClick={addPrinciple} className={"w-full"} variant={empty ? undefined : "ghost"}>
            <Plus className={"me-2 w-5 h-5"} /> Add Principle
        </Button> }
    </div>
}