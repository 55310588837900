import React, { MouseEventHandler } from "react";
import { SegmentContentViewProps } from "../segment-content";
import {
    CategorizationContentBlueprint,
    CategorizationContentMode,
} from "tekkr-common/dist/model/playbook/segment/content/types/categorization/blueprint";
import { Checkbox } from "../../../../../ui/checkbox";
import { TekkrMarkdown } from "../../../../markdown/tekkr-markdown";
import { cn } from "../../../../../../lib/utils";
import { useScopedEdit } from "../../../../../hooks/use-edit";
import {
    CategorizationContentEdit,
    CategorizationContentEditSelectionStates,
} from "tekkr-common/dist/model/playbook/segment/content/types/categorization/edit";

function TableHeader (props: { blueprint: CategorizationContentBlueprint }) {
    return <thead>
        <tr>
            { props.blueprint.categories.map(category => <th
                className={"text-center border py-2 px-2"}
                key={category.id}>
                { category.title }
            </th>)}
            <th className={"text-start px-3 py-2"}>{ props.blueprint.itemsTitle }</th>
        </tr>
    </thead>
}

type Selection = Exclude<CategorizationContentEdit["selection"], undefined>;

const SELECTED = CategorizationContentEditSelectionStates.selected;
const OPTIONAL = CategorizationContentEditSelectionStates.optional;

type OnClickFunction = (item: string, category: string, selection: Selection) => void;
function getOnClickFunction (content: CategorizationContentBlueprint): OnClickFunction {
    // tiered is effectively the same as single, the tiering is just frontend sugar
    if (content.mode === CategorizationContentMode.single || content.mode === CategorizationContentMode.tiered) {
        return (item, category, selection) => {
            const previous = selection[item]?.[category]
            if (content.optionalSelection && previous === SELECTED) {
                selection[item] = { [category]: OPTIONAL };
            } else if (previous) {
                delete selection[item];
            } else {
                selection[item] = { [category]: SELECTED };
            }
        }
    } else if (content.mode === CategorizationContentMode.multi) {
        return (item, category, selection) => {
            const itemCurrentState = selection[item] ?? {};
            const previous = itemCurrentState[category]
            if (content.optionalSelection && previous === SELECTED) {
                selection[item] = { ...itemCurrentState, [category]: OPTIONAL };
            } else if (previous) {
                delete itemCurrentState[category];
            } else {
                selection[item] = { ...itemCurrentState, [category]: SELECTED };
            }
        }
    }
    throw new Error(`mode ${content.mode} not supported`);
}

function isAutochecked(content: CategorizationContentBlueprint, item: string, category: string, selection: Selection): boolean {
    const isTiered = content.mode === CategorizationContentMode.tiered;
    if (!isTiered) {
        return false;
    }

    // tiered lists are effectively single select, so there should be only one key
    const selectedCategory = selection[item] ? Object.keys(selection[item])[0] : null;
    const selectedIndex = selectedCategory ? content.categories.findIndex(it => it.id === selectedCategory) : null;
    const categoryIndex = content.categories.findIndex(it => it.id === category);

    return selectedIndex !== null && categoryIndex < selectedIndex;
}

function BodyRow (props: {
    blueprint: CategorizationContentBlueprint,
    item: CategorizationContentBlueprint["items"][0],
    isEditing: boolean,
    selection: Selection,
}) {
    const { state: selection, updateEdit } = useScopedEdit(props.selection, () => props.selection);
    const onClickFunction = getOnClickFunction(props.blueprint);

    return <tr key={props.item.id} className={"border"}>
        {props.blueprint.categories.map((category) => {
            const selected = selection[props.item.id]?.[category.id];
            const onClick: MouseEventHandler = (e) => {
                e.preventDefault();
                if (!props.isEditing) {
                    return;
                }
                updateEdit(() => {
                    onClickFunction(props.item.id, category.id, props.selection);
                })
            }
            const autochecked = isAutochecked(props.blueprint, props.item.id, category.id, props.selection);
            const checked = !!selected || autochecked;
            const disabled = !props.isEditing && !checked;
            return <td
                className={"align-top border"}
                key={category.id}>
                <div className={cn("m-1", props.isEditing && "cursor-pointer")} onClick={onClick}>
                    <Checkbox
                        checked={checked}
                        checkStyle={selected === OPTIONAL ? "optional" : undefined}
                        className={cn("my-2 mx-2.5 transition-all", !props.isEditing && "cursor-default", disabled && "border-border", autochecked && "opacity-45")} />
                </div>
            </td>;
        })}
        <td>
            <div className={"flex flex-col items-start m-1 py-1 px-2"}>
                <div className={"font-semibold"}>{props.item.title}</div>
                {props.item.description &&
                    <TekkrMarkdown className={"text-sm text-muted-foreground"} markdown={props.item.description} /> }
            </div>
        </td>
    </tr>;
}

export default function CategorizationSegmentContentView(
    props: SegmentContentViewProps<CategorizationContentBlueprint>,
) {
    if (props.edit && !props.edit.selection) {
        props.edit.selection = {};
    }
    const { state } = useScopedEdit(props.edit, (e) => ({
        selection: e?.selection
    }));

    const items = props.isEditing ? props.content.items : props.content.items.filter(it => Object.keys(state.selection?.[it.id] ?? {}).length > 0)

    return <>
        <div className={"flex flex-col"}>
            { props.content.title && <h4>{ props.content.title }</h4> }
            { props.content.prompt && props.isEditing && <TekkrMarkdown className={cn("text-muted-foreground transition-all animate-in fade-in")} markdown={props.content.prompt} /> }
        </div>
        { items.length > 0 && <table className={"border mt-1"}>
            <TableHeader blueprint={props.content} />
            <tbody>
                {items.map((item) => {
                    return <BodyRow
                        isEditing={props.isEditing}
                        key={item.id}
                        blueprint={props.content}
                        item={item}
                        selection={props.edit!.selection!}
                    />
                })}
            </tbody>
        </table> }
        { props.content.optionalSelection && props.isEditing && <div className={"flex flex-row items-center space-1"}>
            <Checkbox className={"cursor-default"} checkStyle={"optional"} checked={true} />
            <span>&nbsp;= optional</span>
        </div>}
        { items.length === 0 && <span className={"text-muted-foreground font-semibold"}>none selected</span> }
    </>
}