import { icons } from "lucide-react";
import Rating from "../../rating";
import { Avatar, AvatarFallback, AvatarImage } from "../../../ui/avatar";
import { Progress } from "../../../ui/progress";
import React from "react";
import { PlaybookMeta } from "../../playbook-card";
import { getInitialsForName } from "../../../../lib/utils";
import { formats } from "tekkr-common/dist/utils/formatting";
import { useOrg } from "../../../../auth/org-provider";
import { useColorScheme } from "../../../../lib/color-scheme";

export default function PlaybookStats(props: {
  progress?: number;
  pb: Pick<PlaybookMeta, "companyStages" | "teamSize" | "author" | "rating">;
  mode: "discover" | "copy";
}) {
  const stats = [
    {
      icon: icons.Users,
      description: "Tech Team Size",
      value: formats.friendly.teamSizeApplicability(props.pb.teamSize),
    },
    {
      icon: icons.Building,
      description: "Company Stage",
      value: formats.friendly.companyStageApplicability(props.pb.companyStages),
    },
    {
      icon: icons.ArrowUp,
      iconColor: "green",
      description: "Relevance for you",
      value: "High",
    },
  ];

  const org = useOrg();
  const { isDark } = useColorScheme();

  return (
    <div className={"animate-in slide-in-from-bottom-4 duration-300 fade-in"}>
      <hr />
      <div className={"flex flex-row justify-between mt-3"}>
        <div>
          { props.mode === "discover" && <>
            <div className={"flex flex-row items-center gap-2 flex-wrap"}>
              <Rating rating={props.pb.rating.score} />
              {!!props.pb.rating.reviewCount && <div className={"text-muted-foreground"}>
                {formats.friendly.rating(props.pb.rating)} (used by {props.pb.rating.reviewCount} companies)
              </div>}
              {!props.pb.rating.reviewCount &&
                  <div className={"bg-primary px-1.5 py-0.5 font-semibold rounded-md text-xs text-white"}>NEW</div>}
            </div>
            <div className={"mt-2 flex flex-row items-center gap-2"}>
              <span className={"text-muted-foreground"}>By </span>
              <Avatar className={"w-6 h-6"}>
                <AvatarImage src={props.pb.author.imageUrl}></AvatarImage>
                <AvatarFallback>
                  {getInitialsForName(props.pb.author.name)}
                </AvatarFallback>
              </Avatar>
              <span>
              <span className={"font-semibold"}>{props.pb.author.name}</span>
                {props.pb.author.role ? (
                    <span>&nbsp;({props.pb.author.role})</span>
                ) : null}
            </span>
            </div>
          </>}
          { props.mode === "copy" && <>
            {org.logo && <img alt={"org logo"} className={"h-10"} src={ isDark ? org.logo.urlWhite : org.logo.urlBlack } />}
          </>}
        </div>
        {props.progress !== undefined && !isNaN(props.progress) ? (
            <div className={"flex flex-col items-end gap-2"}>
              <Progress
                  value={props.progress * 100}
                  className={"h-2.5 w-24"}
                  color={"confirmation"}
              ></Progress>
              <div className={"text-muted-foreground"}>
                {props.progress === 0
                    ? "Not started"
                    : Math.round(props.progress * 100) + "%"}
              </div>
            </div>
        ) : null}
      </div>
      <div className={"border rounded-lg bg-accent flex px-4 py-2 mt-8"}>
        {stats.map((stat) => {
          const StatIcon = stat.icon;
          return (
              <div
                  key={stat.description}
                  className={"w-full flex flex-row gap-1"}
              >
                <StatIcon className={"px-1 py-1"} color={stat.iconColor} />
                <div className={"flex flex-col"}>
                  <div>{stat.value}</div>
                  <div className={"text-muted-foreground text-sm"}>
                    {stat.description}
                  </div>
                </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
