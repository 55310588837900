import {
    PlaybookTargetsReviewBlueprint,
    PlaybookTargetsReviewEdit,
} from "tekkr-common/dist/model/playbook/stages/target-review";
import React from "react";
import { useMutation } from "@tanstack/react-query";
import { CommunicationChannel } from "tekkr-common/dist/model/playbook/enums/enums";
import { usePlaybookPeopleGroups } from "../../controller/hooks/people-groups";
import { createGoogleCalendarLink } from "../../../../lib/google-calendar";
import { Playbook } from "tekkr-common/dist/model/playbook/playbook";
import { SegmentContainer } from "../../../../components/shared/playbook-view/components/segment";
import { Checkbox } from "../../../../components/ui/checkbox";
import { Label } from "../../../../components/ui/label";
import { useScopedEdit } from "../../../../components/hooks/use-edit";
import { GroupsMeetingSchedulingView } from "../../components/groups-meeting-scheduling-view";
import { prompts } from "../../../../service/prompts";
import { EditableTextContentEdit } from "tekkr-common/dist/model/playbook/segment/content/types/editable-text/edit";
import EditableTextSegmentContentView
    from "../../../../components/shared/playbook-view/components/content/types/editable-text";
import { ContentType } from "tekkr-common/dist/model/playbook/segment/content/blueprint";

type Communication = Exclude<PlaybookTargetsReviewBlueprint["communication"], undefined>;

type MessageCommunicationEdit = {
    content?: {
        message?: EditableTextContentEdit;
    }
}

const channelDescriptions: Record<CommunicationChannel, string> = {
    [CommunicationChannel.meeting]: "Schedule a Meeting",
    [CommunicationChannel.message]: "Send a Message",
};

function MessageCommunicationView(props: { playbookId: string, edit: MessageCommunicationEdit }) {
    if (!props.edit.content!.message) {
        props.edit.content!.message = {};
    }

    const generateMessage = async () => {
        return await prompts.targetsCommunicationMessage({ playbookId: props.playbookId });
    }

    return <div>
        <div className={"mb-4"}>
            Send this message to everyone who was involved in the target setting.
            It is good practice to create a channel now if you're using chat (Slack, Teams, etc.)
        </div>
        <EditableTextSegmentContentView copy={"message"} content={{
            id: "message", type: ContentType.editableText
        }} edit={props.edit.content!.message} isEditing={true} textGenerator={generateMessage} />
    </div>;
}

type MeetingCommunicationEdit = {
    content?: {
        duration?: {
            selected?: string;
        }
        meetingScheduled?: boolean;
    }
}

function MeetingCommunicationView(props: { communication: Communication, playbookTitle: string, playbookId: string, edit: MeetingCommunicationEdit }) {
    const eventName = `Kick-Off and Target Review "${props.playbookTitle}"`;

    const peopleGroups = usePlaybookPeopleGroups();

    const { state, updateEdit } = useScopedEdit(props.edit, (e) => ({
        meetingScheduled: e.content?.meetingScheduled ?? false,
    }))

    const generateMessageAndOpen = useMutation({
        mutationFn: async (durationMinutes: number) => {
            if (!state.meetingScheduled) {
                updateEdit((e) => {
                    e.content!.meetingScheduled = true;
                })
            }
            const description = await prompts.targetsCommunicationMeetingDescription({
                playbookId: props.playbookId,
                format: "gcal",
            });
            const invitees = peopleGroups.getOrgUsersInPeopleGroups(props.communication.peopleGroups);

            const link = createGoogleCalendarLink({
                description,
                eventName: eventName,
                invitees: invitees.map(u => u.email),
                meetingDuration: { minute: durationMinutes },
            });
            console.log(link);
            window.open(link, "_blank");
        }
    });

    if (!props.edit.content!.duration) {
        props.edit.content!.duration = {};
    }

    return <GroupsMeetingSchedulingView reschedule={state.meetingScheduled} durationEdit={props.edit.content!.duration} title={eventName} scheduleMutation={generateMessageAndOpen} />
}

export function CommunicationView(props: { playbook: Playbook, communication: Communication, communicationEdit: PlaybookTargetsReviewEdit["communication"] }) {
    const communicationEdit = props.communicationEdit;

    if (!communicationEdit.channels) {
        communicationEdit.channels = {};
    }

    const singleChannel = props.communication.channels.length === 1;

    const { state, updateEdit } = useScopedEdit(communicationEdit, (e) => ({
        done: e.done ?? false,
        channels: singleChannel ? [props.communication.channels[0]] : props.communication.channels.filter((c) => e.channels[c]?.enabled),
    }));

    const setDone = (done: boolean) => {
        updateEdit((e) => {
            e.done = done;
        });
    }

    return <>
        <SegmentContainer
            id={"communication"}
            name={"Communication"}
            title={"Communicate Targets to Stakeholders"}
            isEditing={true}
            hidden={false}
            expanded={true}
            done={state.done}
            options={{
                hideControls: true,
            }}
            setDone={setDone}>
            <div className={"flex flex-col gap-4"}>
                { !singleChannel && <>
                    <h4>Select Channels</h4>
                    <div className={"flex flex-col gap-3 pb-2"}>
                        {props.communication.channels.map((channel) => {
                            if (!communicationEdit.channels[channel]) {
                                communicationEdit.channels[channel] = {
                                    enabled: false,
                                    content: {},
                                };
                            }
                            const onCheckedChange = (checked: boolean) => {
                                updateEdit((e) => {
                                    e.channels[channel]!.enabled = checked;
                                });
                            };
                            return <div key={channel} className="flex items-center space-x-2">
                                <Checkbox checked={communicationEdit.channels[channel]?.enabled}
                                          onCheckedChange={onCheckedChange}
                                          id={`communication-checkbox-${channel}`} />
                                <Label
                                    htmlFor={`communication-checkbox-${channel}`}>{channelDescriptions[channel]}</Label>
                            </div>;
                        })}
                    </div>
                    <hr className={"-mx-10"} />
                </>}
                {state.channels.map((channel, index) => {
                    if (!communicationEdit.channels[channel]) {
                        // todo do this in a nicer way
                        communicationEdit.channels[channel] = {
                            enabled: false,
                            content: {},
                        };
                    }
                    return <React.Fragment key={channel}>
                        { index > 0 && <hr className={"-mx-10"} /> }
                        <h4>{channelDescriptions[channel]}</h4>
                        {channel === CommunicationChannel.message &&
                            <MessageCommunicationView
                                edit={communicationEdit.channels[channel] as MessageCommunicationEdit}
                                playbookId={props.playbook.id}
                            />
                        }
                        {channel === CommunicationChannel.meeting &&
                            <MeetingCommunicationView
                                communication={props.communication}
                                playbookTitle={props.playbook.edit!.title}
                                edit={communicationEdit.channels[channel] as MeetingCommunicationEdit}
                                playbookId={props.playbook.id}
                            />
                        }
                    </React.Fragment>
                })}
            </div>
        </SegmentContainer>
    </>
}