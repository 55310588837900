import { confetti } from "@tsparticles/confetti";

function randomInRange(min: number, max: number) {
    return Math.random() * (max - min) + min;
}

export const triggerConfetti = () => {
    for (const i of [1, 2, 3, 5, 6, 8]) {
        setTimeout(() => {
            void confetti({
                angle: randomInRange(220, 250),
                spread: randomInRange(50, 70),
                particleCount: 40,
                origin: { x: randomInRange(0.85, 1), y: 0.05 },
                shapes: ["circle", "square"],
            });
        }, i * 100);
    }
    setTimeout(() => {
        void confetti({
            angle: randomInRange(270, 270),
            spread: 180,
            particleCount: 30,
            origin: { x: 0.5, y: 0.0 },
            shapes: ["emoji"],
            scalar: 4,
            shapeOptions: {
                emoji: {
                    value: ["🦄"],
                },
            },
        });
    }, 900);
    setTimeout(() => {
        void confetti({
            angle: randomInRange(-30, -60),
            spread: randomInRange(50, 70),
            particleCount: randomInRange(50, 100),
            origin: { x: 0.05, y: 0.05 },
        });
    }, 150);
    setTimeout(() => {
        void confetti({
            angle: randomInRange(-10, -30),
            spread: randomInRange(50, 70),
            particleCount: randomInRange(50, 100),
            origin: { x: 0, y: 0.15 },
        });
    }, 250);
    setTimeout(() => {
        void confetti({
            angle: randomInRange(-10, -30),
            spread: randomInRange(50, 70),
            particleCount: randomInRange(50, 100),
            origin: { x: 0, y: 0.2 },
        });
    }, 350);
};