import { PlaybookStage } from "tekkr-common/dist/model/playbook/enums/enums";
import { Flag, Hammer, LucideIcon, MessagesSquare, Pen, PieChart, Users } from "lucide-react";
import React from "react";

export function IconForPlaybookStage(props: Pick<React.HTMLProps<LucideIcon>, "className"> & { stage: PlaybookStage }) {
    if (props.stage === PlaybookStage.targets) {
        return <Flag {...props} />
    } else if (props.stage === PlaybookStage.alignment) {
        return <Users {...props} />
    } else if (props.stage === PlaybookStage.implementation) {
        return <Hammer {...props} />
    } else if (props.stage === PlaybookStage.edit) {
        return <Pen {...props} />
    } else if (props.stage === PlaybookStage.communication) {
        return <MessagesSquare {...props} />
    } else if (props.stage === PlaybookStage.tracking) {
        return <PieChart {...props} />
    } else {
        throw new Error(`unknown stage ${props.stage}`);
    }
}
