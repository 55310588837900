import { ContentSpacer } from "../common/layout";
import { Button } from "../../components/ui/button";
import { DialogTrigger } from "../../components/ui/dialog";
import { useOrg, useOrgController } from "../../auth/org-provider";
import CreatePersonDialog from "../../modals/create-person-dialog";
import { Mail, Slack, UserPlus, X } from "lucide-react";
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from "../../components/ui/table";
import React, { useEffect } from "react";
import { useMutation } from "@tanstack/react-query";
import { apiClient } from "../../service/tekkr-service";
import Spinner from "../../components/ui/spinner";
import ConfirmationDialog from "../../modals/confirmation-dialog";
import { useSearchParams } from "react-router-dom";
import { Org } from "../../service/tekkr-service-types";
import { AvatarForUser } from "../../components/shared/avatar-for-user";

function UserRow({ u }: { u: Org["users"][0]; org: Org }) {
    const inviteMutation = useMutation({
        mutationFn: async () => {
            await apiClient.createUserInvite({
                params: { userId: u.id },
                query: {
                    sendEmail: "true",
                },
                body: null,
            });
        },
    });
    return (
        <TableRow>
            <TableCell>
                <div className={"flex flex-row items-center gap-2"}>
                    <AvatarForUser className={"w-8 h-8"} user={u} />
                    {u.name}
                </div>
            </TableCell>
            <TableCell className={"text-muted-foreground"}>{u.title ? u.title : <span className={"opacity-45"}>&mdash;</span>}</TableCell>
            <TableCell>{u.email}</TableCell>
            <TableCell className={"flex flex-row items-center gap-2 justify-end"}>
                {!u.hasAccount && (
                    <>
                        <ConfirmationDialog
                            title={"Send email invite?"}
                            body={<p>Do you want to send an email invite to {u.name}?</p>}
                            onConfirm={() => inviteMutation.mutate()}
                        >
                            <DialogTrigger asChild>
                                <Button
                                    variant={"outline-primary"}
                                    className={inviteMutation.isPending ? "invisible" : undefined}
                                    size={"sm"}
                                >
                                    <Mail className={"w-5 h-5 me-2"} />
                                    Invite
                                </Button>
                            </DialogTrigger>
                        </ConfirmationDialog>
                        {inviteMutation.isPending && <Spinner />}
                    </>
                )}
                <Button className={"hidden"} variant={"ghost"} size={"sm"}>
                    <X />
                </Button>
            </TableCell>
        </TableRow>
    );
}

export default function OrgPage() {
    const org = useOrg();
    const orgController = useOrgController();
    const [queryParams, setQueryParams] = useSearchParams();

    const importSlackUsersMutation = useMutation({
        mutationFn: async () => {
            await (await apiClient).importSlackUsers({
                body: undefined,
            });
            await orgController.refetch();
            queryParams.delete("import_users");
            setQueryParams(queryParams);
        },
    })

    useEffect(() => {
        document.title = "Manage Organization - Tekkr";
    });

    return (
        <ContentSpacer>
            <h1 className={"slide-in-top-variant-1"}>Your Organization: {org.name}</h1>
            <p className={"slide-in-bottom-variant-2"}>
                Need to bring more team members on board? You can easily add new users
                to your organization right from this page. Expand your team's
                capabilities and keep everyone in the loop.
            </p>
            <hr className={"my-4 zoom-in-line"} />
            <div className={"flex flex-row items-center gap-4 slide-in-bottom-variant-1"}>
                <h3>Members</h3>
                <CreatePersonDialog context={"org"}>
                    <DialogTrigger asChild>
                        <Button size={"sm"} className={"animate-in slide-in-from-left-1 fade-in-50 duration-300"}>
                            <UserPlus className={"w-5 h-5"} />
                            <span className={"ms-2"}>Add Member</span>
                        </Button>
                    </DialogTrigger>
                </CreatePersonDialog>
                <Button size={"sm"} variant={"secondary"} asChild className={"animate-in slide-in-from-left-4 fade-in-50 duration-500"}>
                    <a href={`https://slack.com/oauth/v2/authorize?client_id=7950302676806.7950312898054&scope=users:read,users:read.email&user_scope=&redirect_uri=https%3A%2F%2F${window.location.host}/install-slack`}>
                        <Slack className={"w-5 h-5 me-2"}/> Import Members from Slack
                    </a>
                </Button>
            </div>

            <ConfirmationDialog
                fetching={importSlackUsersMutation.isPending}
                open={queryParams.get("import_users") === "slack"}
                title={"Import Slack Members?"}
                body={<p>Do you want to import all members of your slack workspace?</p>}
                onConfirm={() => {
                    importSlackUsersMutation.mutate();
                }} />

            <Table className={"slide-in-bottom-variant-3"}>
                <TableBody>
                    <TableRow>
                        <TableHead className="w-[200px]">Name</TableHead>
                        <TableHead className="w-[200px]">Title</TableHead>
                        <TableHead>Email</TableHead>
                        <TableHead></TableHead>
                    </TableRow>
                    {org.users.map((u) => (
                        <UserRow key={u.id} u={u} org={org} />
                    ))}
                </TableBody>
            </Table>
        </ContentSpacer>
    );
}
