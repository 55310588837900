import { Avatar, AvatarImage } from "../ui/avatar";
import React from "react";
import { cn } from "../../lib/utils";
import philipp from "../../static/avatars/philipp-roemer.jpeg";
import filo from "../../static/avatars/filippo-pirri.jpeg";
import tomi from "../../static/avatars/tomi-oladimeji.jpeg";

export default function TeamAvatars(props: React.HTMLProps<HTMLDivElement>) {
  return (
    <div
      {...props}
      className={cn(props.className, "flex flex-row justify-center")}
    >
      <Avatar className={"w-8 h-8 border-card border-2"}>
        <AvatarImage
          src={filo}
          alt="@filo"
        />
      </Avatar>
      <Avatar className={"w-8 h-8 -mx-2 border-card border-2"}>
        <AvatarImage
          src={tomi}
          alt="@tomi"
        />
      </Avatar>
      <Avatar className={"w-8 h-8 border-card border-2"}>
        <AvatarImage
          src={philipp}
          alt="@philipp"
        />
      </Avatar>
    </div>
  );
}
