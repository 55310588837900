import React, { createContext, useContext, useEffect } from "react";
import { apiClient, ApiError } from "../../service/tekkr-service";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import Spinner from "../../components/ui/spinner";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { InternalErrorPageContent, NotFoundErrorPageContent } from "../../components/shared/error-message";
import PlaybookEditPage from "./stages/edit";
import { PlaybookStage } from "tekkr-common/dist/model/playbook/enums/enums";
import { PlaybookSidebar } from "./sidebar";
import { PlaybookProvider } from "./controller/hooks/playbook";
import PlaybookIntroDialog from "../../modals/playbook-intro-dialog";
import { PlaybookStageIntroPage } from "./stages/stage-intro-page";
import { TargetsSettingPage } from "./stages/targets/targets-setting-page";
import { ScheduleTargetReviewsPage } from "./stages/targets/schedule-target-reviews-page";
import { CommunicateTargetsPage } from "./stages/targets/communicate-targets-page";
import { AlignmentRoastingPage } from "./stages/alignment/roasting-page";
import { playbookQueries } from "./controller/queries";
import { AlignmentReviewPage } from "./stages/alignment/playbook-review-page";
import { ImplementationTodosPage } from "./stages/implementation/todos-page";
import { getPlaybookNav } from "./controller/navigation";
import { InformStakeholdersCommunicationPage } from "./stages/communicate/inform-stakeholders-communication-page";
import { AnnouncementCommunicationPage } from "./stages/communicate/announcement-communication-page";
import { computePlaybookEditProgress } from "tekkr-common/dist/model/playbook/state";
import { cn } from "../../lib/utils";
import { useCurrentOrgUser } from "../../auth/org-provider";
import { ContentSpacer } from "../common/layout";
import { TrackMetricsPage } from "./stages/track/track-metrics-page";
import { PlaybookEditCompletedDialog } from "../../modals/playbook-edit-completed-dialog";
import { BlueprintProvider } from "./controller/hooks/blueprint";

export interface PlaybookPageNavigationState {
    openEditMode?: boolean;
}

interface NavPosition {
    stage: PlaybookStage;
    step?: string;
    stepId?: string;
}

function PlaybookPageNavContent({ navPosition }: { navPosition: NavPosition }) {
    if (navPosition.stage === PlaybookStage.edit) {
        return <PlaybookEditPage />
    }
    if (navPosition.stage === PlaybookStage.targets) {
        if (!navPosition.step) {
            return <PlaybookStageIntroPage key={PlaybookStage.targets} stage={PlaybookStage.targets} />
        } else if (navPosition.step === "draft") {
            return <TargetsSettingPage variant={"draft"} />
        } else if (navPosition.step === "set") {
            return <TargetsSettingPage variant={"set"} />
        } else if (navPosition.step === "finalize") {
            return <TargetsSettingPage variant={"finalize"} />
        } else if (navPosition.step === "schedule-review") {
            return <ScheduleTargetReviewsPage targetReviewId={navPosition.stepId!} />
        } else if (navPosition.step === "communicate") {
            return <CommunicateTargetsPage />
        }
    }
    if (navPosition.stage === PlaybookStage.alignment) {
        if (!navPosition.step) {
            return <PlaybookStageIntroPage key={PlaybookStage.alignment} stage={PlaybookStage.alignment} />
        } else if (navPosition.step === "roasting") {
            return <AlignmentRoastingPage roastingId={navPosition.stepId!} />
        } else if (navPosition.step === "schedule-review") {
            return <AlignmentReviewPage reviewId={navPosition.stepId!} />
        }
    }
    if (navPosition.stage === PlaybookStage.implementation) {
        if (!navPosition.step) {
            return <PlaybookStageIntroPage key={PlaybookStage.implementation} stage={PlaybookStage.implementation} />
        } else if (navPosition.step === "todos") {
            return <ImplementationTodosPage />
        }
    }
    if (navPosition.stage === PlaybookStage.communication) {
        if (!navPosition.step) {
            return <PlaybookStageIntroPage key={PlaybookStage.communication} stage={PlaybookStage.communication} />
        } else if (navPosition.step === "inform-stakeholders") {
            return <InformStakeholdersCommunicationPage />
        } else if (navPosition.step === "announce") {
            return <AnnouncementCommunicationPage />
        }
    }
    if (navPosition.stage === PlaybookStage.tracking) {
        if (!navPosition.step) {
            return <PlaybookStageIntroPage key={PlaybookStage.tracking} stage={PlaybookStage.tracking} />
        } else if (navPosition.step === "metrics") {
            return <TrackMetricsPage />
        }
    }
    return <></>
}

interface PlaybookPageNavigation {
    goToNext(): void;
    deletePlaybook(): void;
    showEditCompletedDialog(): void;
}
const PlaybookPageNavigationContext = createContext<PlaybookPageNavigation | null>(null);

export function usePlaybookPageNavigation(): PlaybookPageNavigation | null {
    return useContext(PlaybookPageNavigationContext);
}

function PlaybookPage() {
    const { playbookId } = useParams();
    if (!playbookId) {
        throw new Error("missing playbook id");
    }
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const deleteMutation = useMutation({
        mutationFn: async () => {
            await (await apiClient).deletePlaybook({
                params: { playbookId },
                body: undefined,
            });
            await queryClient.invalidateQueries({
                queryKey: ["library-playbooks"],
            });
            navigate("/library");
        },
    });

    const { isError, error, data, refetch: refetchPlaybook } = useQuery(playbookQueries.getPlaybook(playbookId));

    const [query, setQuery] = useSearchParams();
    const navPosition: NavPosition = {
        stage: query.get("view") as PlaybookStage ?? undefined,
        step: query.get("step") ?? undefined,
        stepId: query.get("step_id") ?? undefined,
    }
    const setStage = (selected: { stage: PlaybookStage, step?: string, stepId?: string }, replace: boolean = false) => {
        if (selected.stage === navPosition.stage && selected.step === navPosition.step && selected.stepId === navPosition.stepId) {
            return; // do nothing, we're already here.
        }
        query.set("view", selected.stage);
        if (selected.step) {
            query.set("step", selected.step);
        } else {
            query.delete("step");
        }
        if (selected.stepId) {
            query.set("step_id", selected.stepId);
        } else {
            query.delete("step_id");
        }
        setQuery(query, {
            replace: replace,
        });
    };

    let content = (
        <div className={"w-full flex flex-col items-center mt-12 animate-in slide-in-from-bottom-2 fade-in"}>
            <Spinner className={"w-8 h-8"}></Spinner>
            <div className={"mt-4 text-sm text-secondary-foreground animate-in fade-in-50 slide-in-from-bottom-2 duration-500"}>Loading Playbook...</div>
        </div>
    );

    const currentUserId = useCurrentOrgUser().id;
    const navigationOptional = data ? getPlaybookNav(data.playbook, data.blueprint, currentUserId) : null;

    useEffect(() => {
        if (navigationOptional && !navPosition.stage) {
            const stage = navigationOptional.stages.find(s => s.state === "todo");
            if (!stage) {
                setStage({
                    stage: PlaybookStage.edit,
                }, true);
            } else if (!stage.steps) {
                setStage({
                    stage: stage.stage,
                }, true);
            } else {
                // @ts-expect-error compiler doesn't allow iterator here for some reason.
                for (const [index, step] of stage.steps.entries()) {
                    if (step.state === "todo") {
                        if (index === 0 && stage.stage !== PlaybookStage.tracking) {
                            setStage({
                                stage: stage.stage,
                            }, true);
                        } else {
                            setStage({
                                stage: stage.stage,
                                step: step.step,
                                stepId: step.id,
                            }, true);
                        }
                        break;
                    }
                }
            }
        }
    }, [navigationOptional, navPosition.stage]);

    const [showEditCompleteDialog, setShowEditCompleteDialog] = React.useState(false);

    if (isError) {
        if (error instanceof ApiError && error.status === 404) {
            content = <ContentSpacer><NotFoundErrorPageContent /></ContentSpacer>;
        } else {
            content = <ContentSpacer><InternalErrorPageContent /></ContentSpacer>;
        }
    } else if (deleteMutation.isPending) {
        content = <div className={"flex flex-row items-center justify-center gap-2 font-semibold"}><Spinner /> Deleting
            Playbook...</div>;
    } else if (data) {
        if (!data.playbook.peopleGroups) {
            data.playbook.peopleGroups = {};
        }
        const navigation = navigationOptional!;

        const navContext: PlaybookPageNavigation = {
            goToNext() {
                const stack: NavPosition[] = [];
                for (const stage of navigation.stages) {
                    stack.push({ stage: stage.stage });
                    for (const step of stage.steps ?? []) {
                        stack.push({ stage: stage.stage, step: step.step, stepId: step.id });
                    }
                }
                let found: boolean = false;
                for (const cur of stack) {
                    if (found) {
                        return setStage(cur);
                    } else if (cur.stage === navPosition.stage && cur.step === navPosition.step && cur.stepId === navPosition.stepId) {
                        found = true;
                    }
                }
            },
            showEditCompletedDialog() {
                setShowEditCompleteDialog(true);
            },
            deletePlaybook() {
                deleteMutation.mutate();
            },
        }

        const introOpen = query.get("new_playbook") === "true";
        const closeIntro = () => {
            query.delete("new_playbook");
            setQuery(query);
        }

        const introDialogStages = new Set<PlaybookStage>();
        for (const stage of navigation.stages) {
            introDialogStages.add(stage.stage);
        }

        const editDone = computePlaybookEditProgress(data.blueprint, data.playbook.edit) === 1.0;

        content = <BlueprintProvider blueprint={data.blueprint}>
            <PlaybookProvider data={{ ...data, refetch: refetchPlaybook }}>
                <PlaybookPageNavigationContext.Provider value={navContext}>
                    <PlaybookEditCompletedDialog open={showEditCompleteDialog} nav={navigation} onOpenChange={setShowEditCompleteDialog} />
                    <PlaybookIntroDialog open={introOpen} onOpenChange={closeIntro} stages={introDialogStages}
                                         playbookTitle={data.playbook.edit?.title ?? data.blueprint.title} />
                    <div className={"flex flex-row relative"}>
                        { editDone && <PlaybookSidebar nav={navigation} selected={navPosition} onSelect={setStage} /> }
                        <div className={cn("grow", editDone && "ms-56")}>
                            <ContentSpacer>
                                <ContentSpacer contentWidth={"narrower"}>
                                    <PlaybookPageNavContent navPosition={navPosition} />
                                </ContentSpacer>
                            </ContentSpacer>
                        </div>
                    </div>
                </PlaybookPageNavigationContext.Provider>
            </PlaybookProvider>
        </BlueprintProvider>;
    }
    return content;
}

export default PlaybookPage;