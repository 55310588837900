import { Check, Copy } from "lucide-react";
import { Button } from "../ui/button";
import React from "react";
import { cn } from "../../lib/utils";
import { useDebouncedCallback } from "use-debounce";

function copyRichText(rich: string, plain: string) {
    if (typeof ClipboardItem !== "undefined") {
        // Shiny new Clipboard API, not fully supported in Firefox.
        // https://developer.mozilla.org/en-US/docs/Web/API/Clipboard_API#browser_compatibility
        const html = new Blob([rich], { type: "text/html" });
        const text = new Blob([plain], { type: "text/plain" });
        const data = new ClipboardItem({ "text/html": html, "text/plain": text });
        void navigator.clipboard.write([data]);
    } else {
        // Fallback using the deprecated `document.execCommand`.
        // https://developer.mozilla.org/en-US/docs/Web/API/Document/execCommand#browser_compatibility
        const cb = (e: ClipboardEvent) => {
            e.clipboardData?.setData("text/html", rich);
            e.clipboardData?.setData("text/plain", plain);
            e.preventDefault();
        };
        document.addEventListener("copy", cb);
        document.execCommand("copy");
        document.removeEventListener("copy", cb);
    }
}

const copyConfirmationClasses = ["bg-confirmation/40", "hover:bg-confirmation/40"];

export function CopyButton (props: { onCopy: () => { html: string, raw: string } | null }) {
    const [confirming, setConfirming] = React.useState(false);
    const onCopy = () => {
        setConfirming(true);
        setTimeout(() => {
            setConfirming(false);
        }, 800)
        const content = props.onCopy();
        if (content) {
            copyRichText(content.html, content.raw);
        }
    }
    const debouncedOnCopy = useDebouncedCallback(onCopy, 800, {
        leading: true,
        trailing: false,
    });
    return <Button variant={"ghost"} size={"sm"} className={cn("transition-colors", confirming && copyConfirmationClasses)} onClick={debouncedOnCopy}>
        <div className={cn("transition-all me-2", confirming && "")}>
            { confirming ? <Check className={"w-5 h-5 animate-in spin-in-45"}/> : <Copy className={"w-5 h-5 animate-in zoom-in-90"}/> }
        </div>
        <span className={"transition-all"}>Copy</span>
    </Button>
}