import { PlaybookSidebarNavigation } from "../sidebar";
import { PlaybookBlueprint } from "tekkr-common/dist/model/playbook/blueprint";
import { getCopyForTargetReviewTemplate } from "../../../copy/target-reviews";
import { PlaybookStage } from "tekkr-common/dist/model/playbook/enums/enums";
import { Playbook } from "tekkr-common/dist/model/playbook/playbook";
import { getAffectedStakeholders } from "../stages/communicate/communication";
import { PlaybookTodoStatus } from "tekkr-common/dist/model/playbook/stages/implementation";
import { SegmentStyle } from "tekkr-common/dist/model/playbook/segment/blueprint";

type NavStage = PlaybookSidebarNavigation["stages"][0];

function getTargetsNav(playbook: Pick<Playbook, "targetsReview">, blueprint: Pick<PlaybookBlueprint, "targetReview">): NavStage {
    const steps: NavStage["steps"] = [];
    if (blueprint.targetReview.expectationReviews?.length) {
        steps.push({
            step: "draft",
            name: "Draft Targets",
            icon: "edit",
            state: playbook.targetsReview?.targetDraftDone ? "done" : "todo"
        });
        blueprint.targetReview.expectationReviews?.forEach((review) => {
            const copy = getCopyForTargetReviewTemplate(review.copy.template);
            steps.push({
                step: "schedule-review",
                name: copy.navigationTitle,
                id: review.id,
                icon: "schedule",
                state: playbook.targetsReview?.reviews[review.id]?.done ? "done" : "todo"
            });
        })
        if ((blueprint.targetReview.expectationReviews?.length ?? 0) > 0) {
            steps.push({
                step: "finalize",
                name: "Finalize Targets",
                icon: "target",
                state: playbook.targetsReview?.targetSettingDone ? "done" : "todo",
            });
        }
    } else {
        steps.push({
            step: "set",
            name: "Set Targets",
            icon: "target",
            state: playbook.targetsReview?.targetSettingDone ? "done" : "todo",
        });
    }
    if (blueprint.targetReview?.communication) {
        steps.push({
            step: "communicate",
            name: "Communicate Targets",
            icon: "communicate",
            state: playbook.targetsReview?.communication?.done ? "done" : "todo",
        });
    }
    return {
        stage: PlaybookStage.targets,
        state: steps.find(s => s.state === "todo") ? "todo" : "done",
        steps,
    }
}

function getAlignmentNav(playbook: Pick<Playbook, "alignment">, blueprint: Pick<PlaybookBlueprint, "alignment">): NavStage {
    const steps: NavStage["steps"] = [];

    blueprint.alignment?.playbookReviews?.forEach((review) => {
        steps.push({
            step: "schedule-review",
            id: review.id,
            name: review.navigationTitle,
            icon: "schedule",
            state: playbook.alignment?.playbookReviews?.[review.id]?.done ? "done" : "todo",
        });
    });
    blueprint.alignment?.roastings?.forEach((roasting) => {
        steps.push({
            step: "roasting",
            id: roasting.id,
            name: "Roasting",
            icon: "roasting",
            state: playbook.alignment?.roastings?.[roasting.id]?.done ? "done" : "todo",
        });
    });

    return {
        stage: PlaybookStage.alignment,
        state: steps.find(s => s.state === "todo") ? "todo" : "done",
        steps,
    }
}

function getImplementationNav(playbook: Pick<Playbook, "edit" | "implementation">, blueprint: Pick<PlaybookBlueprint, "segments">): NavStage {
    const steps: NavStage["steps"] = [];

    const todoSegments = blueprint.segments.filter(segment => segment.todo && !playbook.edit?.segments?.[segment.id]?.hidden)
    if (todoSegments.length) {
        const done = !todoSegments.find(segment => {
            const status = playbook.implementation?.todos?.[segment.id]?.status;
            return status !== PlaybookTodoStatus.done && status !== PlaybookTodoStatus.notDoing;
        })
        steps.push({
            icon: "todos",
            name: "Implement Changes",
            step: "todos",
            state: done ? "done" : "todo",
        });
    }

    return {
        stage: PlaybookStage.implementation,
        state: steps.find(s => s.state === "todo") ? "todo" : "done",
        steps,
    }
}

function getCommunicationNav(playbook: Playbook, blueprint: PlaybookBlueprint, currentUserId: string): NavStage {
    const steps: NavStage["steps"] = [];

    const affectedStakeholders = getAffectedStakeholders(playbook, blueprint, currentUserId);
    if (Object.keys(affectedStakeholders).length > 0) {
        steps.push({
            icon: "messages",
            name: "Inform Stakeholders",
            step: "inform-stakeholders",
            state: playbook.communication?.informStakeholdersDone ? "done" : "todo",
        });
    }

    if (blueprint.communication?.announcement) {
        steps.push({
            icon: "communicate",
            name: "Announce",
            step: "announce",
            state: playbook.communication?.announcement.done ? "done" : "todo",
        });
    }

    return {
        stage: PlaybookStage.communication,
        state: steps.find(s => s.state === "todo") ? "todo" : "done",
        steps,
    }
}

export function getPlaybookNav(playbook: Playbook, blueprint: PlaybookBlueprint, currentUserId: string): PlaybookSidebarNavigation {
    const stages: NavStage[] = [];

    const pushStageIfHasSteps = (stage: NavStage) => {
        if(stage.steps && stage.steps.length > 0) {
            stages.push(stage);
        }
    }

    const editState = blueprint.segments.find(s => {
        const edit = playbook.edit?.segments?.[s.id];
        return s.style === SegmentStyle.card && !(edit?.hidden || edit?.done);
    }) ? "todo" : "done"
    stages.push({ stage: PlaybookStage.edit, state: editState });
    stages.push(getTargetsNav(playbook, blueprint));
    pushStageIfHasSteps(getAlignmentNav(playbook, blueprint));
    pushStageIfHasSteps(getImplementationNav(playbook, blueprint));
    pushStageIfHasSteps(getCommunicationNav(playbook, blueprint, currentUserId));

    if (blueprint.dummyMetrics) {
        stages.push({
            stage: PlaybookStage.tracking,
            state: "todo",
            steps: [
                { icon: "graphs", name: "Metrics", state: "todo", step: "metrics" }
            ]
        });
    }

    return { stages };
}

export const testingScope = {
    getTargetsNav,
    getAlignmentNav,
    getImplementationNav,
    getCommunicationNav,
}