import React from "react";
import { SegmentContentViewProps } from "../segment-content";
import { useCurrentOrgUser } from "../../../../../../auth/org-provider";
import { useMutation } from "@tanstack/react-query";
import { apiClient } from "../../../../../../service/tekkr-service";
import { usePlaybookOptional } from "../../../../../../pages/playbook/controller/hooks/playbook";
import {
    ToolTemplateContentBlueprint
} from "tekkr-common/dist/model/playbook/segment/content/types/tool-template/blueprint";
import { ToolTemplateContentEdit } from "tekkr-common/dist/model/playbook/segment/content/types/tool-template/edit";
import { Button } from "../../../../../ui/button";
import { useScopedEdit } from "../../../../../hooks/use-edit";
import { ReactComponent as GoogleSheetsLogo } from '../../../../../../static/icons/google-sheets.svg';
import { Paperclip, Wrench } from "lucide-react";
import Spinner from "../../../../../ui/spinner";
import { ConnectOauthAccountButton } from "../../../../connect-oauth-account-button";


function openReference(reference: ToolTemplateContentEdit["reference"]) {
    if (reference.type === "google-sheet") {
        window.open(`https://docs.google.com/spreadsheets/d/${reference.fileId}/edit`, "_blank");
    } else {
        throw new Error(`reference type ${reference.type} not supported`);
    }
}

function ToolReferenceView({ reference, blueprint }: {
    reference: ToolTemplateContentEdit["reference"],
    blueprint: ToolTemplateContentBlueprint,
}) {
    if (reference.type === "google-sheet") {
        const url = `https://docs.google.com/spreadsheets/d/${reference.fileId}/edit`;
        return <a href={url} target={"_blank"} rel={"noreferrer"}
            className={"bg-accent py-2 px-4 rounded-md cursor-pointer inline-flex flex-row items-center gap-2 transition-all hover:bg-secondary min-w-0"}>
            <Paperclip className={"w-5 h-5 opacity-65 shrink-0"} />
            <GoogleSheetsLogo className={"w-6 h-6 shrink-0"} />
            <div className={"grow shrink flex flex-col min-w-0"}>
                <p className={"font-semibold line-clamp-1 overflow-ellipsis"}>{blueprint.title}</p>
                <p className={"text-xs text-muted-foreground line-clamp-1 overflow-ellipsis"}>{url}</p>
            </div>
        </a>
    } else {
        throw new Error(`reference type ${reference.type} not supported`);
    }
}

function UseToolView(props: { isLoading: boolean, onUseTool: () => void }) {
    const currentUser = useCurrentOrgUser();
    const googleConnected = currentUser.linkedAccounts.includes("google");

    if (props.isLoading) {
        return <div className={"flex flex-row items-center gap-2"}>
            <Spinner />
            <p className={"font-semibold text-sm"}>Setting up tool...</p>
        </div>;
    }

    if (!googleConnected) {
        return <div className={"flex flex-col items-start gap-1"}>
            <ConnectOauthAccountButton service={"google"} onConnected={props.onUseTool}><Wrench className={"w-5 h-5 me-2"} />Use Tool</ConnectOauthAccountButton>
            <span className={"text-muted-foreground text-xs"}>This will create a file in your Google Drive and link it to this playbook.</span>
        </div>
    } else {
        return <div className={"flex flex-col items-start gap-1"}>
            <Button onClick={props.onUseTool}><Wrench className={"w-5 h-5 me-2"} />Use Tool</Button>
            <span className={"text-muted-foreground text-xs"}>This will create a file in your Google Drive and link it to this playbook.</span>
        </div>
    }
}

export default function ToolTemplateSegmentContentView({
    content,
    edit,
    isEditing,
}: SegmentContentViewProps<ToolTemplateContentBlueprint>) {
    const pb = usePlaybookOptional();

    const { state, updateEdit } = useScopedEdit(edit, () => ({
        reference: edit?.reference,
    }));

    const useTemplateMutation = useMutation({
        mutationFn: async () => {
            const res = await apiClient.useToolTemplate({
                params: {
                    playbookId: pb!.playbook.id,
                },
                body: {
                    service: "google",
                    blueprint: content,
                },
            });
            const body = res.body as ToolTemplateContentEdit;
            updateEdit((e) => {
                e!.reference = body.reference;
            });
            openReference(body.reference);
        }
    });

    return <div
        className={
            "border rounded-md px-4 py-3 grid grid-cols-3 items-center justify-between space-x-4 relative"
        }
    >
        <img
            src={content.imageUrl}
            alt={content.title}
            className={
                "aspect-3/2 rounded-md overflow-hidden bg-accent object-cover"
            }
            style={{ objectPosition: "top left" }}
        />
        <div className={"col-span-2"}>
            <h4>{content.title}</h4>
            {content.description ? (
                <p className={"text-muted-foreground text-sm"}>{content.description}</p>
            ) : null}
            <div className={"flex flex-col gap-2 mt-2"}>
                {state.reference && <ToolReferenceView reference={state.reference} blueprint={content} />}
                {!state.reference && isEditing && <UseToolView isLoading={useTemplateMutation.isPending} onUseTool={useTemplateMutation.mutate} />}
            </div>
        </div>
    </div>
}