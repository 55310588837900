import React from "react";
import { Button } from "../../../components/ui/button";
import { cn } from "../../../lib/utils";
import { usePlaybookPageNavigation } from "../playbook-page";

interface Props {
    header: string;
    skipButtonText?: string;
    variant?: "plain";
    explainer?: string;
    nextButtonDisabled?: boolean;
    onNext?: () => boolean | void;
    hideNavigationButtons?: boolean;
    className?: string;
}

export function StagePageContainer (props: React.PropsWithChildren & Props) {
    const playbookPageNavigation = usePlaybookPageNavigation();

    return <div className={cn("flex flex-col gap-4", props.className)}>
        <h1 className={cn("animate-in fade-in-70 duration-300", props.variant === "plain" && "mb-2")}>{ props.header }</h1>
        { props.explainer && <p className={"animate-in fade-in-70 slide-in-from-bottom-1 duration-500"}>{ props.explainer }</p>}
        { props.variant !== "plain" && <hr className={"animate-in zoom-in-50 duration-500"} /> }
        <div className={"animate-in fade-in-50 slide-in-from-top-0.5 duration-500 flex flex-col gap-3"}>
            { props.children }
        </div>
        { !props.hideNavigationButtons && <div className={"flex flex-row gap-4 justify-end"}>
            { /* <Button variant={"outline"}>{ props.skipButtonText ?? "Skip" } <ChevronLast className={"w-5 h-5 ms-2"} /></Button> */ }
            <Button
                disabled={props.nextButtonDisabled}
                onClick={() => {
                    if (!props.onNext || props.onNext() !== false) {
                        playbookPageNavigation?.goToNext()
                    }
                }}
            >Next</Button>
        </div> }
    </div>
}