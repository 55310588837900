import { ContentSpacer } from "../common/layout";
import { BookMarked, BookOpen, Search } from "lucide-react";
import PlaybookCard from "../../components/shared/playbook-card";
import { useAuth } from "../../auth/auth-provider";
import React, { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { apiClient } from "../../service/tekkr-service";
import { ClientInferResponseBody } from "@ts-rest/core";
import { apiContract } from "tekkr-common/dist/model/api/api.contract";
import LoadingCard from "../../components/shared/loading-card";
import { useGlobalDialogs } from "../../modals/global-dialogs";
import { useDebouncedCallback } from "use-debounce";
import { Input } from "../../components/ui/input";
import { cn } from "../../lib/utils";
import Spinner from "../../components/ui/spinner";
import { useOrg } from "../../auth/org-provider";

type Blueprint = ClientInferResponseBody<
  typeof apiContract.listBlueprints,
  200
>["data"][0];

const allBlueprintsQuery = (orgId: string) => ({
  queryKey: ["all-blueprints", orgId],
  queryFn: async () => {
    const response = await (await apiClient).listBlueprints({ query: {} });
    return (
      response.body as ClientInferResponseBody<
        typeof apiContract.listBlueprints,
        200
      >
    ).data;
  },
});
const searchBlueprintsQuery = (orgId: string, term: string) => ({
  queryKey: ["blueprints-search", orgId],
  queryFn: async () => {
    const response = await (
      await apiClient
    ).listBlueprints({
      query: {
        term,
      },
    });
    return (
      response.body as ClientInferResponseBody<
        typeof apiContract.listBlueprints,
        200
      >
    ).data;
  },
});

function BlueprintsList(props: {
  display?: "grid" | "list";
  blueprints: Blueprint[];
}) {
  const globalDialogs = useGlobalDialogs();

  const layoutClassName = cn(
    props.display === "list"
      ? "flex flex-col gap-6"
      : "grid lg:grid-cols-3 sm:grid-cols-2 gap-3"
  );

  // We can assume by this point that `isSuccess === true`
  return (
    <div>
      <div className={layoutClassName}>
        {props.blueprints.map((blueprint) => (
          <PlaybookCard
            locked={blueprint.access === "restricted"}
            key={blueprint.id}
            linkTo={`/blueprint/${blueprint.id}`}
            variant={props.display === "list" ? "horizontal" : "vertical"}
            data={blueprint}
            onLockedClick={() => globalDialogs?.showUnlockAllPlaybooksCta()}
          />
        ))}
      </div>
    </div>
  );
}

function getFeaturedBlueprint(blueprints: Blueprint[]): Blueprint | undefined {
  const accessible = blueprints.filter((bp) => bp.access === "access");
  if (accessible.length === 1) {
    return accessible[0];
  }
  return undefined;
}

function DiscoveryPage() {
  const auth = useAuth();
  const org = useOrg();

  useEffect(() => {
    document.title = "Tekkr";
  });

  const bpQuery = useQuery(allBlueprintsQuery(org.id));

  const [searchTerm, setSearchTerm] = useState("");
  const searchQuery = useQuery(searchBlueprintsQuery(org.id, searchTerm));
  const debouncedRefetch = useDebouncedCallback(() => {
    searchQuery.refetch();
  }, 250);
  function onSearchTermChange(term: string) {
    setSearchTerm(term);
    debouncedRefetch();
  }

  const featured = bpQuery.data
    ? getFeaturedBlueprint(bpQuery.data)
    : undefined;

  // todo nicer empty state for search results

  return (
    <ContentSpacer>
      <div className={"flex flex-col gap-4 py-8 items-center"}>
        <h1 className={"text-center w-full max-w-3xl animate-in fade-in duration-300 slide-in-from-top-1"}>
          Hey {auth.user?.displayName?.trim().split(" ")[0]}!<br />
          What can we help you master today?
        </h1>
        <p className={"text-center max-w-3xl animate-in fade-in slide-in-from-bottom-1 duration-500"}>
          Tekkr Playbooks is a curation of the most advanced ideas, practices
          and tools brought together by Tekkr and other tech organizations to
          make tech teams work better.
        </p>
        <div className={"relative w-full max-w-md mt-4 animate-in fade-in slide-in-from-bottom-2 duration-500"}>
          <Input
            value={searchTerm}
            onChange={(e) => onSearchTermChange(e.target.value)}
            className={"bg-input ps-10 pe-3"}
            placeholder={"Search for title, topics or pain points..."}
          ></Input>
          <div className={"absolute py-3 px-3 top-0 bottom-0 left-0"}>
            <Search className={"w-4 h-4"} />
          </div>
        </div>
      </div>

      {searchTerm.trim() === "" && (
        <>
          {featured && (
            <>
              <div className={"flex flex-row justify-start gap-2 mt-8"}>
                <BookMarked />
                <h4>
                  Your personal playbook awaits!
                </h4>
              </div>
              <PlaybookCard
                locked={featured.access === "restricted"}
                linkTo={`/blueprint/${featured.id}`}
                variant={"horizontal"}
                data={featured}
              ></PlaybookCard>
            </>
          )}

          <div className={"flex flex-row justify-start gap-2 mt-8 animate-in fade-in duration-500"}>
            <BookOpen />
            <h4>Browse All Playbooks</h4>
          </div>
          {bpQuery.isError && (
            <div
              className={"border border-destructive rounded-lg p-4 text-center"}
            >
              <p className={"text-muted-foreground font-semibold"}>
                Error: {bpQuery.error.message}
              </p>
            </div>
          )}
          {bpQuery.isPending && (
            <div className={"grid md:grid-cols-3 sm:grid-cols-2 gap-3 animate-in fade-in duration-700"}>
              {Array.from({ length: 4 }).map((_, index) => (
                <LoadingCard key={index} />
              ))}
            </div>
          )}
          {!bpQuery.isPending && !bpQuery.isError && (
            <BlueprintsList display={"grid"} blueprints={bpQuery.data!} />
          )}
        </>
      )}

      {searchTerm !== "" && (
        <>
          {searchQuery.isError && (
            <div
              className={"border border-destructive rounded-lg p-4 text-center"}
            >
              <p className={"text-muted-foreground font-semibold"}>
                Error: {searchQuery.error.message}
              </p>
            </div>
          )}
          {!searchQuery.isError && searchQuery.data?.length !== 0 && (
            <div className={"flex flex-row justify-start gap-2"}>
              <h4>
                Results for &quot;{searchTerm.trim()}&quot;
              </h4>
              <div
                className={cn(
                  "text-sm text-muted-foreground flex flex-row items-center gap-2 transition-all ms-4",
                  searchQuery.isFetching ? "opacity-100" : "opacity-0"
                )}
              >
                <Spinner className={"w-4 h-4"} />
                searching...
              </div>
            </div>
          )}
          {searchQuery.isPending && (
            <div className={"flex flex-col gap-3"}>
              {Array.from({ length: 4 }).map((_, index) => (
                <LoadingCard key={index} />
              ))}
            </div>
          )}
          {!searchQuery.isPending && !searchQuery.isError && (
            <>
              {searchQuery.data!.length > 0 && (
                <BlueprintsList
                  display={"list"}
                  blueprints={searchQuery.data!}
                />
              )}
              {searchQuery.data!.length === 0 && (
                <>
                  <div className={"text-center"}>
                    <p className={"font-semibold"}>
                      We couldn't find any playbooks related to your search 😢
                    </p>
                    <p
                      className={
                        "mt-4 text-center text-sm text-muted-foreground"
                      }
                    >
                      Make sure all words are spelled correctly.
                      <br />
                      Try different or more general keywords.
                    </p>
                  </div>
                </>
              )}
            </>
          )}
        </>
      )}
    </ContentSpacer>
  );
}

export default DiscoveryPage;
