import {
    SelectContentBlueprint,
    SelectContentSubject, selectSubjectConfigs,
} from "tekkr-common/dist/model/playbook/segment/content/types/select/blueprint";
import { SelectContentEdit } from "tekkr-common/dist/model/playbook/segment/content/types/select/edit";

export function getMeetingDurationMinutes (edit: SelectContentEdit, blueprint: SelectContentBlueprint): number {
    if (blueprint.subject !== SelectContentSubject.meetingDuration) {
        throw new Error(`can't get meeting duration minutes for subject ${blueprint.subject}`);
    }

    const selected = edit?.selected ?? blueprint.default;
    const durationOption = selectSubjectConfigs[SelectContentSubject.meetingDuration].options.find(it => selected === it.id);
    if (!durationOption) {
        throw new Error(`${selected} is not a valid option for meeting duration`)
    }

    return durationOption.numeric!;
}

export function getTimelineDays (edit: SelectContentEdit, blueprint: SelectContentBlueprint): number {
    if (blueprint.subject !== SelectContentSubject.turnaroundTime) {
        throw new Error(`can't get meeting timeline days for subject ${blueprint.subject}`);
    }

    const selected = edit?.selected ?? blueprint.default;
    const durationOption = selectSubjectConfigs[SelectContentSubject.turnaroundTime].options.find(it => selected === it.id);
    if (!durationOption) {
        throw new Error(`${selected} is not a valid option for turnaround time`)
    }

    return durationOption.numeric!;
}