import { cn } from "../../lib/utils";
import { Skeleton } from "../ui/skeleton";
import Spinner from "../ui/spinner";
import { Button, ButtonProps } from "../ui/button";
import React, { useEffect, useRef } from "react";
import { confetti } from "@tsparticles/confetti";
import { easings } from "../../lib/easings";
import { clamp } from "lodash";

const triggerConfetti = (clickPos: { x: number; y: number }) => {
    void confetti({
        particleCount: 10,
        spread: 360,
        startVelocity: 10,
        origin: clickPos,
        shapes: ["star"],
        scalar: 0.25,
        gravity: -3,
        colors: ["#F9A8D4", "#60A5FA"]
    });
}

export function AiButton (props: { isLoading: boolean, onClick?: () => void, className?: string, variant?: ButtonProps["variant"], size?: ButtonProps["size"] } & React.PropsWithChildren) {
    const scheduleButtonRef = useRef<HTMLButtonElement>(null);
    useEffect(() => {
        if (props.isLoading) {
            const interval = setInterval(() => {
                const button = scheduleButtonRef.current;
                if (!button) return;

                const rect = button.getBoundingClientRect();
                const xMin = rect.left / window.innerWidth;
                const xMax = rect.right / window.innerWidth;
                const yMin = rect.top / window.innerHeight;
                const yMax = rect.bottom / window.innerHeight;

                if (xMax < 1.0 && xMin > 0.0 && yMax < 1.0 && yMin > 0.0){
                    triggerConfetti({
                        x: xMin + Math.random() * (xMax - xMin),
                        y: yMin + Math.random() * (yMax - yMin),
                    });
                }

            }, 250);
            return () => {
                clearInterval(interval);
            }
        }
    }, [props.isLoading]);

    const progressBarRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        if (props.isLoading) {
            const start = new Date().getTime();
            const interval = setInterval(() => {
                if (!progressBarRef.current) return;
                const passed = new Date().getTime() - start;
                const progress = easings.easeOutCirc(clamp(passed / 10_000, 0, 1));
                progressBarRef.current.style.transform = `scaleX(${progress})`;
            }, 50);
            return () => {
                if (progressBarRef.current) {
                    progressBarRef.current.style.transform = `scaleX(0.0)`;
                }
                clearInterval(interval);
            }
        }
    }, [props.isLoading]);

    return <Button size={props.size} variant={props.variant} ref={scheduleButtonRef} onClick={props.onClick} className={cn(props.className, props.isLoading && "drop-shadow-glow-primary border-none", "transition-all duration-700 overflow-clip")} disabled={props.isLoading}>
        <div className={cn("contents", props.isLoading && "invisible")}>
            { props.children }
        </div>
        <div className={cn(props.isLoading ? "visible" : "invisible", "contents transition-all duration-500")}>
            <div ref={progressBarRef} style={{ transform: "scaleX(0.0)", transformOrigin: "left" }} className={cn(props.isLoading ? "opacity-50" : "opacity-0", "transition-all bg-white top-0 bottom-0 absolute left-0 right-0")}></div>
            <Skeleton className={cn(props.isLoading ? "opacity-100" : "opacity-0 invisible", "transition-all absolute bg-primary bg-gradient-to-br from-blue-400 pointer-events-none top-0 bottom-0 left-0 right-0 rounded-md")}></Skeleton>
            <Spinner className={cn(props.isLoading ? "opacity-100" : "opacity-0", "transition-all absolute text-white")} />
        </div>
    </Button>
}