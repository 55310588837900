import { CommunicationView } from "./communication-view";
import React from "react";
import { usePlaybook } from "../../controller/hooks/playbook";
import { StagePageContainer } from "../stage-container";
import { PlaybookTargetsReviewEdit } from "tekkr-common/dist/model/playbook/stages/target-review";
import { EditScope, useScopedEdit } from "../../../../components/hooks/use-edit";
import { usePlaybookTargetsReviewEdit } from "../../controller/hooks/target-review-edit";
import { useBlueprint } from "../../controller/hooks/blueprint";

export function PageViews (props: { edit: PlaybookTargetsReviewEdit }) {
    const { blueprint } = useBlueprint();
    const { playbook } = usePlaybook();

    const { updateEdit } = useScopedEdit(props.edit, () => {});

    const onNext = () => {
        updateEdit(() => {
            props.edit.communication.done = true;
        });
    }

    if (!blueprint.targetReview?.communication) {
        throw new Error("cannot use communication page for blueprint without communication enabled.")
    }

    return <StagePageContainer header={"Communicate Targets"} explainer={"The goal of this step is to build organizational awareness about your initiative. Share your targets early to let others know what you’re aiming to achieve."} variant={"plain"} onNext={onNext}>
        <CommunicationView playbook={playbook} communication={blueprint.targetReview!.communication}
                           communicationEdit={props.edit.communication} />
    </StagePageContainer>
}

export function CommunicateTargetsPage () {
    const { targetsReviewEdit, updateTargetsReview } = usePlaybookTargetsReviewEdit();
    return <EditScope onEditUpdated={updateTargetsReview}>
            <PageViews edit={targetsReviewEdit} />
    </EditScope>
}