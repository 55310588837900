import { Todo } from "../todos-page";
import { ContentType } from "tekkr-common/dist/model/playbook/segment/content/blueprint";
import {
    ExternalLinkContentBlueprint,
    ExternalLinkStyle,
} from "tekkr-common/dist/model/playbook/segment/content/types/external-link/blueprint";
import ExternalLinkSegmentContentView
    from "../../../../../components/shared/playbook-view/components/content/types/external-link";
import { useBlueprint } from "../../../controller/hooks/blueprint";

export function ToolTodoContent (props: { todo: Todo }) {
    const { blueprint } = useBlueprint();
    const segment = blueprint.segments.find(s => s.id === props.todo.segmentId)!;
    const toolContent = segment.content.find((c) => c.type === ContentType.externalLink && c.style === ExternalLinkStyle.tool)! as ExternalLinkContentBlueprint;
    return <ExternalLinkSegmentContentView content={toolContent} edit={undefined} isEditing={false} />
}