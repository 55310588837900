import React, { useCallback } from "react";
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from "../components/ui/dialog";
import { Button } from "../components/ui/button";
import { useMutation } from "@tanstack/react-query";
import { apiClient } from "../service/tekkr-service";
import { usePlaybook } from "../pages/playbook/controller/hooks/playbook";
import Spinner from "../components/ui/spinner";
import { useDropzone } from "react-dropzone";
import { Cross, Download, HandIcon, ImageIcon, XIcon } from "lucide-react";
import { cn } from "../lib/utils";

type Props = {
    open: boolean;
    onOpenChange: (open: boolean) => void;
    onImageUploaded?: () => void;
}

const dropzoneContent = {
    default: <>
        <Download />
        <p className={"text-medium"}>Drag-and-drop an image here or click to select.</p>
    </>,
    invalid: <>
        <HandIcon />
        <p className={"text-medium"}>Invalid file type. Please select an image file.</p>
    </>,
    active: <>
        <Download className={"text-primary animate-bounce"} />
        <p className={"text-medium text-primary"}>Drop image here.</p>
    </>
}

export function UploadPlaybookImageDialog(props: React.PropsWithChildren<Props>) {
    const { playbook } = usePlaybook();
    const [file, setFile] = React.useState<File | null>(null);

    const { mutate: upload, isPending } = useMutation({
        mutationFn: async () => {
            if (!file) {
                throw new Error("no file selected");
            }
            await apiClient.replacePlaybookImage({
                params: {
                    playbookId: playbook.id,
                },
                body: {
                    image: file,
                },
            });
            props.onOpenChange(false);
            props?.onImageUploaded?.();
        },
    });

    const onDrop = useCallback((files: File[]) => {
        const [f] = files;
        setFile(f);
    }, [])
    const {getRootProps, getInputProps, isDragActive, isDragReject} = useDropzone({ onDrop, maxFiles: 1, accept: { "image/*": [] } });

    let dropzoneView = dropzoneContent.default;
    if (isDragActive) {
        dropzoneView = isDragReject ? dropzoneContent.invalid : dropzoneContent.active;
    }

    return <Dialog open={props.open || isPending} onOpenChange={!isPending ? props.onOpenChange : undefined}>
        <DialogContent>
            <DialogHeader>
                <DialogTitle>Replace Playbook Image</DialogTitle>
                <DialogDescription>Upload an image here, to customize the look of your playbook.</DialogDescription>
            </DialogHeader>
            {isPending && <div className={"flex flex-col items-center gap-2"}>
                <Spinner />
                Uploading Image...
            </div>}

            { !isPending && <>
                <div {...getRootProps()} className={cn("transition-all cursor-pointer hover:bg-accent w-full h-32 outline-dotted outline-muted-foreground rounded-lg flex flex-col gap-3 items-center justify-center p-6", isDragActive && "outline-primary bg-primary/20", isDragReject && "text-destructive outline-destructive bg-destructive/20")}>
                    <input {...getInputProps()} accept="image/*" />
                    { (!file || isDragActive) && dropzoneView }
                    { file && !isDragActive && <div className={"flex flex-row gap-4 w-full"}>
                        <img className={"w-24 h-24 rounded-md object-cover"} alt={"Image Preview"} src={URL.createObjectURL(file)} />
                        <div className={"flex flex-col grow h-full justify-center"}>
                            <div className={"font-semibold line-clamp-1 flex flex-row items-center"}><ImageIcon className={"w-4 h-4 me-2"} />{file.name}</div>
                            <div className={"text-sm text-muted-foreground"}>Drop a different file or click to replace.</div>
                        </div>
                    </div>}
                </div>

                {file && <Button onClick={() => upload()}>Upload</Button>}
            </>}
        </DialogContent>
        {props.children}
    </Dialog>
}