import { usePlaybook } from "./playbook";
import { Playbook } from "tekkr-common/dist/model/playbook/playbook";
import { useMutation } from "@tanstack/react-query";
import { apiClient } from "../../../../service/tekkr-service";
import { PlaybookTargets } from "tekkr-common/dist/model/playbook/targets";
import { useDebouncedCallback } from "use-debounce";
import { useComputedReducer } from "../../../../lib/utils";

function useUpdateTargets(playbook: Playbook) {
    return useMutation({
        mutationFn: async (targets: PlaybookTargets) => {
            await (await apiClient).updatePlaybookTargets({
                body: targets,
                params: {
                    playbookId: playbook.id,
                },
            });
        },
    });
}

export function usePlaybookTargets(): [PlaybookTargets, (targets: PlaybookTargets) => void] {
    const { playbook } = usePlaybook();
    const updateTargetsMutation = useUpdateTargets(playbook);
    const updateTargets = useDebouncedCallback(updateTargetsMutation.mutate, 1_000);

    if (!playbook.targets) {
        playbook.targets = [];
    }

    const [targets, dispatchReducer] = useComputedReducer(() => playbook.targets!)
    const setTargets = (t: PlaybookTargets) => {
        playbook.targets = t;
        dispatchReducer();
    }

    return [
        targets,
        (t) => {
            setTargets(t);
            updateTargets(t);
        }
    ]
}