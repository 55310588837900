import React from "react";
import { useMutation } from "@tanstack/react-query";
import { apiClient } from "../../service/tekkr-service";
import { ClientInferResponseBody } from "@ts-rest/core";
import { apiContract } from "tekkr-common/dist/model/api/api.contract";
import { useOrgController } from "../../auth/org-provider";
import { Button } from "../ui/button";

type OAuthService = "google" | "asana";

interface Props {
    service: OAuthService;
    onConnected?: () => void;
}

export function ConnectOauthAccountButton (props: React.PropsWithChildren<Props>) {
    const orgController = useOrgController();

    const mutation = useMutation({
        mutationFn: async () => {
            const res = await apiClient.linkOauthAccount({
                params: {
                    service: props.service,
                },
            });
            const body = res.body as ClientInferResponseBody<typeof apiContract.linkOauthAccount>;
            return body.authUrl;
        },
    });

    const open = async () => {
        const url = await mutation.mutateAsync();
        const w = window.open(url)
        const i = setInterval(() => {
            if (w?.closed) {
                void orgController.refetch();
                props.onConnected?.();
                clearInterval(i);
            }
        }, 500);
    }

    return <Button onClick={open} disabled={mutation.isPending}>{ props.children }</Button>
}