import { ArrowRight, FileEdit, Info } from "lucide-react";
import { Link } from "react-router-dom";
import { Progress } from "../../../components/ui/progress";
import { DateTime } from "luxon";
import React from "react";
import { ClientInferResponseBody } from "@ts-rest/core";
import { apiContract } from "tekkr-common/dist/model/api/api.contract";

type LibraryType = ClientInferResponseBody<
    typeof apiContract.listPlaybooks,
    200
>["data"];

export function BlueprintPageLibraryBox(props: { library: LibraryType }) {
    // todo show user creator picture next to created-at timestamp (see figma)
    return (
        <div className={"my-4 flex flex-col gap-2"}>
            <div className={"flex flex-row items-center gap-1 justify-between"}>
                <h4>In your library</h4>
                <p className={"text-muted-foreground text-sm"}>
                    <Info className={"inline-block w-4 h-4 me-1"} /> You have{" "}
                    {props.library.length.toString()}{" "}
                    {props.library.length === 1 ? "copy" : "copies"} of this playbook in
                    your library.
                </p>
            </div>
            <div className={"border rounded-md"}>
                {props.library.map((pb, index) => (
                    <Link key={pb.id} to={`/playbook/${pb.id}`}>
                        {index > 0 && <hr />}
                        <div
                            className={
                                "hover:bg-accent px-4 py-2 w-full flex flex-row items-center gap-4"
                            }
                        >
                            <div>
                                <FileEdit className={"w-5 h-5 shrink-0"} />
                            </div>
                            <div className={"flex flex-col shrink w-full gap-1"}>
                                <p className={"overflow-ellipsis overflow-hidden text-start"}>
                                    {pb.title}
                                </p>
                                <div className={"flex flex-row items-center gap-2"}>
                                    <Progress
                                        color={"confirmation"}
                                        className={"w-36 h-2"}
                                        value={(pb.state.edit ?? 0.0) * 100}
                                    />
                                    <p className={"text-muted-foreground text-sm"}>
                                        {Math.round((pb.state.edit ?? 0.0) * 100)}%
                                    </p>
                                </div>
                            </div>
                            <div className={"flex flex-row items-center gap-2 shrink-0"}>
                                <div className={"shrink-0 text-sm text-muted-foreground"}>
                                    created on{" "}
                                    {DateTime.fromISO(pb.createdAt).toLocaleString({
                                        month: "short",
                                        day: "numeric",
                                        year: "numeric",
                                    })}
                                </div>
                                <ArrowRight className={"shrink-0 w-5 h-5"} />
                            </div>
                        </div>
                    </Link>
                ))}
            </div>
        </div>
    );
}