import React from "react";
import { Dialog, DialogContent, DialogTitle } from "../components/ui/dialog";
import { Button } from "../components/ui/button";
import { useAuth } from "../auth/auth-provider";
import { useOrg } from "../auth/org-provider";
import { PlaybookSidebarNavigation } from "../pages/playbook/sidebar";
import { PlaybookStage } from "tekkr-common/dist/model/playbook/enums/enums";
import { playbookStageTitles } from "tekkr-common/dist/model/playbook/static/copy";
import { usePlaybookPageNavigation } from "../pages/playbook/playbook-page";

export function PlaybookEditCompletedDialog (
    props: React.PropsWithChildren & {
        open?: boolean;
        onOpenChange?: (open: boolean) => void;
        nav: PlaybookSidebarNavigation;
    }
) {
    const { user } = useAuth();
    const org = useOrg();
    const nextStage = props.nav.stages.find(s => s.stage !== PlaybookStage.edit)!;

    const pageNav = usePlaybookPageNavigation();

    const getStarted = () => {
        props.onOpenChange?.(false);
        pageNav!.goToNext()
    }

    return (
        <Dialog open={props.open} onOpenChange={props.onOpenChange}>
            {props.children}
            <DialogContent className="max-w-lg text-center p-12 flex flex-col items-center gap-8">
                <DialogTitle>
                    <div className={"flex flex-col gap-2"}>
                        <h2>Great job, {user.displayName?.split(" ")[0]}! 🥇</h2>
                        <p className={"text-muted-foreground"}>You've completed editing your playbook.</p>
                    </div>
                </DialogTitle>
                <p>If you need to make changes later, you can come back at any time. Now, it's time to start establishing it at {org.name}! Next up: {playbookStageTitles.upcoming[nextStage.stage]}. Tekkr will guide you through the process.</p>
                <Button onClick={getStarted} className={"hover:drop-shadow-glow-primary transition-all duration-500"}>Get started</Button>
            </DialogContent>
        </Dialog>
    )
}