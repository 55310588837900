import { SegmentBlueprint } from "tekkr-common/dist/model/playbook/segment/blueprint";
import { SegmentEdit } from "tekkr-common/dist/model/playbook/segment/edit";
import { ContentType } from "tekkr-common/dist/model/playbook/segment/content/blueprint";
import {
    SelectContentBlueprint,
    SelectContentSubject, selectSubjectConfigs,
} from "tekkr-common/dist/model/playbook/segment/content/types/select/blueprint";
import { SelectContentEdit } from "tekkr-common/dist/model/playbook/segment/content/types/select/edit";
import { getMeetingDurationMinutes } from "../../../../lib/edit-utils";
import { PeopleListContentEdit } from "tekkr-common/dist/model/playbook/segment/content/types/people-list/edit";
import {
    PeopleListContentBlueprint
} from "tekkr-common/dist/model/playbook/segment/content/types/people-list/blueprint";
import { Org, OrgUser } from "../../../../service/tekkr-service-types";
import { Rfc5545FrequencyConfig } from "../../../../lib/google-calendar";
import { PlaybookPeopleGroups } from "tekkr-common/dist/model/playbook/people-groups";


function findSelectContentItem (segment: SegmentBlueprint, subject: SelectContentSubject, preferId: string) {
    const relevantContent = segment.content.filter((c) => c.type === ContentType.select && c.subject === subject) as SelectContentBlueprint[];
    if (relevantContent.length === 0) {
        return null;
    }
    // find item with preferred id or use first in list otherwise.
    return relevantContent.find((c) => c.id === preferId) ?? relevantContent[0];
}

export const recurRules: Record<string, Rfc5545FrequencyConfig> = {
    weekly: { freq: "WEEKLY" },
    two_weeks: { freq: "WEEKLY", interval: 2 },
    three_weeks: { freq: "WEEKLY", interval: 3 },
    monthly: { freq: "MONTHLY" },
    quarterly: { freq: "MONTHLY", interval: 3 },
    annually: { freq: "YEARLY", interval: 1 },
    "semi-annually": { freq: "MONTHLY", interval: 6 },
    once: null,
}

interface Cadence {
    rfc5545: Rfc5545FrequencyConfig;
    friendlyName: string;
}

function extractCadence(content: SelectContentBlueprint, edit?: SelectContentEdit): Cadence | null {
    const selected = edit?.selected ?? content.default;
    if (selected) {
        return {
            rfc5545: recurRules[selected]!,
            friendlyName: selectSubjectConfigs.meeting_cadence.options.find(it => it.id === selected)!.displayName,
        }
    } else {
        return null;
    }
}

export function getMeetingCadenceFromSegment(segment: SegmentBlueprint, edit?: SegmentEdit): Cadence | null {
    const content = findSelectContentItem(segment, SelectContentSubject.meetingCadence, "cadence");
    if (!content) {
        return null;
    }
    return extractCadence(content, edit?.content?.[content.id]);
}


export function getMeetingDurationFromSegment(segment: SegmentBlueprint, edit?: SegmentEdit): { minutes: number } {
    const content = findSelectContentItem(segment, SelectContentSubject.meetingDuration, "duration");
    if (!content || !content.default) {
        return { minutes: 60 };
    }
    return { minutes: getMeetingDurationMinutes(edit?.content?.[content.id], content) };
}


export function getMeetingParticipantsFromSegment(org: Org, segment: SegmentBlueprint, edit: SegmentEdit | undefined, peopleGroups: PlaybookPeopleGroups | undefined): OrgUser[] {
    const relevantContent = segment.content.filter((c) => c.type === ContentType.peopleList) as PeopleListContentBlueprint[];
    if (relevantContent.length === 0) {
        return [];
    }
    const content = relevantContent.find((c) => c.id.includes("participants")) ?? relevantContent[0];

    let ids: string[];
    if (content.peopleGroupId) {
        ids = peopleGroups?.[content.peopleGroupId] ?? [];
    } else {
        ids = (edit?.content?.[content.id] as PeopleListContentEdit | undefined)?.selectedUsers ?? [];
    }

    const idSet = new Set(ids);
    return org.users.filter(u => idSet.has(u.id))
}