import { PlaybookEdit } from "tekkr-common/dist/model/playbook/edit";
import { MutationFilters, useIsMutating, useMutation, useMutationState } from "@tanstack/react-query";
import debounce from "debounce";
import { usePlaybook } from "./playbook";
import { playbookMutations } from "../queries";

// todo convert this to same pattern as targets review and alignment
export function useUpdatePlaybookEdit(): (edit: PlaybookEdit) => void {
    const { playbook } = usePlaybook();
    const { mutate } = useMutation(playbookMutations.updateEdit(playbook.id));
    return debounce((edit: PlaybookEdit) => {
        mutate(edit)
    }, 500);
}