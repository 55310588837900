"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.selectContentBlueprintSchema = exports.selectSubjectConfigs = exports.SelectContentSubject = void 0;
const blueprint_1 = require("../../blueprint");
const zod_1 = require("zod");
var SelectContentSubject;
(function (SelectContentSubject) {
    SelectContentSubject["meetingDuration"] = "meeting_duration";
    SelectContentSubject["meetingDurationDays"] = "meeting_duration_days";
    SelectContentSubject["turnaroundTime"] = "turnaround_time";
    SelectContentSubject["meetingCadence"] = "meeting_cadence";
    SelectContentSubject["workingMode"] = "working_mode";
    SelectContentSubject["location"] = "location";
    SelectContentSubject["leadTimeWeeks"] = "lead_time_weeks";
})(SelectContentSubject || (exports.SelectContentSubject = SelectContentSubject = {}));
exports.selectSubjectConfigs = {
    [SelectContentSubject.meetingDuration]: {
        title: "Meeting Duration",
        options: [
            { id: "15min", displayName: "15 Minutes", numeric: 15 },
            { id: "30min", displayName: "30 Minutes", numeric: 30 },
            { id: "45min", displayName: "45 Minutes", numeric: 45 },
            { id: "60min", displayName: "60 Minutes", numeric: 60 },
            { id: "90min", displayName: "90 Minutes", numeric: 90 },
            { id: "2h", displayName: "2 Hours", numeric: 2 * 60 },
            { id: "3h", displayName: "3 Hours", numeric: 3 * 60 },
            { id: "4h", displayName: "4 Hours", numeric: 4 * 60 },
            { id: "8h", displayName: "8 Hours", numeric: 8 * 60 },
        ],
    },
    [SelectContentSubject.meetingDurationDays]: {
        title: "Duration",
        options: [
            { id: "0.5d", displayName: "0.5 Days" },
            { id: "1d", displayName: "1 Day" },
            { id: "1.5d", displayName: "1.5 Days" },
            { id: "2d", displayName: "2 Days" },
            { id: "3d", displayName: "3 Days" },
            { id: "4d", displayName: "4 Days" },
            { id: "1w", displayName: "1 Week" },
            { id: "2w", displayName: "2 Weeks" },
        ],
    },
    [SelectContentSubject.workingMode]: {
        title: "Working Mode",
        options: [
            { id: "asynchronous", displayName: "Asynchronous" },
            { id: "meeting_in_person", displayName: "Meeting (in person)" },
            { id: "meeting_virtual", displayName: "Meeting (virtual)" },
        ],
    },
    [SelectContentSubject.meetingCadence]: {
        title: "Meeting Cadence",
        options: [
            { id: "weekly", displayName: "Every week" },
            { id: "two_weeks", displayName: "Every two weeks" },
            { id: "three_weeks", displayName: "Every three weeks" },
            { id: "monthly", displayName: "Every month" },
            { id: "quarterly", displayName: "Quarterly" },
            { id: "semi-annually", displayName: "Semi-Annually" },
            { id: "annually", displayName: "Annually" },
            { id: "once", displayName: "Once" },
        ],
    },
    [SelectContentSubject.location]: {
        title: "Location",
        options: [
            { id: "in_person", displayName: "Physical / In Person" },
            { id: "remote", displayName: "Remote" },
        ],
    },
    [SelectContentSubject.turnaroundTime]: {
        title: "Deadline",
        options: [
            { id: "1d", displayName: "1 day", numeric: 1 },
            { id: "2d", displayName: "2 days", numeric: 2 },
            { id: "3d", displayName: "3 days", numeric: 3 },
            { id: "4d", displayName: "4 days", numeric: 4 },
            { id: "5d", displayName: "5 days", numeric: 5 },
            { id: "6d", displayName: "6 days", numeric: 6 },
            { id: "1w", displayName: "1 week", numeric: 7 },
            { id: "2w", displayName: "2 weeks", numeric: 2 * 7 },
        ],
    },
    [SelectContentSubject.leadTimeWeeks]: {
        title: "Lead Time",
        options: [
            { id: "1w", displayName: "1 week", numeric: 1 },
            { id: "2w", displayName: "2 weeks", numeric: 2 },
            { id: "3w", displayName: "3 weeks", numeric: 3 },
            { id: "4w", displayName: "4 weeks", numeric: 4 },
            { id: "5w", displayName: "5 weeks", numeric: 5 },
            { id: "6w", displayName: "6 weeks", numeric: 6 },
            { id: "8w", displayName: "8 weeks", numeric: 8 },
            { id: "10w", displayName: "10 weeks", numeric: 10 },
            { id: "12w", displayName: "12 weeks", numeric: 12 },
        ],
    }
};
exports.selectContentBlueprintSchema = blueprint_1.editableContentBlueprintSchema.extend({
    type: zod_1.z.literal(blueprint_1.ContentType.select),
    subject: zod_1.z.nativeEnum(SelectContentSubject),
    overrides: zod_1.z.object({
        title: zod_1.z.string().optional(),
    }).optional(),
    default: zod_1.z.string().optional(),
    descriptionMarkdown: zod_1.z.string().optional(),
});
