import { Dialog, DialogContent, DialogTitle } from "../components/ui/dialog";
import React from "react";
import { PlaybookStage } from "tekkr-common/dist/model/playbook/enums/enums";
import { IconForPlaybookStage } from "../components/shared/icon-playbook-stage";
import { playbookStageTitles } from "tekkr-common/dist/model/playbook/static/copy";
import { Button } from "../components/ui/button";

const stages: {
    stage: PlaybookStage;
    explainer: string;
}[] = [
    {
        stage: PlaybookStage.edit,
        explainer: "Customize this playbook to your needs. You can come back to edit at any time and further optimize your playbook.",
    },
    {
        stage: PlaybookStage.targets,
        explainer: "Define the goals for this initiative. Make sure all major stakeholders are on the same page.",
    },
    {
        stage: PlaybookStage.alignment,
        explainer: "Get everyone who is affected on board. Make sure you're doing what your organization needs.",
    },
    {
        stage: PlaybookStage.implementation,
        explainer: "Automatically identify key todos and activities that need to be performed to implement this playbook.",
    },
    {
        stage: PlaybookStage.communication,
        explainer: "Share your final playbook and celebrate the improvements to your organization.",
    },
    {
        stage: PlaybookStage.tracking,
        explainer: "Track the most important playbook metrics to ensure you're delivering against your targets.",
    },
]

export default function PlaybookIntroDialog(
    props: React.PropsWithChildren & {
        open?: boolean;
        onOpenChange?: (open: boolean) => void;
        playbookTitle: string;
        stages?: Set<PlaybookStage>;
    }
) {
    const relevantStages = props.stages ? stages.filter(it => props.stages!.has(it.stage)) : stages;
    return (
        <Dialog open={props.open} onOpenChange={props.onOpenChange}>
            {props.children}
            <DialogContent className="sm:max-w-2xl text-center p-12 flex flex-col items-center gap-8">
                <DialogTitle>
                    <div className={"flex flex-col gap-2"}>
                        <h2>Welcome to your new playbook 🥳</h2>
                        <p className={"text-muted-foreground"}>Let's make this a success! Tekkr's built-in process ensures that you achieve what you need with this playbook.</p>
                    </div>
                </DialogTitle>

                <div className={"relative"}>
                    <h4 className={"mb-3"}>Tekkr's Process for this Playbook</h4>
                    <div className={"absolute top-10 bottom-10 left-3.5 w-0.5 bg-muted -z-20"}></div>
                    <table>
                    <tbody>
                        {relevantStages.map(stage => <tr key={stage.stage}>
                            <td className={"flex flex-row gap-2 items-center py-2 pe-4"}>
                                <div
                                    className={"rounded-full bg-muted w-7 h-7 p-1.5 outline outline-background outline-8"}>
                                    <IconForPlaybookStage stage={stage.stage} className={"w-4 h-4"} />
                                </div>
                                <div className={"font-semibold"}>
                                    {playbookStageTitles.upcoming[stage.stage]}
                                </div>
                            </td>
                            <td className={"text-muted-foreground text-start max-w-sm py-2"}>
                                {stage.explainer}
                            </td>
                        </tr>)}
                        </tbody>
                    </table>
                </div>

                <Button onClick={() => props.onOpenChange?.(false)}>Let's go!</Button>

            </DialogContent>
        </Dialog>
    );
}
