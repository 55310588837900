"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CommunicationChannel = exports.PlaybookStage = exports.playbookCategoryConfig = exports.PlaybookCategory = exports.CompanyStage = exports.PlaybookVisibility = void 0;
var PlaybookVisibility;
(function (PlaybookVisibility) {
    PlaybookVisibility["public"] = "public";
    PlaybookVisibility["dev"] = "dev";
})(PlaybookVisibility || (exports.PlaybookVisibility = PlaybookVisibility = {}));
var CompanyStage;
(function (CompanyStage) {
    CompanyStage["seed"] = "seed";
    CompanyStage["seriesA"] = "series_a";
    CompanyStage["seriesB"] = "series_b";
    CompanyStage["seriesC"] = "series_c";
})(CompanyStage || (exports.CompanyStage = CompanyStage = {}));
var PlaybookCategory;
(function (PlaybookCategory) {
    PlaybookCategory["product_management"] = "product_management";
    PlaybookCategory["people_management"] = "people_management";
    PlaybookCategory["stakeholder_management"] = "stakeholder_management";
    PlaybookCategory["sdlc"] = "sdlc";
    PlaybookCategory["target_setting"] = "target_setting";
    PlaybookCategory["personal_growth"] = "personal_growth";
    PlaybookCategory["engineering"] = "engineering";
    PlaybookCategory["fundamentals"] = "fundamentals";
})(PlaybookCategory || (exports.PlaybookCategory = PlaybookCategory = {}));
exports.playbookCategoryConfig = {
    [PlaybookCategory.people_management]: { color: "#483263", title: "People Management" },
    [PlaybookCategory.product_management]: { color: "#80423E", title: "Product Management" },
    [PlaybookCategory.sdlc]: { color: "#3B6495", title: "SDLC" },
    [PlaybookCategory.stakeholder_management]: { color: "#7C6351", title: "Stakeholder Management" },
    [PlaybookCategory.target_setting]: { color: "#5B7F8C", title: "Target Setting" },
    [PlaybookCategory.personal_growth]: { color: "#884263", title: "Personal Growth" },
    [PlaybookCategory.engineering]: { color: "#4B7163", title: "Engineering" },
    [PlaybookCategory.fundamentals]: { color: "#2D4B5C", title: "Fundamentals" },
};
var PlaybookStage;
(function (PlaybookStage) {
    PlaybookStage["edit"] = "edit";
    PlaybookStage["targets"] = "targets";
    PlaybookStage["alignment"] = "alignment";
    PlaybookStage["implementation"] = "implementation";
    PlaybookStage["communication"] = "communication";
    PlaybookStage["tracking"] = "tracking";
})(PlaybookStage || (exports.PlaybookStage = PlaybookStage = {}));
var CommunicationChannel;
(function (CommunicationChannel) {
    CommunicationChannel["message"] = "message";
    CommunicationChannel["meeting"] = "meeting";
})(CommunicationChannel || (exports.CommunicationChannel = CommunicationChannel = {}));
