import { useEditableTextInMarkdownEdit } from "../../playbook-view/components/content/types/markdown";
import { cn } from "../../../../lib/utils";
import Contenteditable, { ContenteditableRef } from "../../contenteditable";
import { Edit3 } from "lucide-react";
import React, { useRef } from "react";

export function InlineEditableMarkdownComponent(props: { id: string, text: string }) {
    const segmentContext = useEditableTextInMarkdownEdit(props.id, props.text);

    if (!segmentContext) {
        return <>{props.text}</>;
    }

    return <InlineEditableTextField {...segmentContext} defaultValue={props.text} />
}

type TextFieldProps = {
    isEditing: boolean,
    value?: string,
    setValue: (value: string) => void,
    defaultValue?: string
    placeholder?: string;
}

export function InlineEditableTextField(props: React.HTMLProps<HTMLDivElement> & TextFieldProps) {
    const { isEditing, value, setValue } = props;
    const isEmpty = value !== undefined && value.trim().length === 0 && !props.placeholder;

    const contenteditableRef = useRef<ContenteditableRef>(null);

    return <div className={cn("inline cursor-text", props.className)}>
        <div
            className={cn("transition-all inline flex-row gap-2 items-center py-0.5 -mt-0.5 caret-primary", isEditing && "bg-input rounded-md px-2 hover:bg-muted/70", isEditing && isEmpty && "outline bg-red-500/20 outline-red-500")}>
            {isEditing && <>
                <Contenteditable ref={contenteditableRef} className={cn("inline focus:outline-none", isEditing && "font-semibold")}
                                 value={(value !== undefined ? value : props.defaultValue) ?? ""} onChange={setValue}></Contenteditable>
            </>}
            { isEditing && props.placeholder && !value && <span onClick={() => contenteditableRef.current?.focus()} className={cn("opacity-65", isEmpty && "text-red-500")}>{props.placeholder}</span>}
            {!isEditing && <span>{value}</span>}
            <Edit3
                className={cn("transition-all animate-in opacity-0 w-0 h-3 inline -translate-x-12", isEditing && "w-3 ms-2 opacity-100 translate-x-0", !isEditing && "hidden")} />
        </div>
    </div>
}