import React, { HTMLProps, PropsWithChildren } from "react";
import { cn } from "../../lib/utils";

export type TekkrCardStyle = "primary" | "confirmation" | "disabled";

interface Props {
    cardStyle?: TekkrCardStyle;
}

const lineBgClasses: Record<TekkrCardStyle, string> = {
    "primary": "bg-primary",
    "confirmation": "bg-confirmation",
    "disabled": "bg-accent",
}

export function TekkrCard (props: PropsWithChildren<Pick<HTMLProps<HTMLDivElement>, "className">> & Props) {
    return <div className={"border ps-5 p-4 rounded-lg relative overflow-clip"}>
        <div className={cn("w-0.5 absolute top-0 bottom-0 left-0 transition-all duration-500", lineBgClasses[props.cardStyle ?? "primary"])}></div>
        <div className={props.className}>
            { props.children}
        </div>
    </div>
}