"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.categorizationContentEditSchema = exports.CategorizationContentEditSelectionStates = void 0;
const zod_1 = require("zod");
var CategorizationContentEditSelectionStates;
(function (CategorizationContentEditSelectionStates) {
    CategorizationContentEditSelectionStates[CategorizationContentEditSelectionStates["selected"] = 1] = "selected";
    CategorizationContentEditSelectionStates[CategorizationContentEditSelectionStates["optional"] = 2] = "optional";
})(CategorizationContentEditSelectionStates || (exports.CategorizationContentEditSelectionStates = CategorizationContentEditSelectionStates = {}));
exports.categorizationContentEditSchema = zod_1.z.strictObject({
    selection: zod_1.z.record(zod_1.z.string(), zod_1.z.record(zod_1.z.string(), zod_1.z.nativeEnum(CategorizationContentEditSelectionStates))).optional() // map item.id -> category.id -> selected/optional
});
