import {
    Calendar,
    ChartLine,
    Check,
    Flame,
    ListTodo,
    Megaphone,
    MessagesSquare,
    Pen,
    Target,
} from "lucide-react";
import React from "react";
import { PlaybookStage } from "tekkr-common/dist/model/playbook/enums/enums";
import { cn } from "../../lib/utils";
import { IconForPlaybookStage } from "../../components/shared/icon-playbook-stage";
import { playbookStageTitles } from "tekkr-common/dist/model/playbook/static/copy";

type SidebarButtonVariant =  "active" | "upcoming" | "done";

type StepIcon = "target" | "communicate" | "schedule" | "edit" | "roasting" | "todos" | "messages" | "graphs";

type NavState = "done" | "todo";

interface SubNavItem {
    step: string;
    icon: StepIcon;
    name: string;
    id?: string;
    state: NavState;
}

export interface PlaybookSidebarNavigation {
    stages: {
        stage: PlaybookStage;
        state: NavState;
        steps?: SubNavItem[];
    }[];
}

type PlaybookSidebarProps = {
    nav: PlaybookSidebarNavigation,
    selected: {
        stage: PlaybookStage,
        step?: string,
        stepId?: string,
    },
    onSelect: (selected: { stage: PlaybookStage, step?: string, stepId?: string }) => void,
};

function StepIconView (props: React.HTMLProps<SVGSVGElement> & { name: StepIcon }) {
    const { name } = props;
    if (name === "target") {
        return <Target {...props} />
    } else if (name === "schedule") {
        return <Calendar {...props} />
    } else if (name === "communicate") {
        return <Megaphone {...props} />
    } else if (name === "edit") {
        return <Pen {...props} />
    } else if (name === "roasting") {
        return <Flame {...props} />
    } else if (name === "todos") {
        return <ListTodo {...props} />
    } else if (name === "messages") {
        return <MessagesSquare {...props} />
    } else if (name === "graphs") {
        return <ChartLine {...props} />
    }
    throw new Error(`unsupported step icon ${name}`);
}

function isStageDone(stage: { stage: PlaybookStage, state: NavState }) {
    return stage.state === "done";
}

const sidebarButtonStyles: Record<SidebarButtonVariant, string> = {
    active: "hover:cursor-pointer hover:bg-accent font-semibold",
    upcoming: "opacity-65 cursor-not-allowed",
    done: "text-confirmation hover:cursor-pointer hover:bg-accent"
}
type SidebarStageButtonProps = {
    stage: PlaybookStage,
    variant: SidebarButtonVariant,
    selected: boolean,
    onClick: () => void,
}
function SidebarStageButton(props: SidebarStageButtonProps) {
    const onClick = props.variant === "upcoming" ? undefined : props.onClick;
    return <div onClick={onClick}
                className={cn("flex flex-row items-center gap-2 transition-all rounded-md px-4 py-2", sidebarButtonStyles[props.variant], props.selected ? "bg-accent hover:bg-border" : undefined)}>
        <IconForPlaybookStage className={"w-5 h-5"} stage={props.stage} />
        { playbookStageTitles.upcoming[props.stage] }
    </div>;
}

interface SidebarStepButtonProps {
    onSelect: PlaybookSidebarProps["onSelect"];
    stage: PlaybookStage;
    item: SubNavItem;
    selected: boolean;
    enabled: boolean;
}
function SidebarStepButton (props: SidebarStepButtonProps) {
    const isDone = props.item.state === "done";
    const Icon = isDone ? Check : StepIconView;

    const onClick = props.enabled ?
        () => props.onSelect({ stage: props.stage, step: props.item.step, stepId: props.item.id })
        : undefined;

    return <div
        className={cn("m-2 text-sm text-muted-foreground flex flex-row gap-2 items-start", props.selected && "text-foreground font-semibold", props.enabled ? "cursor-pointer" : "cursor-not-allowed")}
        onClick={onClick}>
        <Icon className={"w-4 h-4 shrink-0 mt-0.5"} name={props.item.icon} />
        {props.item.name}
    </div>;
}

export function PlaybookSidebar(props: PlaybookSidebarProps) {
    return <div className={"w-64 shrink-0 p-8 py-6 border-r-2 border-r-accent fixed bottom-0 top-16 animate-in slide-in-from-left fade-in duration-500"}>
        <div className={"py-9"}>
            {props.nav.stages.map((stage, index) => {
                const prev = index === 0 ? undefined : props.nav.stages[index - 1];
                const done = isStageDone(stage);
                const prevDone = prev && isStageDone(prev);
                let variant: SidebarButtonVariant = "upcoming";
                if (done) {
                    variant = "done";
                } else if (!prev || prevDone) {
                    variant = "active";
                }
                const selected = props.selected.stage === stage.stage;
                const isLast = index === props.nav.stages.length - 1;
                return <div key={stage.stage}>
                    <SidebarStageButton key={stage.stage} stage={stage.stage} variant={variant} selected={selected} onClick={() => props.onSelect({ stage: stage.stage })} />
                    {<div key={`subnav-${stage.stage}`} className={"flex flex-row"}>
                        { ((selected && stage.steps) || !isLast) && <div className={cn("w-0.5 shrink-0 rounded-md ms-6 my-1", done ? "bg-confirmation" : "bg-border")}></div> }
                        <div className={"min-h-6 pt-1 pb-2"}>
                            { selected && stage.steps?.map((i, index) => {
                                const prev = index === 0 ? undefined : stage.steps?.[index - 1];
                                const stepSelected = props.selected.step === i.step && (!i.id || props.selected.stepId === i.id)
                                return <SidebarStepButton
                                    enabled={!prev || prev.state !== "todo"}
                                    key={i.step + "-" + i.id}
                                    onSelect={() => props.onSelect({ stage: stage.stage, step: i.step, stepId: i.id })}
                                    stage={stage.stage}
                                    item={i}
                                    selected={stepSelected} />
                            })}
                        </div>
                    </div>}
                </div>;
            })}
        </div>
    </div>
}