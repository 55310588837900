"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.iterateObjectPaths = iterateObjectPaths;
function iteratePathPrototype(obj, path, visitor) {
    if (path.length === 0) {
        return visitor(obj, []);
    }
    const cur = path[0];
    if (cur !== "*") {
        if (!(cur in obj)) {
            return;
        }
        const value = obj[cur];
        return iteratePathPrototype(value, path.slice(1), (value, path) => {
            visitor(value, [cur, ...path]);
        });
    }
    else {
        Object.entries(obj).forEach(([key, value]) => {
            iteratePathPrototype(value, path.slice(1), (value, path) => {
                visitor(value, [key, ...path]);
            });
        });
    }
}
function iterateObjectPaths(obj, paths, visitor) {
    for (const path of paths) {
        iteratePathPrototype(obj, path.split("."), visitor);
    }
}
