"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.computePlaybookTodos = computePlaybookTodos;
exports.getSelectedValueFromSegment = getSelectedValueFromSegment;
const blueprint_1 = require("../../segment/blueprint");
const blueprint_2 = require("../../segment/content/blueprint");
const formatting_1 = require("../../../../utils/formatting");
function computePlaybookTodos(pw, blueprint) {
    const todos = [];
    for (const segment of blueprint.segments) {
        if (!segment.todo) {
            continue;
        }
        const edit = pw.playbook.edit?.segments?.[segment.id];
        if (edit?.hidden) {
            continue;
        }
        const placeholderCache = {};
        if (segment.todo.type === blueprint_1.SegmentTodoType.list) {
            const list = segment.content.find(s => s.type === blueprint_2.ContentType.list);
            const { content, edit } = pw.getContentAndEdit(segment.id, list.id, blueprint_2.ContentType.list);
            for (const item of content.items) {
                if (!(edit?.selectedItems) || !edit.selectedItems.includes(item.id)) {
                    continue;
                }
                todos.push({
                    id: `${segment.id}-${item.id}`,
                    segmentId: segment.id,
                    ...segment.todo,
                    title: `Set up: ${item.title}`,
                    description: item.description ?? segment.todo.description,
                    type: blueprint_1.SegmentTodoType.simple,
                });
            }
        }
        else {
            todos.push({
                id: segment.id,
                segmentId: segment.id,
                ...segment.todo,
                title: fillPlaceholders(segment.todo.title, segment, edit, placeholderCache)
            });
        }
    }
    return todos;
}
function fillPlaceholders(text, segment, edit, cache) {
    return text.replaceAll(/\{\{[A-z-]+}}/g, (text) => {
        const name = text.slice(2, text.length - 2);
        if (cache[name]) {
            return cache[name];
        }
        const value = getSelectedValueFromSegment(segment, edit, name);
        cache[name] = value;
        return value;
    });
}
function getSelectedValueFromSegment(segment, edit, path) {
    const content = segment.content.find((c) => "id" in c && c.id === path);
    if (!content) {
        throw new Error(`couldn't find '${path}'`);
    }
    if (content.type === blueprint_2.ContentType.list) {
        const listEdit = edit?.content?.[content.id];
        const allItems = [
            ...(listEdit?.customItems?.map(item => item.title) ?? []),
            ...(listEdit?.selectedItems?.map(selected => content.items.find(it => it.id === selected))?.filter(item => !!item)?.map(item => item.title) ?? [])
        ];
        return allItems.length ? formatting_1.formats.friendly.commaList(allItems) : "?"; // todo this is not a great solution, we should figure out what happens with the todo if nothing was selected
    }
    else {
        throw new Error(`content type ${content.type} is not supported`);
    }
}
