import { createContext, PropsWithChildren, useContext } from "react";
import React from "react";

interface Callbacks {
    onEditUpdated?: () => void;
    onPeopleGroupsUpdated?: () => void;
}

const ContentEditingCallbacksContext = createContext<Callbacks | null>(null);

export function ContentEditingListener(props: PropsWithChildren & Callbacks) {
    return <ContentEditingCallbacksContext.Provider value={props}>
        { props.children }
    </ContentEditingCallbacksContext.Provider>
}

// todo remove
export function useContentEditingNotifier(): Callbacks | null {
    return useContext(ContentEditingCallbacksContext);
}