import React, { createContext, useContext, useEffect, useState } from "react";
import { useComputedReducer } from "../../lib/utils";

class EventManager {
    private subscribers: Map<number, () => void>;
    private currentId: number;
    constructor() {
        this.subscribers = new Map();
        this.currentId = 0;
    }
    subscribe(callback: () => void): () => void {
        const id = this.currentId++;
        this.subscribers.set(id, callback);
        return () => {
            this.subscribers.delete(id);
        };
    }
    publish(): void {
        this.subscribers.forEach(callback => callback());
    }
}

interface EditContext {
    eventManager: EventManager;
}

const UseEditContext = createContext<EditContext | null>(null);

type EditContextProps = {
    onEditUpdated?: () => void;
}

function EditUpdater (props: {fn: () => void}) {
    useEditCallback(props.fn)
    return <></>
}

export function EditScope (props: React.PropsWithChildren & EditContextProps) {
    const [eventManager] = useState(() => new EventManager());
    const ctx: EditContext = {
        eventManager,
    };
    return <UseEditContext.Provider value={ctx}>
        { props.onEditUpdated && <EditUpdater fn={props.onEditUpdated} /> }
        { props.children }
    </UseEditContext.Provider>
}

type UseEditReturn<T, S> = { state: S, updateEdit: (update: (edit: T) => void) => void };

function _useEdit<T, S> (subscribe: boolean, edit: T, reducer: (edit: T) => S): UseEditReturn<T, S> {
    const ctx = useContext(UseEditContext);

    const reduced = reducer(edit);
    const [, dispatch] = useComputedReducer(() => JSON.stringify(reduced));
    useEffect(() => {
        if (subscribe) {
            return ctx?.eventManager.subscribe(dispatch)
        }
    }, [subscribe]);

    return {
        state: reduced,
        updateEdit: (fn) => {
            if (!ctx) {
                throw new Error("No edit context in scope.");
            }
            fn(edit);
            ctx.eventManager.publish();
            if (!subscribe) dispatch();
        },
    }
}

export function useScopedEdit<T, S> (edit: T, reducer: (edit: T) => S): UseEditReturn<T, S> {
    return _useEdit(false, edit, reducer);
}

export function useGlobalEdit<T, S> (edit: T, reducer: (edit: T) => S): UseEditReturn<T, S> {
    return _useEdit(true, edit, reducer);
}

export function useEditCallback(callback: () => void) {
    const ctx = useContext(UseEditContext);

    if (!ctx) {
        throw new Error("No edit context in scope.");
    }

    useEffect(() => ctx.eventManager.subscribe(callback), []);
}