import { usePlaybook, usePlaybookOptional } from "../../../../pages/playbook/controller/hooks/playbook";
import { MarkdownContentEdit } from "tekkr-common/dist/model/playbook/segment/content/types/markdown/blueprint";
import { useComputedReducer } from "../../../../lib/utils";
import { useEditCallback } from "../../../hooks/use-edit";
import React from "react";
import { useBlueprint } from "../../../../pages/playbook/controller/hooks/blueprint";
import { InlineEditable } from "tekkr-common/dist/lib/blueprint-wrapper";
import { Playbook } from "tekkr-common/dist/model/playbook/playbook";

export function ReferenceMarkdownComponent(props: { type: string, id: string }) {
    if (props.type === "editable") {
        return <EditableReference id={props.id} />
    } else {
        return <>#invalid-reference#</>
    }
}

function EditableReference(props: { id: string }) {
    const { bw } = useBlueprint();
    const editable = bw.getInlineEditables()[props.id];

    const playbook = usePlaybookOptional();

    if (playbook) {
        return <EditedEditableReference id={props.id} editable={editable} playbook={playbook.playbook} />
    } else {
        return <span>{ editable.defaultValue }</span>
    }
}

function EditedEditableReference(props: { id: string, editable: InlineEditable, playbook: Playbook }) {
    const { editable, playbook } = props;
    const segmentEdit = playbook ? playbook.edit?.segments?.[editable.location.segmentId] : undefined
    const mdEdit = segmentEdit?.content?.[editable.location.contentId] as MarkdownContentEdit | undefined;

    const [v, dispatch] = useComputedReducer(() => {
        return mdEdit?.editableText?.[props.id] || editable.defaultValue;
    });
    useEditCallback(dispatch);

    return <span>{v}</span>;
}