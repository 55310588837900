"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.playbookPeopleGroupsSchema = exports.peopleGroupBlueprintSchema = void 0;
const zod_1 = require("zod");
const schema_refinements_1 = require("../../utils/schema-refinements");
exports.peopleGroupBlueprintSchema = zod_1.z.strictObject({
    id: zod_1.z.string().regex(schema_refinements_1.formats.kebabCaseRegex),
    name: zod_1.z.string(),
    roles: zod_1.z.array(zod_1.z.string()).optional(),
});
exports.playbookPeopleGroupsSchema = zod_1.z.record(zod_1.z.string(), zod_1.z.array(zod_1.z.string()));
