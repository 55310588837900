import React, { ReactElement } from "react";
import MarkdownSegmentContentView from "./types/markdown";
import ListSegmentContentView from "./types/list";
import PeopleListSegmentContentView from "./types/people-list";
import {
    BlueprintEditType,
    ContentBlueprint,
    ContentType,
    PlaybookStage,
} from "tekkr-common/dist/model/playbook/segment/content/blueprint";
import SelectSegmentContentView from "./types/select";
import SectionSegmentContentView from "./types/section";
import BlueprintReferenceSegmentContentView from "./types/blueprint-reference";
import ExternalLinkSegmentContentView from "./types/external-link";
import EditableTextSegmentContentView from "./types/editable-text";
import { SegmentContent } from "tekkr-common/dist/model/playbook/segment/blueprint";
import { PlaybookEdit } from "tekkr-common/dist/model/playbook/edit";
import { SegmentEdit } from "tekkr-common/dist/model/playbook/segment/edit";
import CategorizationSegmentContentView from "./types/categorization";
import ToolTemplateSegmentContentView from "./types/tool-template";
import { PrinciplesSegmentContentView } from "./types/principles";
import { usePlaybookOptional } from "../../../../../pages/playbook/controller/hooks/playbook";
import AnimateHeight from "react-animate-height";
import { cn } from "../../../../../lib/utils";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type SegmentContentViewProps<CT extends ContentBlueprint, MP = {}> = {
    content: CT;
    edit: BlueprintEditType<CT> | undefined;
    isEditing: boolean;
} & MP;

const viewMap: Partial<
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Record<ContentType, (props: SegmentContentViewProps<any, any>) => ReactElement>
> = {
    [ContentType.markdown]: MarkdownSegmentContentView,
    [ContentType.list]: ListSegmentContentView,
    [ContentType.peopleList]: PeopleListSegmentContentView,
    [ContentType.select]: SelectSegmentContentView,
    [ContentType.section]: SectionSegmentContentView,
    [ContentType.blueprintReference]: BlueprintReferenceSegmentContentView,
    [ContentType.externalLink]: ExternalLinkSegmentContentView,
    [ContentType.editableText]: EditableTextSegmentContentView,
    [ContentType.categorization]: CategorizationSegmentContentView,
    [ContentType.toolTemplate]: ToolTemplateSegmentContentView,
    [ContentType.principles]: PrinciplesSegmentContentView,
    [ContentType.space]: () => <div className={"h-4"}></div>
};

function isHidden(props: Props & { contentItem: SegmentContent[0] }, stage: PlaybookStage): boolean {
    const blueprint = props.contentItem;
    const visibility = blueprint.visibility;
    if (!visibility) {
        return false;
    }
    if ("hideIn" in visibility) {
        return visibility.hideIn.includes(stage);
    } else if ("onlyIn" in visibility) {
        return visibility.onlyIn !== stage;
    }
    return false;
}

function ItemView(props: Props & { contentItem: SegmentContent[0] }) {
    const ViewType = viewMap[props.contentItem.type];

    if (!ViewType) {
        throw new Error(`content type "${props.contentItem.type}" not supported`);
    }

    const pb = usePlaybookOptional();

    const stage =
        !pb
            ? PlaybookStage.discover
            : props.isEditing
                ? PlaybookStage.edit
                : PlaybookStage.read;

    const hidden = isHidden(props, stage);
    return <AnimateHeight duration={300} height={hidden ? 0 : "auto"}>
        <ViewType
            content={props.contentItem}
            edit={props.contentEdit}
            isEditing={props.isEditing}
        />
    </AnimateHeight>
}

type Props = {
    playbookEdit?: PlaybookEdit;
    contentEdit?: object;
    isEditing: boolean;
};

export function ContentItemsView(props: Props & {
    content: SegmentContent,
    edit: SegmentEdit | undefined,
}) {
    const { edit, content } = props;
    if (edit && !edit.content) {
        edit.content = {};
    }
    return (
        <div className={"flex flex-col gap-2"}>
            {content.map((item, index) => {
                if ("id" in item && item.id && edit && !edit.content![item.id]) {
                    edit.content![item.id] = {};
                }
                return (
                    <ItemView
                        key={index}
                        contentItem={item}
                        isEditing={props.isEditing}
                        playbookEdit={props.playbookEdit}
                        contentEdit={
                            "id" in item && item.id && edit ? edit.content![item.id] : undefined
                        }
                    />
                );
            })}
        </div>
    );
}