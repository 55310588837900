import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "../components/ui/dialog";
import { Input } from "../components/ui/input";
import React from "react";
import { useMutation } from "@tanstack/react-query";
import { apiClient, ApiError } from "../service/tekkr-service";
import { useOrgController } from "../auth/org-provider";
import Spinner from "../components/ui/spinner";
import { ClientInferResponseBody } from "@ts-rest/core";
import { apiContract } from "tekkr-common/dist/model/api/api.contract";
import { Button } from "../components/ui/button";
import { useForm } from "react-hook-form";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../components/ui/form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Checkbox } from "../components/ui/checkbox";

type PropsType = React.PropsWithChildren & {
  onPersonCreated?: (id: string) => void;
  context: "playbook" | "org";
};

const formSchema = z.object({
  name: z.string().min(2, "Please enter a name").max(50, "This name is too long"),
  title: z.string().max(50, "This title is too long").optional(),
  email: z.string().max(100, "This email address is too long").email("Please enter a valid email address"),
  sendInvite: z.boolean(),
});

type FormType = z.infer<typeof formSchema>;

function getErrorMessage(e: Error) {
  if (e instanceof ApiError) {
    if (e.status === 409) {
      return "A member with this email address already exists.";
    }
  }
  return "Creating this person failed, please try again later.";
}

function CreatePersonDialogContent(
  props: PropsType & { updateKey: () => void }
) {
  const orgController = useOrgController();

  const mutation = useMutation({
    mutationFn: async (formData: FormType) => {
      const response = await (
        await apiClient
      ).createOrgUser({
        body: {
          name: formData.name,
          title: formData.title,
          email: formData.email,
        },
        query: {
          emailInvite: formData.sendInvite ? "true" : "false",
        },
      });
      const user = response.body as ClientInferResponseBody<
        typeof apiContract.createOrgUser,
        201
      >;
      await orgController.refetch();
      resetDialog();
      if (props.onPersonCreated) {
        props.onPersonCreated(user.id);
      }
    },
  });

  const form = useForm<FormType>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: "",
      email: "",
      sendInvite: props.context === "org",
    },
  });
  function onSubmit(values: FormType) {
    mutation.mutate(values);
  }

  function resetDialog() {
    props.updateKey();
  }

  const [open, setOpenState] = React.useState(false);
  const setOpen = (open: boolean) => {
    if (mutation.isPending) return;
    if (!open) {
      resetDialog();
    }
    setOpenState(open);
  };

  const values = form.getValues();

  return (
    <Dialog open={open} onOpenChange={(open) => setOpen(open)}>
      {props.children}
      <DialogContent className="sm:max-w-[500px]">
        <DialogHeader>
          <DialogTitle className={"text-start"}>Add Organization Member</DialogTitle>
          <DialogDescription className={"text-start"}>
            { props.context === "playbook" ? "Add a member to your organization to reference them here and in other places." : "Add a member to your organization and give them access to your playbooks." }
          </DialogDescription>
        </DialogHeader>

        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-5">
            <FormField
                control={form.control}
                name="name"
                render={({ field }) => (
                    <FormItem className={"grid grid-cols-5 items-center"}>
                      <FormLabel className={"pe-2"}>Name</FormLabel>
                      <FormControl className={"col-span-4"}>
                        <Input
                            disabled={mutation.isPending}
                            autoComplete={"off"}
                            placeholder="John Doe"
                            {...field}
                        />
                      </FormControl>
                      <div></div>
                      <FormMessage className={"col-span-4 mt-2"} />
                    </FormItem>
                )}
            />
            <FormField
                control={form.control}
                name="title"
                render={({ field }) => (
                    <FormItem className={"grid grid-cols-5 items-center"}>
                      <FormLabel className={"pe-2 block"}>
                        <div>
                          Title<br/><p className={"text-xs mt-1 text-muted-foreground"}>(optional)</p>
                        </div>
                      </FormLabel>
                      <FormControl className={"col-span-4"}>
                        <Input
                            disabled={mutation.isPending}
                            autoComplete={"off"}
                            placeholder="Staff Product Manager"
                            {...field}
                        />
                      </FormControl>
                      <div></div>
                      <FormMessage className={"col-span-4 mt-2"} />
                    </FormItem>
                )}
            />
            <FormField
                control={form.control}
                name="email"
                render={({ field }) => (
                    <FormItem className={"grid grid-cols-5 items-center"}>
                      <FormLabel className={"pe-2"}>Email</FormLabel>
                      <FormControl className={"col-span-4"}>
                        <Input
                            disabled={mutation.isPending}
                            autoComplete={"off"}
                            placeholder="john@company.com"
                            {...field}
                        />
                      </FormControl>
                      <div></div>
                      <FormMessage className={"col-span-4 mt-2"} />
                    </FormItem>
                )}
            />
            <FormField
                control={form.control}
                name={"sendInvite"}
                render={({ field }) => (
                    <div className={"grid grid-cols-5 items-center"}>
                      <div></div>
                      <FormItem
                          className={
                            "col-span-4 flex flex-row gap-2 items-center space-y-0"
                          }
                      >
                        <FormControl className={"col-span-4"}>
                          <Checkbox
                              disabled={mutation.isPending}
                              checked={field.value}
                              onCheckedChange={field.onChange}
                          />
                        </FormControl>
                        <FormLabel className={"mt-0"}>
                          Send an invitation email to join Tekkr
                        </FormLabel>
                      </FormItem>
                    </div>
                )}
            />
            <div className={"flex flex-row justify-end pt-4"}>
              <Button type={"submit"} disabled={mutation.isPending}>
                {!mutation.isPending && (values.sendInvite ? "Save and Invite" : "Save")}
                {mutation.isPending && <Spinner />}
              </Button>
            </div>
          </form>
        </Form>

        {mutation.isError ? (
            <div
                className={
                  "border border-destructive py-2 px-4 rounded-md text-destructive text-sm"
                }
            >
              {getErrorMessage(mutation.error)}
            </div>
        ) : null}
      </DialogContent>
    </Dialog>
  );
}

export default function CreatePersonDialog(props: PropsType) {
  const [key, setKey] = React.useState(Math.random());
  return (
    <CreatePersonDialogContent
      key={key}
      updateKey={() => setKey(Math.random())}
      {...props}
    />
  );
}
