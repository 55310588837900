import React from "react";
import { PlaybookEdit } from "tekkr-common/dist/model/playbook/edit";
import { useEffect } from "react";
import { usePlaybookPageNavigation } from "../playbook-page";
import { useOrg } from "../../../auth/org-provider";
import { Button } from "../../../components/ui/button";
import { Check, Edit, Save, Share2Icon, TrashIcon, TriangleAlert } from "lucide-react";
import { triggerConfetti } from "../confetti";
import { computePlaybookEditProgress } from "tekkr-common/dist/model/playbook/state";
import Spinner from "../../../components/ui/spinner";
import { PlaybookActionBarItem, PlaybookHeader } from "../../../components/shared/playbook-view/playbook-header";
import { DateTime } from "luxon";
import PlaybookView from "../../../components/shared/playbook-view/playbook-view";
import { useCloseConfirmation } from "../../../components/hooks/close-confirmation";
import { PlaybookPeopleGroups } from "tekkr-common/dist/model/playbook/people-groups";
import { usePlaybook } from "../controller/hooks/playbook";
import { useUpdatePlaybookEdit } from "../controller/hooks/playbook-edit";
import { EditScope, useEditCallback } from "../../../components/hooks/use-edit";
import ConfirmationDialog from "../../../modals/confirmation-dialog";
import { SharePlaybookDialog } from "../../../modals/share-playbook-dialog";
import { AvatarForUser } from "../../../components/shared/avatar-for-user";
import { MutationFilters, useIsMutating, useMutationState } from "@tanstack/react-query";
import { useSearchParams } from "react-router-dom";
import { useBlueprint } from "../controller/hooks/blueprint";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "../../../components/ui/tooltip";
import { useDebounce } from "use-debounce";

type EditSession = {
    startingProgress: number;
};

function usePlaybookUpdatingState(): { isOk: boolean, isFetching: boolean, isError: boolean, updatedAt?: number } {
    const filters: MutationFilters = {
        predicate: (m) => m.meta?.["indicatePlaybookEditing"] === true,
    }
    const state = useMutationState({ filters });
    const isMutating = useIsMutating(filters);

    const _isFetching = isMutating > 0;
    const [isFetching] = useDebounce(_isFetching, _isFetching ? 0 : 350);
    const lastState = state[state.length - 1];

    return {
        isOk: !isFetching && (state.length === 0 || !lastState.error),
        isError: !isFetching && state.length > 0 && !!lastState.error,
        isFetching,
        updatedAt: lastState ? lastState.submittedAt : undefined,
    }
}

function EditSavingStateIndicator() {
    const state = usePlaybookUpdatingState();
    return <div className={"rounded-md px-4 py-1.5 text-accent-foreground font-semibold text-sm mx-3 animate-in slide-in-from-right-8 fade-in duration-300"}>
        <div>
            {state.isFetching ? (
                <div className={"flex flex-row gap-2 items-center text-muted-foreground slide-in-from-right-0.5 animate-in"}>
                    <Spinner className={"w-5 h-5"}></Spinner> saving changes
                </div>
            ) : null}
            {state.isOk && !!state.updatedAt ? (
                <TooltipProvider>
                    <Tooltip>
                        <TooltipTrigger asChild>
                            <div className={"flex flex-row gap-2 items-center cursor-default text-muted-foreground slide-in-from-right-0.5 animate-in"}>
                                <Save className={"w-5 h-5 animate-in zoom-in-50"} /> changes saved
                            </div>
                        </TooltipTrigger>
                        <TooltipContent className={"mt-6"}>
                            <p className={"max-w-sm text-sm"}>
                                All changes that you've made to this playbook have been saved successfully. ✅
                                { state.updatedAt && <>
                                    <br />
                                    <span className={"text-xs text-muted-foreground"}>Last saved: { DateTime.fromMillis(state.updatedAt).toLocaleString({ hour: "2-digit", minute: "2-digit" }) }</span>
                                </> }
                            </p>
                        </TooltipContent>
                    </Tooltip>
                </TooltipProvider>
            ) : null}
            {state.isError && (
                <div className={"flex flex-row gap-2 items-center"}>
                    <TriangleAlert />
                    &nbsp; Changes could not be saved! &nbsp;
                    <u>
                        <a href={"https://www.tekkr.io/contact-us"}>
                            Contact us.
                        </a>
                    </u>
                </div>
            )}
        </div>
    </div>;
}

function EditUpdater(props: { edit: PlaybookEdit }) {
    const updateEdit = useUpdatePlaybookEdit();
    useEditCallback(() => {
        updateEdit(props.edit);
    })
    return <></>
}

export function PlaybookEditPage() {
    const org = useOrg();

    const { blueprint } = useBlueprint();
    const { playbook } = usePlaybook();

    const [editSession, setEditSession] = React.useState<EditSession | undefined>(
        undefined,
    );

    function startEditSession() {
        setEditSession({
            startingProgress:
                computePlaybookEditProgress(blueprint, playbook.edit) ?? 0,
        });
    }

    function endEditSession() {
        if (editSession && editSession.startingProgress < 1) {
            const newProgress = computePlaybookEditProgress(blueprint, playbook.edit);
            if (newProgress === 1) {
                setTimeout(() => {
                    playbookNavigation?.showEditCompletedDialog();
                }, 1_500);
                triggerConfetti();
            }
        }
        setEditSession(undefined);
    }

    // wait until data is loaded and then start editing session if playbook was jsut created
    const [query] = useSearchParams();
    useEffect(() => {
        if (query.get("new_playbook") === "true") {
            startEditSession();
        }
    }, []);

    useCloseConfirmation(!!editSession);

    useEffect(() => {
        document.title = `${editSession ? "Editing: " : ""} ${playbook.edit?.title ?? blueprint.title ?? "Playbook"} - Tekkr`;
    }, [playbook, blueprint, editSession]);

    const playbookEdit = (playbook.edit ?? {}) as PlaybookEdit;
    const peopleGroups = (playbook.peopleGroups ?? {}) as PlaybookPeopleGroups;

    if (!playbookEdit.segments) {
        playbookEdit.segments = {};
    }

    const playbookNavigation = usePlaybookPageNavigation();
    const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
    const [shareDialogOpen, setShareDialogOpen] = React.useState(false);

    const actionBarItems: PlaybookActionBarItem[] = [
        { title: "Share", onClick: () => setShareDialogOpen(true), icon: Share2Icon },
        { title: "Delete", onClick: () => setDeleteDialogOpen(true), icon: TrashIcon, variant: "destructive" }
    ];

    const creator = org.users.find((u) => u.id === playbook.createdBy)!;

    const content = <>
        <SharePlaybookDialog open={shareDialogOpen} onOpenChange={setShareDialogOpen}></SharePlaybookDialog>
        <ConfirmationDialog
            open={deleteDialogOpen}
            onOpenChange={setDeleteDialogOpen}
            title={"Delete this Playbook?"}
            confirmButtonVariant={"destructive"}
            body={
                <p>
                    Do you really want to delete the playbook "
                    <b>{playbookEdit.title}</b>"?
                </p>
            }
            onConfirm={() => playbookNavigation?.deletePlaybook()}
        >
        </ConfirmationDialog>
        <PlaybookHeader
            blueprint={blueprint}
            playbook={playbook}
            isEditing={!!editSession}
            actionBarItems={actionBarItems}
            onReplaceImage={() => {}}
        >
            <div
                className={
                    "flex flex-row items-center gap-3 mb-6 text-muted-foreground"
                }
            >
                <p>Created by</p>

                <div
                    className={
                        " text-muted-foreground flex flex-row gap-2 px-2 py-1 border rounded-md items-center"
                    }
                >
                    <AvatarForUser user={creator} className={"w-6 h-6"} />
                    <span>{ creator!.name }</span>
                </div>
                <p>
                    on{" "}
                    {DateTime.fromISO(playbook.createdAt).toLocaleString({
                        month: "short",
                        day: "numeric",
                        year: "numeric",
                    })}
                </p>
            </div>
        </PlaybookHeader>

        <PlaybookView
            playbookEdit={playbookEdit}
            peopleGroups={peopleGroups}
            isEditing={!!editSession}
        />

        <div
            className={
                "fixed top-0 right-24 flex flex-row items-center h-16 w-50 z-50"
            }
        >
            {editSession ? <EditSavingStateIndicator /> : null}
            {editSession ? (
                <Button size={"sm"} onClick={() => endEditSession()}>
                    <Check className={"w-5 h-5"} />
                    <span className={"ms-2"}>Done Editing</span>
                </Button>
            ) : (
                <Button
                    size={"sm"}
                    className={"px-4"}
                    onClick={() => startEditSession()}
                >
                    <Edit className={"w-5 h-5"} />
                    <span className={"ms-2"}>Edit</span>
                </Button>
            )}
        </div>
    </>

    return (
        <div className={"px-8 py-6"}>
            <EditScope>
                <EditUpdater edit={playbookEdit} />
                {content}
            </EditScope>
        </div>
    );
}

export default PlaybookEditPage;